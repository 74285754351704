export const emptyInventoryScreenFilters = {
  state: [],
  years: [],
  types: [],
  severity: [],
  regions: [],
  classifications: [],
  exclusions: [],
  iacTypes: [],
  stacks: [],
  arns: [],
  frns: [],
  owners: [],
  tags: [],
  integrationIds: [],
  stateLocationsStrings: [],
  providerTypes: {
    providerType: [],
    providerSubType: [],
    provider: [],
    integrationId: [],
  },
  misconfiguration: false,
  reliability: false,
  optimization: false,
  history: false,
  git: false,
  comments: false,
  deletedRange: [],
  module: null,
  deleted: false,
  relationship: false,
  masterType: ["cloud"],
  excluded: false,
  vcsId: "",
  vcsRepo: "",
  governance: false,
  taggingStatus: "",
};

export const defaultInventoryColumnVisibilityModel = {
  __check__: true,
  integrationId: true,
  assetType: true,
  name: true,
  arn: false,
  state: true,
  stacks: false,
  region: true,
  assetId: true,
  ownerData: true,
  lastConfigurationChangeTime: false,
  resourceCreationDate: true,
  resourceStatus: true,
  costDelta: false
}

export const FILTERS_KEYS = {
  tags: 'tags',
}

export const NOT_RELEVANT_STATES = ["iacIgnored", "child", "pending", "undetermined"];