import React from "react";
import { Tooltip } from 'antd';

import "./gradientBorderButton.scss";
const GradientBorderButton = ({ children, customGradient, customInnerBackground, onClick, tooltip = "", customContentStyle = {}, customClassName="", tooltipPlacement = "top"}) => {
    const contentStyle = {
        ...customContentStyle,
        background: customInnerBackground,
    }
    return (
        <Tooltip title={tooltip} placement={tooltipPlacement}>
            <div className={`GradientBorderButton ${customClassName}`} style={customGradient ? { background: customGradient } : null }>
                    <div className="GradientBorderButton__content" onClick={onClick} style={contentStyle}>
                        {children}
                    </div>
            </div>
        </Tooltip>
    );
};

export default GradientBorderButton;