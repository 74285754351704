import React from "react";
import "./percentageBar.scss";
import { useSelector } from "react-redux";
import Tooltip from "antd/lib/tooltip";


const PercentageBar = ({
    count = 0,
    total = 0,
    tooltipTitle = "",
}) => {

    const isThemeDark = useSelector(
        (state) => state.userPreferencesReducer.themeDark
    );

    const getLabel = (percentage) => {
        if (typeof percentage !== 'number' || isNaN(percentage)) {
            percentage = 0;
        }
        return `${Math.floor(percentage)}%`;
    };

    const getSize = (label) => {
        let size;
        switch (label) {
            case '1%':
                size = "one";
                break;
            case '2%':
                size = "two";
                break;
            case '3%':
                size = "three";
                break;
            case '4%':
                size = "four";
                break;
            case '98%':
            case '99%':
            case '100%':
                size = "hundred";
                break;
            default:
                break;
        }
        return size;
    }

    const percentage = count > 0 && total > 0 ? (count / total) * 100 : 0;
    const theme = isThemeDark ? "dark" : "light";
    const label = getLabel(percentage);
    const size = getSize(label);

    return (
        <Tooltip overlayClassName='PercentageBar-Tooltip' title={<span> {tooltipTitle} <br /> {`${count} / ${total}`}</span>}>
            <div className="PercentageBar">
                <span className="PercentageBar__progress-label">{label}</span>
                <div className={`PercentageBar__progress-container ${theme}`}>
                    <div className={`PercentageBar__progress-bar ${size}`} style={{ width: label }}></div>
                </div>
            </div>
        </Tooltip>
    );
};

export default PercentageBar;