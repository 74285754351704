import React from "react";
import map from "lodash/map";
import {
  gitFlag,
  historyFlag,
  commentsFlag,
  relationshipFlag,
  noTagsFlag,
  governanceFlag,
} from "./flagIcons";
import "./assetFlagsFilter.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import useWindowSize from "../../../hooks/useWindowSize";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from '../../../utils/amplitudeEvents';

const AssetFlagsFilter = ({ handleSetFilter }) => {
  const { t } = useTranslation("inventory");
  const windowSize = useWindowSize();
  const showText = windowSize?.width > 1600;

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );
  const aggregationsComments = useSelector(
    (state) => state.inventoryReducer.aggregationsComments
  );
  const aggInsights = useSelector(
    (state) => state.inventoryReducer.aggregationsClassification
  );
  const aggregationsHistory = useSelector(
    (state) => state.inventoryReducer.aggregationsHistory
  );
  const aggregationsGit = useSelector(
    (state) => state.inventoryReducer.aggregationsGit
  );
  const aggregationsRelationship = useSelector(
    (state) => state.inventoryReducer.aggregationsRelationship
  );
  const aggregationsNoTags = useSelector(
    (state) => state.inventoryReducer.aggregationsNoTags
  );

  const options = [
    {
      text: t("header-filters.flags.governance"),
      icon: governanceFlag(
        aggInsights,
        themeDark
      ),
      key: "governance",
      active: screenFilters?.governance,
      isEnable: aggInsights,
    },
    {
      text: t("header-filters.flags.comments"),
      icon: commentsFlag(aggregationsComments, themeDark),
      key: "comments",
      active: screenFilters?.comments,
      isEnable: aggregationsComments,
    },
    {
      text: t("header-filters.flags.history"),
      icon: historyFlag(aggregationsHistory, themeDark),
      key: "history",
      active: screenFilters?.history,
      isEnable: aggregationsHistory,
    },
    {
      text: t("header-filters.flags.git"),
      icon: gitFlag(aggregationsGit, themeDark),
      key: "git",
      active: screenFilters?.git,
      isEnable: aggregationsGit,
    },
    {
      text: t("header-filters.flags.notags"),
      icon: noTagsFlag(aggregationsNoTags, themeDark),
      key: "noTags",
      active: screenFilters?.noTags,
      isEnable: aggregationsNoTags,
    },
    {
      text: t("header-filters.flags.relationship"),
      icon: relationshipFlag(aggregationsRelationship, themeDark),
      key: "relationship",
      active: screenFilters?.relationship,
      isEnable: aggregationsRelationship,
    },
  ];

  const handleClick = (item) => {
    if (!item?.isEnable) {
      return;
    }
    sendEvent(inventoryEvents.clickedGroupedFilterTiles, { filterName: item.text});
    return handleSetFilter(item?.key);
  };

  return (
    <div className="AssetFlagsFilter">
      {map(options, (item) => {
        return (
          <Tooltip
            placement="bottom"
            title={showText ? null : item?.text}
            key={item?.key}
            overlayClassName="AssetFlagsFilter__tooltip"
          >
            <div
              className={`AssetFlagsFilter__item center col ${item?.key} ${
                item?.active ? "active" : ""
              } ${item?.isEnable ? "" : "disable"}`}
              onClick={() => handleClick(item)}
            >
              <span className="AssetFlagsFilter__item-icon">{item?.icon}</span>
              {showText && (
                <span className="AssetFlagsFilter__item-text centered">
                  {item?.text}
                </span>
              )}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default AssetFlagsFilter;
