import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useDispatch } from "react-redux";

import { handleUnsavedModalAction } from "../../../../redux/actions/iacImportActions";
import "./unsavedCodifyModal.scss";
import { sendEvent } from "../../../../utils/amplitude";
import { codifyEvents } from "../../../../utils/amplitudeEvents";

const UnsavedCodifyModal  = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleModalActions = async (continueToOtherScreen) => {
        setLoading(true);
        await dispatch(handleUnsavedModalAction(continueToOtherScreen))
        sendEvent(continueToOtherScreen ? codifyEvents.clickedCodifyEditExitWithoutSaving : codifyEvents.clickedCodifyEditCancelWithouSaving)
        setLoading(false);
    }
    return (
        <Modal
            visible={true}
            onCancel={() => handleModalActions(false)}
            closable
            title=""
            footer={null}
            destroyOnClose={true}
            className="UnsavedCodifyModal"
            width="600px"
            wrapClassName="UnsavedCodifyModal__wrap"
            centered
            zIndex={1141}
        >
            <div className="UnsavedCodifyModal__container col g15">
                <span className="UnsavedCodifyModal__title font-16 bold">
                    Exit Edit Mode Without Saving?
                </span>
                <span className="UnsavedCodifyModal__sub">
                    Exiting edit mode will discard any unsaved changes. Are you sure you want to continue?
                </span>
                <div className="UnsavedCodifyModal__buttons row g10">
                    <Button
                        className="app-form-button close"
                        type="Default"
                        onClick={() => handleModalActions(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="app-form-delete"
                        danger
                        htmlType="submit"
                        loading={loading}
                        onClick={() => handleModalActions(true)}
                    >
                        Exit without saving
                    </Button>
                </div>

            </div>
        </Modal>
    )
}

export default UnsavedCodifyModal;