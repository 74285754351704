import React, { useEffect, useRef } from "react";
import isFunction from "lodash/isFunction";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import Editor from "@monaco-editor/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../../shared/loading/loading";
import { sendEvent } from "../../../utils/amplitude";
import { codifyEvents } from "../../../utils/amplitudeEvents";

import GradientBorderButton from "../../../shared/gradientBorderButton/gradientBorderButton";
import { changeCodifyDrawerProperty } from "../../../redux/actions/iacImportActions";

import { Tooltip } from "antd";
import "./codeSyntaxEditor.scss";

const CodeSyntaxEditor = ({ code = "", onSave = () => null, language = "hcl", onClickAddFile }) => {
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const editMode = useSelector((state) => state.iacImportReducer.editMode) || false;
    const codeValue = useSelector((state) => state.iacImportReducer.tempCode.new);
    const latestCodeRef = useRef(code);

    const dispatch = useDispatch();
    const shouldBeAddIcon = isFunction(onClickAddFile);
    
    const isMacSystemOperation = navigator.platform?.toLowerCase().indexOf("mac") >= 0;
    
    useEffect(() => {
        latestCodeRef.current = code;
    }, [code]);

    useEffect(() => {
        window.addEventListener("keydown", (e) => {
            const isInputTarget = e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA";
            const userTryToEnterEdit = !isInputTarget && e.key === ".";
            const userTryToSave = e.key === "s" && (e.ctrlKey || e.metaKey);
            if (!editMode && userTryToEnterEdit) {
                onSetEditMode(true);
            }
            if (editMode && userTryToSave) {
                e.preventDefault();
                onSave(codeValue);
                onSetEditMode(false);
            };
        });
    }, [codeValue]);

    const onSetEditMode = (toggle) => dispatch(changeCodifyDrawerProperty("editMode", toggle));
    const setCodeValue = (newCode) =>  {
        latestCodeRef.current = newCode;
        dispatch(changeCodifyDrawerProperty("tempCode", { new: newCode , old: code }));
    }
    
    const onEditSaveClick = (e) => {
        e.preventDefault();
        const toggle = !editMode;
        if (toggle) {
            sendEvent(codifyEvents.clickedEditCodification, {});
        }
        else {
            onSave(latestCodeRef?.current);
            sendEvent(codifyEvents.clickedSaveEditCodification, {});
        }
        onSetEditMode(toggle)
    }
    
    const onCancelEdit = () => {
        sendEvent(codifyEvents.clickedCancelEditCodification, {});
        onSetEditMode(false);
    }
    const onAddFileClicked = (e) => {
        e.preventDefault();
        onClickAddFile();
    }

    return (
    <div className="CodeSyntaxEditor basic-100 col">
        <GradientBorderButton onClick={(e) => e.stopPropagation()} customClassName="CodeSyntaxEditor__header row g10">
                {editMode ? 
                <Tooltip title={`Cancel (${isMacSystemOperation ? '⎋':'Esc'})`}>
                    <div className="CodeSyntaxEditor__header-btn" onClick={onCancelEdit}>
                        <FontAwesomeIcon icon="times" />
                    </div>
                </Tooltip>
                : shouldBeAddIcon &&
                <Tooltip title="Add File">
                    <div className="CodeSyntaxEditor__header-btn" onClick={onAddFileClicked}>
                        <FontAwesomeIcon icon="file-code"/>
                    </div>
                </Tooltip>}
                {(editMode || shouldBeAddIcon) && <div className="CodeSyntaxEditor__header-divider"/>}
                <Tooltip title={editMode ? `Save (${isMacSystemOperation ? '⌘':'Ctrl + '}S)` : `Edit (.)`} placement={editMode ?"topLeft" : "top"}>
                    <div className="CodeSyntaxEditor__header-btn" onClick={onEditSaveClick}>
                        <FontAwesomeIcon icon={editMode ? "save" :"edit"}  />
                    </div>
                </Tooltip>
        </GradientBorderButton>
        <div className={`CodeSyntaxEditor__code ${editMode ? "edit" : ""} `}>
            {editMode ? <Editor className="CodeSyntaxEditor__editor" value={code} options={{fontSize: 14}} loading={<div className="basic-loading"><Loading /> </div>} 
            onChange={(newVal) => setCodeValue(newVal)} language={language} theme={themeDark ? "vs-dark" : "light" } />
            : <SyntaxHighlighter
                style={atomDark}
                showLineNumbers={true}
                language={language}
                lineProps={{
                style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                }}
            >
                {code}
            </SyntaxHighlighter>}
        </div>  
    </div>
    );
}

export default CodeSyntaxEditor;