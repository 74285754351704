import { concat } from "lodash";

export const updateCheckedKeys = (checkedKeysValue = [], info = {}, providerGroups = {}) => {
    const checkedNodes = getAllCheckedNodes(info)

    if (info.checked) {
        const providers = Object.keys(providerGroups);
        checkedNodes.forEach(key => {
            if (!checkedKeysValue.includes(key)) {
                if (checkProviderChildern(providers, key, providerGroups, checkedNodes, checkedKeysValue)) {
                    checkedKeysValue.push(key);
                }
            }
        });
        return checkedKeysValue;
    }
    const key = info.node.key;
    const childrenKeys = getAllChildrenKeys(info.node);
    const provider = getProvider(info)
    checkedKeysValue = checkedKeysValue.filter((k) => k !== key && k !== provider);
    childrenKeys.forEach(childKey => {
        checkedKeysValue = checkedKeysValue.filter((k) => k !== childKey);
    });

    return checkedKeysValue;
};

const getAllCheckedNodes = (info = {}) => {
    return info?.checkedNodes?.length > 0 ? info.checkedNodes.map(checkedNode => checkedNode.key) : [];
};

const getAllChildrenKeys = (node) => {
    return node?.children?.length > 0
      ? node.children.reduce((keys, child) => keys.concat(child.key, getAllChildrenKeys(child)), [])
      : [];
  };

const getProvider = (info = {}) => {
    if (info?.node?.title?.props?.children?.length > 0) {
        return info.node.title.props.children[0].props.provider
    }
    return '';
}

const checkProviderChildern = (providers = [], key = '', providerGroups, checkedNodes, checkedKeysValue) => {
    if (!providers.includes(key)) return true
    const providerGroupsIds = providerGroups[key].map(provider => provider.id)
    const checkedIds = concat(checkedNodes, checkedKeysValue);
    const isAllChildrenChecked = providerGroupsIds.every(id => checkedIds.includes(id));
    return isAllChildrenChecked;
};