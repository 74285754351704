import React, { useState, useEffect, Suspense, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import compact from "lodash/compact";
import Base64 from "../../../utils/base64";
import { formatAwsStringWithUnderscore, getAllProvidersArray } from "../../../utils/formatting";
import AppBtn from "../../../shared/appBtn/appBtn";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import MatchingAssetCell from "../insightsTable/matchingAssetCell";
import SavingsCell from "../insightsTable/savingsCell";
import { Badge, Button, Popover, Spin } from "antd";
import SeverityCell from "../insightsTable/severityCell";
import { LoadingOutlined, PlusOutlined, ToolFilled } from "@ant-design/icons";
import { getNotificationByInsightId } from "../../../redux/actions/notificationsActions";
import { integrationIcons } from "../../../utils/icons";
import CreateNotification2 from "../../notifications/createNotification/createNotification2";
import CategoryCell from "../insightsTable/categoryCell";
import { suggestionsArr } from "../../../consts/suggestions";
import "./governanceDrawer.scss";
import AssetTypeRowCellV2 from "../insightsTable/assetTypeRowCellV2";
import { ReactComponent as NoRegoCodeIcon } from '../../../Images/general_icons/no_rego_code.svg'; 
import { translateProviderName } from "../../../utils/insightsHelper";
import FrameworkCell from "../insightsTable/frameworkCell";

const loadingIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;


const GovernanceDrawer = ({ visible, closeDrawer, data = {}, handleShowSavings, handleOpenIssue, handleClose }) => {
  const { t } = useTranslation("insights");
  const history = useHistory();
  const dispatch = useDispatch();

  const [code, setCode] = useState(null);
  const [notification, setNotification] = useState();
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [notificationModelOpen, setNotificationModelOpen] = useState(false);
  const [insightsLoading, setInsightsLoading] = useState(false);

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const isRegoCodeExist = !isEmpty(data?.rego);

  useEffect(() => {
    setNotification(null);
    if (data && data?.isSubscribed) {
      fetchInsightNotification()
    }
  }, [data?.id]);

  useEffect(() => {
    if (visible) {
      fetchCode();
    }
  }, [visible]);

  const fetchCode = () => {
    setCode(`${isEmpty(data?.rego) ? "" : Base64.decode(data?.rego)}`);
  };

  const fetchInsightNotification = async () => {
    setNotificationLoading(true);
    const notificationResult = await dispatch(getNotificationByInsightId(data.id));
    if (notificationResult && notificationResult.populatedIntegration) {
      setNotification({
        ...notificationResult,
        notificationIntegrations: [notificationResult.populatedIntegration]
      });
    } else if(!notificationResult && notification){
      setNotification(null);
    };
    setNotificationLoading(false);
  }

  const handleOnNotificationCreated = () => {
    data.isSubscribed = true;
    fetchInsightNotification();
  }
  const handleNotificationDeleted = () => {
    data.isSubscribed = false;
    fetchInsightNotification();
  }

  const renderCode = () => {
    if (!isEmpty(code)) {
      return (
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {code}
        </SyntaxHighlighter>
      );
    }
    return (
      <></>
    );
  };

  const handleEdit = () => {
    return history.push({
      pathname: "/governance/create-custom-control",
      state: {
        data: { ...data, id: data?._id },
      },
    });
  };

  const renderCategory = () => {
    if (!isEmpty(data?.type)) {
      return <CategoryCell value={data?.category} />;
    }
    return null;
  };

  const renderWhenDisabled = () => {
    const { excluded_assets, total_assets, isScanning } = data || {};
    return (!excluded_assets && !total_assets) || isScanning;
  };

  const getPopoverContent = (type, key, data) => {
    return <ul className="InsightDetails__body__popover-content">
      {data?.map((item = {}) => <li key={uuidv4()}>
        {type === 'provider' ? <ProviderIcon provider={key} customStyle="integration" /> : <AssetTypeImage assetType={item} />}
        {type === 'provider' ? item?.name : formatAwsStringWithUnderscore(item)}
      </li>)}
    </ul>
  }
  const getFormattedResourceDisplay = (type) => {
    switch (type) {
      case "provider":
        const allProviders = getAllProvidersArray(providerIntegrations);
        let providerKey = data?.providers?.[0];
        let matchedProviders = data?.providerIds?.map(providerId => allProviders.find(provider => provider.id === providerId)) ?? [];
        
        if (matchedProviders.length === 1 && matchedProviders[0] == undefined){
            providerKey = translateProviderName(providerKey)
            matchedProviders = []
        }

        if (isEmpty(matchedProviders)) {
          return <ProviderIcon provider={providerKey} customStyle="integration" />;
        }
        else if (matchedProviders.length === 1) {
          return <div className="row g10">
            {<ProviderIcon provider={providerKey} customStyle="integration" />}
            <span>{matchedProviders?.[0]?.name}</span>
          </div>
        }
        return <Badge count={matchedProviders.length} className="InsightDetails__body__badge" size="small">
          <Popover content={getPopoverContent('provider', providerKey, matchedProviders)} placement="bottomLeft">
            <div>
              <ProviderIcon provider={providerKey} customStyle="integration" />
            </div>
          </Popover>
        </Badge>;

      case "assetTypes":
        if (data?.type?.length == 1) {
          return <div className="row g10">
            <AssetTypeImage assetType={data?.type?.[0]} />
            <span>{formatAwsStringWithUnderscore(data?.type?.[0])}</span>
          </div>;
        }
        return <AssetTypeRowCellV2 value={data?.type} />;
    }
  }

  const getFormattedNotification = () => {
    if (notificationLoading) return <Spin indicator={loadingIcon} />;
    if (!notification || !notification.populatedIntegration) return <Button className="InsightDetails" disabled={isViewer} type="link" icon={<PlusOutlined />} onClick={() => setNotificationModelOpen(true)}>Add new</Button>;
    const integration = notification?.populatedIntegration;
    return <div className={`row g10 ${isViewer ? 'InsightDetails__disabled' : ''}`} onClick={() => {
      if (!isViewer) {
        setNotificationModelOpen(true);
      }
    }}>
      <img
        src={integrationIcons[integration?.service]}
        alt="integration icon"
        className={integration?.service}
      />
      <span>{integration.service === 'email' ? t('email-address') : integration?.name}</span>
    </div>
  }
  
  const hasInsights = data?.cost && data?.isEnabled && data?.total_assets;
  const isAiRemediation= data?.type?.length === 1 && data?.total_assets > 0
  const isFrameworksExist = !isEmpty(data?.frameworks);
   
  const insightDetailsRows = compact([
    {
      label: t('table.columns.data-source'),
      data: getFormattedResourceDisplay("provider")
    },
    {
      label: t('table.columns.matching'),
      data: <MatchingAssetCell row={data} closeDrawer={closeDrawer} handleClose={handleClose} />
    },
    {
      label: t('table.columns.asset-type'),
      data: getFormattedResourceDisplay('assetTypes')
    },
    (hasInsights || isAiRemediation) && {
      label: t('table.columns.saving'),
      data: <SavingsCell
        row={data}
        handleShowSavings={(e) => handleShowSavings(e, data)}
      />
    },
    {
      label: t('table.columns.notification'),
      data: getFormattedNotification()
    },
    {
      label: t('table.columns.severity'),
      data: <SeverityCell value={data?.severity} />
    },
    isFrameworksExist && {
      label: t('table.columns.framework'),
      data: <FrameworkCell frameworks={data?.frameworks} />
    },
  ]);
  const isSuggestionsCodeExist = suggestionsArr?.find((item) => item?.name === data?.name);

  return (
    <>
      <AppDrawer
        title={data?.name}
        subTitle={renderCategory()}
        closeDrawer={closeDrawer}
        visible={visible}
        width="900px"
        hideClose
        bodyClassName=" non-scroll"
        footer={compact([
          !data?.isDefault && (
            <AppBtn
              text={t("table.menu.edit")}
              onClick={handleEdit}
              icon={<FontAwesomeIcon icon="pen" />}
              key="edit"
            />
          ),
          hasInsights && isSuggestionsCodeExist && (
            <AppBtn
              gradientOutlined
              text={t("table.menu.remediation")}
              onClick={(e) => handleShowSavings(e, data)}
              icon={<ToolFilled />}
              disabled={renderWhenDisabled()}
              key="remediation"
            />
          ),
          <AppBtn
            plain
            text={t("table.menu.ticket")}
            onClick={() => handleOpenIssue(data)}
            icon={<FontAwesomeIcon icon={["fab", "jira"]} />}
            disabled={renderWhenDisabled()}
            key="issue-ticket"
          />,
        ])}
      >
        <div className="InsightDetails">
          <div className="InsightDetails__heading">
            {t("policy-description")}
          </div>
          <div className="InsightDetails__paragraph-wrapper">
            <p>{data?.description}</p>
          </div>
          <div className="InsightDetails__body">
            {insightDetailsRows.map((item) => (
              <React.Fragment key={uuidv4()}>
                <label className={item?.label}>{item?.label}</label>
                <div className={item?.label}>{item?.data}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
        {isRegoCodeExist ? (
          <div className="CodeDrawer__body">{renderCode()}</div>
        ) : (
          <div className="CodeDrawer__body center col g15">
            { NoRegoCodeIcon && <NoRegoCodeIcon /> }
            <span className="text bold">This policy is not managed by OPA (Rego)</span>
          </div>
        )}
      </AppDrawer>
      <Suspense>
        <CreateNotification2
          visible={notificationModelOpen}
          initialValues={notification}
          handleClose={() => setNotificationModelOpen(false)}
          insightsLoading={insightsLoading}
          insights={data?.id}
          onSubmitCallback={handleOnNotificationCreated}
          deletable
          onDeleteCallback={handleNotificationDeleted}
        />
      </Suspense>
    </>
  );
};

export default memo(GovernanceDrawer);
