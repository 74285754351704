import React, { useEffect, useState } from "react";
import { Collapse, Radio } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { TablePagination } from "@mui/material";
import "./aiRemediationVc.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AiRemediationVcTable from "./table/aiRemediationVcTable";
import { useDispatch, useSelector } from "react-redux";
import { getAiRemediationCloud } from "../../../../redux/actions/aiRemediationActions";
import Loading from "../../../../shared/loading/loading";
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";
import { formatUrl, getIcon } from "./aiRemediation.utils";
import AiRemediationCliCollapse from "./aiRemediationCliCollapse";
import { clouds } from "../../../../utils/icons";

const AiRemediationProvider = ({
  data,
  setSlectedPath,
  selectedPath,
  loadingFix,
}) => {
  const [activeKey, setActiveKey] = useState(null);
  const [page, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [activeKeyCollapse, setActiveKeyCollapse] = useState({});

  const dispatch = useDispatch();

  const cloudData = useSelector(
    (state) => state.aiRemediationReducer?.cloud || {}
  );

  const afterKey = useSelector(
    (state) => state.aiRemediationReducer?.afterKeyCloud || {}
  );

  const totalFiles = useSelector(
    (state) => state.aiRemediationReducer?.totalFilesCloud
  );

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const classificationsId = data?._id;

  useEffect(() => {
    let skip = 0;
    if (page > 0) {
      skip = afterKey[page - 1] || "";
    }
    fetchCode(skip);
  }, [rowsPerPage, page]);

  const fetchCode = async (skip) => {
    setLoading(true);
    const response = await dispatch(
      getAiRemediationCloud(classificationsId, rowsPerPage, skip)
    );
    if (response?.abort) return;
    setLoading(false);
  };

  const { Panel } = Collapse;

  const onChangeRadio = (e) => {
    setSlectedPath(e?.target?.value);
  };

  const onCollapseChange = (keys = [], integrationId = "") => {
    const shouldCloseCollapseActiveKeys = keys[0] === integrationId
    if (shouldCloseCollapseActiveKeys) {
      setActiveKeyCollapse((prev) => {
        return { ...prev, [integrationId]: [] };
      });
    } 
  };

  const handleSetRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setTablePage(0);
  };

  const renderHeader = (title, count, provider) => (
    <div className="panel-header">
      <div className="panel-header-content">
        <RightOutlined className="panel-arrow-icon" />
        <span className="panel-icon-provider">
          <img src={clouds(provider, themeDark)} />
        </span>
        <span className="panel-title">{title}</span>
        <span className="panel-count purple-flag g5 row">
          <FontAwesomeIcon icon="layer-group" className="purple-text" />
          {count}
        </span>
      </div>
    </div>
  );

    const codeDataFlat = Object.entries(cloudData).flatMap(
      ([vcId, items]) => items
    );

  const isCodeDataPagination = (totalFiles || 0) > 10;

  return loading ? (
    <Loading />
  ) : (
    <div className="AiRemediationVc AiRemediationProvider col g15">
      <h2>Cloud Patch</h2>
      {codeDataFlat.map((item = {}) => (
        <Collapse
          onChange={(open) => onCollapseChange(open, item.integrationId)}
          key={item.integrationId}
        >
          <Panel
            header={renderHeader(item.integrationName, item.count, item.provider)}
            key={item.integrationId}
          >
            <AiRemediationCliCollapse
              classificationsId={classificationsId}
              totalAssets={item.count}
              integrationId={item.integrationId}
              setActiveKey={setActiveKeyCollapse}
              activeKey={activeKeyCollapse}
              classificationData={data}
            />
          </Panel>
        </Collapse>
      ))}

      {isCodeDataPagination && <TablePagination
        component="div"
        className="AiRemediationVc__pagination"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onRowsPerPageChange={handleSetRowsPerPage}
        count={totalFiles}
        page={page}
        onPageChange={(e, page) => setTablePage(page)}
      />}
    </div>
  );
};

export default AiRemediationProvider;
