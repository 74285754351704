import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Input, Popover, Tooltip } from "antd";
import {
  changeCodifyDrawerProperty,
  createModule,
  setModuleFile,
} from "../../../../redux/actions/iacImportActions";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import map from "lodash/map";
import keys from "lodash/keys";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import { iacProviders } from '../../../../utils/icons';
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";
import { ACTIVE_CODIFICATION_TYPES } from "../../codifyDrawer/codifyHelpers";
import CodeSyntaxEditor from "../codeSyntaxEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropMenu from "../../../../shared/dropMenu/dropMenu";
import { sendEvent } from "../../../../utils/amplitude";
import { codifyEvents } from "../../../../utils/amplitudeEvents";
import { appToast } from "../../../../shared/appToast/appToast";
import { renderEllipsis } from "../../../../utils/formatting";
import "./createModuleTab.scss";

const MAX_STRING_LENGTH_TO_RENDER = 50000;
const CreateModuleTab = ({ selectedResources, codifyType, activeTab, isMultiCodify, iacData, handleOverweightData, OverweightDataDisplay, onSaveCode, onAddFile, onEditFileName, onDeleteFile }) => {
  const dispatch = useDispatch();
  const [loadingCreate, setLoadinCreate] = useState(false);
  const [enabledFileInput, setEnabledFileInput] = useState("");

  const moduleFileSelected = useSelector(
    (state) => state.iacImportReducer.moduleFileSelected
  );
  const moduleDataCreated = useSelector(
    (state) => state.iacImportReducer.moduleDataCreated
  );
  const arrFileNames = isMultiCodify ? iacData?.map((item) => item.filePath) : Object.keys(moduleDataCreated);
  
  useEffect(() => {
    if (!isMultiCodify) {
      return handleCreateModule();
    }
    initMultiCodify();
  }, [codifyType, activeTab]);

  useEffect(() => {
    if (moduleFileSelected) {
      const syntaxToRender = isMultiCodify ? iacData?.find((iacData) => iacData.filePath === moduleFileSelected)?.content
      : isString(moduleDataCreated[moduleFileSelected]) ? moduleDataCreated[moduleFileSelected] : ''
      if(syntaxToRender?.length > MAX_STRING_LENGTH_TO_RENDER) {
        handleOverweightData(true)
      }
      else {
        handleOverweightData(false)
      }
    }

  }, [moduleFileSelected]);

  const initMultiCodify = async () => {
    setLoadinCreate(true);
    const isFileSelected = iacData?.find((item) => item.filePath === moduleFileSelected);
    if (isFileSelected) return setLoadinCreate(false);
    await dispatch(setModuleFile(iacData[0]?.filePath));
    setLoadinCreate(false);
  };

  const handleCreateModule = async () => {
    setLoadinCreate(true);

    let assetType = "";
    let assetId = "";
    let provider = "";
    let providerId = "";
    let resources = [];

    for (let i = 0; i < selectedResources?.length; i++) {
      const resource = selectedResources[i];

      if (i === 0) {
        assetType = resource.assetType;
        assetId = resource.assetId;
        provider = resource.provider;
        providerId = resource.integrationId;
      } else {
        resources.push({
          terraformAssetType: resource.assetType,
          providerId: resource.integrationId,
          assetId: resource.assetId,
        });
      }
    }

    const payload = {
      assetType,
      assetId,
      providerId,
      provider,
      resources,
      codificationMode: codifyType === ACTIVE_CODIFICATION_TYPES.createModule ? "CREATE_MODULE_PORTKEY" : "CREATE_MODULE_PORTKEY_WITH_DEPENDENCIES",
    };

    const res = await dispatch(createModule(payload));
    if (res?.abort) return; 
    setLoadinCreate(false);
  };
  const getLinks = (fileName) => [
    {
      text: "Edit file name",
      icon: <FontAwesomeIcon icon="pen" className="purple-text" />,
      onClick: (e) => {
        e.stopPropagation();
        sendEvent(codifyEvents.clickedCodificationRenameFile);
        setEnabledFileInput(fileName);
      }
    },
    {
      text: "Delete file",
      icon: <FontAwesomeIcon icon="times-circle" className="purple-text"/>,
      onClick: (e) =>{
        e.stopPropagation();
        sendEvent(codifyEvents.clickedCodificationDeleteFile)
        onDeleteFile(fileName)
      }
    }
  ]
  const handleEditFileName = (e, oldName) => {
    e.stopPropagation();
    const newName = e.target.value;
    if (newName === oldName) return;
    if (arrFileNames.includes(newName)) {
      appToast("error", "", "File name already exists", null, null, { autoClose: 2000 });
      return onEditFileName(oldName, oldName);
    }
    onEditFileName(newName, oldName);
  };
  const handleRenderFileList = () => {
    if (isEmpty(moduleDataCreated) && isEmpty(iacData)) {
      return null;
    }
    // if error msg returned
    if (keys(moduleDataCreated)?.includes("msg")) {
      return null;
    }
    const arrayToMap = isMultiCodify ? iacData : moduleDataCreated;
    return map(arrayToMap, (val, valKey) => {
      const key = isMultiCodify ? val?.filePath : valKey;
      const isSelected = moduleFileSelected === key;
      const isCurrFileInputEnabled = enabledFileInput === key;
      const fileName = key?.split("/").pop();
      return (
          <span
              key={uuidv4()}
              className={`CreateModuleTab__menu-item row g10 ${
                isSelected ? "active" : null
              }`}
              onClick={(e) => {
                if (isSelected) return;
                setEnabledFileInput("");
                dispatch(setModuleFile(key))
              }}
          >
        {!isMultiCodify && <img src={iacProviders('terraform')} alt="terraform" />}
              <Tooltip title={fileName?.length > 12 ? fileName : ""}>
                {isCurrFileInputEnabled ?
                <Input className="CreateModuleTab__menu-item-input" id={`inputs-${key}`} 
                onBlur={(e) => handleEditFileName(e, fileName)}
              onPressEnter={(e) => handleEditFileName(e, fileName)} defaultValue={fileName}  />
               : <span className="CreateModuleTab__menu-item-text">{renderEllipsis(fileName, 12)}</span>}
            </Tooltip>
               <Popover
                content={<DropMenu links={getLinks(key)} tooltipPlacement="right"/>}
                title={null}
                onClick={(e) => e.stopPropagation()}
                placement="right"
                overlayClassName="InsightMenuCell"
              >
                <span className="CreateModuleTab__menu-item-popover">
                  <FontAwesomeIcon icon="ellipsis-v" className="WorkspaceMenu-icon" />
                </span>
              </Popover>
      </span>
      );
    });
  };
  const onSaveNewCode = async (newCode) => {
    if (isMultiCodify) {
      return onSaveCode(newCode, moduleFileSelected);
    }
    await dispatch(changeCodifyDrawerProperty("moduleDataCreated", { ...moduleDataCreated, [moduleFileSelected]: newCode }));
  };

  const renderCode = () => {
    const shouldRenderNoData = !moduleFileSelected || (isMultiCodify ? isEmpty(iacData) : isEmpty(moduleDataCreated));
    if(shouldRenderNoData) {
      return <AppEmpty text="No data" customStyle="code" />
    }
    const syntaxToRender = isMultiCodify ? iacData.find((iacData) => iacData.filePath === moduleFileSelected)?.content
    : isString(moduleDataCreated[moduleFileSelected]) ? moduleDataCreated[moduleFileSelected] : ''
    if(syntaxToRender?.length > MAX_STRING_LENGTH_TO_RENDER) {
      return <div className="tab-page center">{OverweightDataDisplay}</div>
    }
    return <CodeSyntaxEditor code={syntaxToRender} onSave={onSaveNewCode} />
  }

  if (loadingCreate) {
    return (
      <div className="tab-page center">
        <LongLoader customLoader={<Loading />} duration={10000} 
          customClassName="col g10 center font-18 bold" loading={loadingCreate}
          msg1={<span className="row g8">Generating Terraform Module <TypingDots isInText/></span>}
          msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}/>
      </div>
    );
  }

  if (!loadingCreate && isEmpty(moduleDataCreated) && isEmpty(iacData)) {
    return (
      <div className="tab-page center">
        <AppEmpty text="No Module data" customStyle="code" />
      </div>
    );
  }

   // if error msg returned
   if (!loadingCreate && keys(moduleDataCreated)?.includes("msg") && isEmpty(iacData) ) {
    return (
      <div className="tab-page center">
        <AppEmpty text={moduleDataCreated?.msg} customStyle="code" />
      </div>
    )
  }

  return (
    <div className="CreateModuleTab">
      <div className="CreateModuleTab__menu col">
        {handleRenderFileList()}
        <div className="CreateModuleTab__menu-item row g10 pointer" onClick={onAddFile}>
            <FontAwesomeIcon icon="plus" />
            <span>Add File</span>
        </div>
        </div>
      <div className="CreateModuleTab__code">
      {renderCode()}
      </div>
    </div>
  );
};

export default CreateModuleTab;
