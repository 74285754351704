import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faInfoCircle,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import "./appToast.scss";

const toastConfig = {
  error: {
    title: "Error",
    text: "We’re sorry, something went wrong",
    icon: faTimesCircle,
  },
  success: {
    title: "Success",
    text: "the operation is completed",
    icon: faCheckCircle,
  },
  info: {
    title: "Info",
    text: "See more information here",
    icon: faInfoCircle,
  },
  warning: {
    title: "Warning",
    text: "it looks like you’re in trouble ",
    icon: faExclamationTriangle,
  },
};

export const appToast = (type, customTitle, customText, customIcon, customBody, customOptions = {}) => {
  const toastMsg = () => {

    if(customBody) {
      return customBody;
    }
    return (
      <div className="appToast row">
        <FontAwesomeIcon icon={customIcon? customIcon : toastConfig[type].icon} className="appToast__icon" />
        <div className="appToast__content col">
          <div className="appToast__content-title">{customTitle ? customTitle : toastConfig[type].title}</div>
          <div className="appToast__content-text">{customText ? customText : toastConfig[type].text}</div>
        </div>
      </div>
    );
  };

  return toast[type ? type : 'info'](toastMsg, customOptions);
};
