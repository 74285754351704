import React, { useEffect, useState } from "react";
import { Tooltip } from "antd";
import TableWrapper from "../../../../../shared/tableWrapper/tableWrapper";
import { useDispatch, useSelector } from "react-redux";
import { getAiRemediationCodeAssets } from "../../../../../redux/actions/aiRemediationActions";
import AssetDataSourceCell from "../../../../inventory/inventoryTable/assetDataSourceCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iacStatusIconByType, prettifyStackName } from "../../../../../utils/formatting";
import "./aiRemediationVcTable.scss";

const columns = [
  {
    headerName: "Data Source",
    field: "provider",
    minWidth: 50,
    flex: 1,
    renderCell: (params) => {
      return (
        <AssetDataSourceCell
          row={params?.row}
          providerIntegrations={params?.row}
        />
      );
    },
  },
  {
    headerName: "Name",
    field: "name",
    minWidth: 50,
    flex: 1,
    renderCell: (params) => {
      const { state } = params.row;
      const isDrifted = state === "modified" || state === "Drifted";
       return (
      <>
        <span className="AiRemediationVcTable icon">
        {isDrifted && <FontAwesomeIcon icon={iacStatusIconByType(params.row.state)} />}
        </span>
        <span>{params.value}</span>
      </>
    )},
  },
  {
    headerName: "ID",
    field: "assetId",
    minWidth: 50,
    flex: 1,
    renderCell: (params) => (
      <Tooltip title={params.value}>
        <span>{params.value}</span>
      </Tooltip>
    ),
  },

  {
    headerName: "Location",
    field: "region",
    minWidth: 50,
    flex: 1,
  },
  {
    headerName: "Stacks",
    field: "stacks",
    minWidth: 50,
    flex: 1,
    renderCell: (params) => (params.value || []).map((val) => prettifyStackName(val)).join(", "),
  },
];

const AiRemediationVcTable = ({
  classificationsId,
  pathCollapse,
  totalAssets,
  integrationId,
}) => {
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const codeAssetsData = useSelector(
    (state) => state.aiRemediationReducer?.codeAssets || {}
  );

  const afterKey = useSelector(
    (state) => state.aiRemediationReducer?.afterKeyAssets || {}
  );
  
  useEffect(() => {
    fetchCodeAssets();
  }, [pathCollapse]);

  const fetchCodeAssets = async (skip = '') => {
    setLoading(true);
    const response = await dispatch(
      getAiRemediationCodeAssets(classificationsId, pathCollapse, skip, integrationId)
    );
    if (response?.abort) return;
    setLoading(false);
  };

  const assetsCodeData = codeAssetsData[pathCollapse] || [];

  useEffect(() => {
    let skip = '';
    if(pageNumber > 1){
      const afterKeyArray = afterKey[pathCollapse] || [];
      skip = afterKeyArray[pageNumber - 2] || '';
    }
     fetchCodeAssets(skip);
  }, [pageNumber]);

  
  return (
    <div>
      <TableWrapper
        rowKey="assetId"
        tableData={assetsCodeData || []}
        columns={columns}
        loading={loading}
        disableSelectionOnClick
        pageSize={pageSize}
        rowCount={totalAssets}
        handlePageChange={setPageNumber}
        serverSide
      />
    </div>
  );
};

export default AiRemediationVcTable;