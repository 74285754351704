import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    changeCodifyDrawerProperty,
    fetchGeneratedCodify,
} from "../../../../redux/actions/iacImportActions";
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";

import CreateModuleTab from "../createModuleTab/createModuleTab";
import Loading from "../../../../shared/loading/loading";
import { CODIFY_TYPES, RESOURCE_STATE_TYPES } from "../../../../consts/general";
import { ACTIVE_CODIFICATION_TYPES, getUniqueModuleFileName } from "../../codifyDrawer/codifyHelpers";
import { sendEvent } from "../../../../utils/amplitude";
import { codifyEvents } from "../../../../utils/amplitudeEvents";
import "./createComposition.scss";

const CreateComposition = ({ selectedResources = [], handleOverweightData, OverweightDataDisplay }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); 
  const firstResource = selectedResources[0] || {};
  const isSelectedResourceDeleted = firstResource.state === RESOURCE_STATE_TYPES.deleted;
  const compositionData = useSelector((state) => state.iacImportReducer.compositionData) || [];


  useEffect(() => {
    fetchCompositionData();
  }, []);

  const fetchCompositionData = async () => {
    setLoading(true);
    const payload = {
      codeProvider: "crossplaneComposition",
      language: CODIFY_TYPES.crossplane,
      accountId: firstResource.accountId,
      integrationId: firstResource.integrationId,
      isDeleted: isSelectedResourceDeleted,
      FRNs: selectedResources.map((resource) => resource.frn),
    }
    const data = await dispatch(fetchGeneratedCodify(payload));
    if (data?.abort) return;
    await dispatch(changeCodifyDrawerProperty("compositionData", data || []));
    setLoading(false);
  };

  const onSaveCode = async (newCode, selectedFilePath) => {
    const updatedData = compositionData.map((data = {}) => data.filePath === selectedFilePath ? { ...data, content: newCode } : data);
    await dispatch(changeCodifyDrawerProperty("compositionData", updatedData));
  }
  
  const onAddFile = async () => {
    sendEvent(codifyEvents.clickedCodificationAddNewFile);
    const newName = getUniqueModuleFileName(compositionData.map((data) => data?.filePath));
    const newFile = { filePath: newName, content: "" };
    await dispatch(changeCodifyDrawerProperty("addFile", { compositionData: [...compositionData, newFile], editMode: true, moduleFileSelected: newName }, true));
  };
  const onEditFileName = async (newName, oldName) => {
    const updatedData = compositionData.map((data = {}) => data.filePath === oldName ? { ...data, filePath: newName } : data);
    await dispatch(changeCodifyDrawerProperty("compositionData", updatedData));
    await dispatch(changeCodifyDrawerProperty("moduleFileSelected", newName));
  };
  const onDeleteFile = async (fileName) => {
    const updatedData = compositionData.filter((data = {}) => data.filePath !== fileName);
    await dispatch(changeCodifyDrawerProperty("compositionData", updatedData));
    await dispatch(changeCodifyDrawerProperty("moduleFileSelected", updatedData[0]?.filePath));
  };

  if (loading) {
    return (
        <div className="tab-page center">
            <LongLoader customLoader={<Loading />} duration={10000} 
            customClassName="col g10 center font-18 bold" loading={loading}
            msg1={<span className="row g8">Generating composition <TypingDots isInText/></span>}
            msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}/>
        </div>)
  }
  return (
    <CreateModuleTab isMultiCodify codifyType={ACTIVE_CODIFICATION_TYPES.createComposition} onSaveCode={onSaveCode} onAddFile={onAddFile}
    iacData={compositionData} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay} 
    onEditFileName={(newName, oldName) => onEditFileName(newName, oldName)} onDeleteFile={(fileName) => onDeleteFile(fileName)}
    />) 
};

export default CreateComposition;
