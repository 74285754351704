import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { getIconMutedColor } from "../../utils/helpers";
import "./noTagsLabelIndicator.scss";

const NotagsLabelIndicator = ({
    indicator,
}) => {
    const themeDark = useSelector(
        (state) => state.userPreferencesReducer.themeDark
    );


    return (
        <Tooltip
            placement="top"
            title="Untagged"
            style={{ cursor: "pointer" }}
            overlayInnerStyle={{
            fontSize: "13px",
            }}
        >
            <button
            className="NotagsLabelIndicator center"
            >
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path d="M0.914188 5.78849L5.40861 1.29407C5.67685 1.02583 6.04161 0.876613 6.42094 0.879937L9.45798 0.90633C10.239 0.913296 10.8664 1.55204 10.8594 2.33301L10.8328 5.29608C10.8305 5.55596 10.7567 5.80792 10.6224 6.02565C10.0762 5.62488 9.40203 5.38819 8.6726 5.38819C6.85026 5.38819 5.37297 6.86549 5.37297 8.68782C5.37297 9.43111 5.61873 10.117 6.03343 10.6686L5.91389 10.7882C5.36163 11.3404 4.46626 11.3404 3.91401 10.7882L0.914188 7.78837C0.361937 7.23612 0.361937 6.34074 0.914188 5.78849ZM8.41373 3.28864C8.68986 3.56477 9.13755 3.56477 9.41367 3.28864C9.6898 3.01252 9.6898 2.56483 9.41367 2.2887C9.13755 2.01258 8.68986 2.01258 8.41373 2.2887C8.13761 2.56483 8.13761 3.01252 8.41373 3.28864Z" fill={!indicator ? getIconMutedColor(themeDark) : "#9872FE"}/>
                        <path d="M8.75 6C8.2061 6 7.67442 6.16128 7.22218 6.46346C6.76995 6.76563 6.41747 7.19512 6.20933 7.69762C6.00119 8.20012 5.94673 8.75305 6.05284 9.2865C6.15895 9.81995 6.42086 10.3099 6.80546 10.6945C7.19005 11.0791 7.68005 11.341 8.2135 11.4472C8.74695 11.5533 9.29988 11.4988 9.80238 11.2907C10.3049 11.0825 10.7344 10.7301 11.0365 10.2778C11.3387 9.82558 11.5 9.2939 11.5 8.75C11.4992 8.02089 11.2092 7.32187 10.6937 6.80631C10.1781 6.29076 9.47911 6.00078 8.75 6ZM8.75 6.6875C9.1777 6.68815 9.59443 6.82282 9.94163 7.07258L7.07233 9.94129C6.8518 9.63356 6.72051 9.2709 6.69295 8.89331C6.66538 8.51572 6.7426 8.13784 6.91611 7.80134C7.08961 7.46484 7.35265 7.18277 7.67624 6.98624C7.99983 6.7897 8.3714 6.68632 8.75 6.6875ZM8.75 10.8125C8.32231 10.8119 7.90557 10.6772 7.55837 10.4274L10.4277 7.55871C10.6482 7.86644 10.7795 8.22909 10.8071 8.60669C10.8346 8.98428 10.7574 9.36216 10.5839 9.69866C10.4104 10.0352 10.1473 10.3172 9.82376 10.5138C9.50017 10.7103 9.1286 10.8137 8.75 10.8125Z" fill={!indicator ? getIconMutedColor(themeDark) : "#9872FE"}/>
                    </g>
                </svg>
            </button>
        </Tooltip>
        );
    };

export default NotagsLabelIndicator;
