import {CODIFY_TYPES, IAC_TYPES, PROVIDERS, RESOURCE_STATE_TYPES} from "../../../consts/general";
import { appToast } from "../../../shared/appToast/appToast"
import { areAllRegionsSame } from "../../../utils/helpers";
import { includes, isEmpty, map, keys, isArray, isString, groupBy } from "lodash";
import { providers } from "../../../utils/providers";
import { sendEvent } from "../../../utils/amplitude";
import { codifyEvents, inventoryEvents } from "../../../utils/amplitudeEvents";
import awsGrayIcon from "../../../Images/clouds/aws_gray.svg";
import awsWhiteIcon from "../../../Images/clouds/aws_white.svg";
import azureGrayIcon from "../../../Images/clouds/azure_gray.svg";
import azureWhiteIcon from "../../../Images/clouds/azure_white.svg";
import googleGrayIcon from "../../../Images/clouds/google_gray.svg";
import googleWhiteIcon from "../../../Images/clouds/google_white.svg";
import { formatIacStatus } from "../../../utils/formatting";

export const ACTIVE_CODIFICATION_TYPES = {
  naive: "naive",
  smart: "smart",
  modules: "modules",
  createModule: "createModule",
  createComposition: "createComposition",
  createModuleDependencies: "createModuleDependencies",
  dependencies: "dependencies",
  fullDependencies: "fullDependencies",
  resourceGroup: "resourceGroup",
  awsMigration: "awsMigration",
  azureMigration: "azureMigration",
  googleMigration: "googleMigration",
};

const REVERSE_LEARNING_REQUEST_TABS = [ACTIVE_CODIFICATION_TYPES.naive, ACTIVE_CODIFICATION_TYPES.smart, ACTIVE_CODIFICATION_TYPES.dependencies, ACTIVE_CODIFICATION_TYPES.fullDependencies, ACTIVE_CODIFICATION_TYPES.resourceGroup];
export const CREATE_MODULE_CODIFICATION_MODES = [
    ACTIVE_CODIFICATION_TYPES.createModule,
    ACTIVE_CODIFICATION_TYPES.createModuleDependencies
]

export const MIGRATION_PROVIDERS_TYPES = {
  [ACTIVE_CODIFICATION_TYPES.awsMigration]: PROVIDERS.aws,
  [ACTIVE_CODIFICATION_TYPES.azureMigration]: PROVIDERS.azurerm,
  [ACTIVE_CODIFICATION_TYPES.googleMigration]: PROVIDERS.gcp,
}
export const PROVIDERS_MIGRATION_TYPES = {
  [PROVIDERS.aws]: ACTIVE_CODIFICATION_TYPES.awsMigration,
  [PROVIDERS.azurerm]: ACTIVE_CODIFICATION_TYPES.azureMigration,
  [PROVIDERS.gcp]: ACTIVE_CODIFICATION_TYPES.googleMigration,
}

export const DEFAULT_CLOUD_MIGRATION_CODIFICATION_TYPE = {
  [PROVIDERS.aws]: ACTIVE_CODIFICATION_TYPES.azureMigration,
  [PROVIDERS.azurerm]: ACTIVE_CODIFICATION_TYPES.awsMigration,
  [PROVIDERS.gcp]: ACTIVE_CODIFICATION_TYPES.awsMigration,
};
const MUTUAL_CODIFY_TYPES = [CODIFY_TYPES.terraform, CODIFY_TYPES.tfcdk, IAC_TYPES.pulumi];

export const PROVIDERS_CODIFY_TYPES = {
  [PROVIDERS.aws]: [...MUTUAL_CODIFY_TYPES, CODIFY_TYPES.cloudformation, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2, CODIFY_TYPES.crossplane, CODIFY_TYPES.ansible],
  [PROVIDERS.azurerm]: [...MUTUAL_CODIFY_TYPES, CODIFY_TYPES.arm, CODIFY_TYPES.bicep, CODIFY_TYPES.crossplane, CODIFY_TYPES.ansible],
  [PROVIDERS.gcp]: [...MUTUAL_CODIFY_TYPES, CODIFY_TYPES.configConnector, CODIFY_TYPES.googleCloudSdk, CODIFY_TYPES.crossplane, CODIFY_TYPES.ansible],
}
export const MODULE_CALL_FORMAT_TYPES = {
  HCL: "HCL",
  YAML: "YAML",
  JSON: "JSON",
  TFVARS: "TFVARS",
  TFVARS_JSON: "TFVARS_JSON",
}
export const MODULE_CALL_FORMATS_WITHOUT_IMPORT_BLOCKS = [MODULE_CALL_FORMAT_TYPES.JSON, MODULE_CALL_FORMAT_TYPES.TFVARS, MODULE_CALL_FORMAT_TYPES.TFVARS_JSON];

export const CODIFY_LANGUAGES = ["typescript", "python", "go", "csharp", "java"];
export const LANGS_SUFFIX = {
    typescript: "ts",
    python: "py",
    go: "go",
    csharp: "cs",
    java: "java"
}
export const CODIFICATION_MODES = {
  naive: "NAIVE",
  smart: "DATA",
  dependencies: "BY_STATE",
  fullDependencies: "FULL",
  resourceGroup: "RESOURCE_GROUP",
  modules: "MODULE",
};

const K8S = 'k8s'
const REDACT_WARNING = 'This code configuration contains sensitive data properties that should be added separately.'

export const alertCodifyRedact = (customToast) => customToast || appToast("info", "Info", REDACT_WARNING);
export const alertIfCodifyRedact = ({ activeCodificationType, activeTab, mapData = {}, createModulesRedactedFiles, moduleFileSelected, customToast, isK8sRedacted = false, isK8sSelectedProvider = false }) => {
  let isRedacted = false;
  if (includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType)) {
    isRedacted = createModulesRedactedFiles[moduleFileSelected];
  } else if (activeCodificationType !== ACTIVE_CODIFICATION_TYPES.modules) { // no need to check modules tab (it's not redacted)
    switch (activeTab) {
      case CODIFY_TYPES.terraform:
        isRedacted = isK8sSelectedProvider ? isK8sRedacted  : mapData[CODIFY_TYPES.terraform][0]?.isRedactedValue
        break;
      case CODIFY_TYPES.tfcdk:
        isRedacted = mapData[CODIFY_TYPES.tfcdk][0]?.isRedactedValue
        break;
      case K8S:
        isRedacted = isK8sRedacted;
        break;
      default:
        isRedacted = mapData[activeTab][0]?.isRedactedValue;
        break;
    }
  }
  return isRedacted ? alertCodifyRedact(customToast) : null;
}

export const handleCallbackByActiveTab = (key = '', fetchIacImport = () => null, 
activeLanguage, fetchNewCodify = () => null, selectedResources = [], identifyIfCodeIsUnsaved = false) => {
  switch (key) {
    case CODIFY_TYPES.terraform:
      fetchIacImport(key, null, ACTIVE_CODIFICATION_TYPES.naive);
      break;
    case CODIFY_TYPES.tfcdk:
      fetchIacImport(key, activeLanguage, ACTIVE_CODIFICATION_TYPES.naive, identifyIfCodeIsUnsaved);
      break;
    case CODIFY_TYPES.cloudformation:
      const isSameRegions = areAllRegionsSame(selectedResources);
      if (isSameRegions) {
        fetchNewCodify(key, null, false, identifyIfCodeIsUnsaved);
      }
      break;
    case CODIFY_TYPES.pulumi:
    case CODIFY_TYPES.cdk:
    case CODIFY_TYPES.cdkl2:
    case CODIFY_TYPES.cdk8s:
      fetchNewCodify(key, activeLanguage, false);
      break;
    case CODIFY_TYPES.crossplane:
    case CODIFY_TYPES.ansible:
    case CODIFY_TYPES.configConnector:
    case CODIFY_TYPES.googleCloudSdk:
    case CODIFY_TYPES.arm:
    case CODIFY_TYPES.bicep:
    case CODIFY_TYPES.helm:
      fetchNewCodify(key, null, false);
      break;
    case K8S:
      fetchIacImport(key, null, null);
      break;
    default:
      break;
  }
}
const allowedMigrationTypesByProvider = {
  [PROVIDERS.aws]: [ACTIVE_CODIFICATION_TYPES.azureMigration, ACTIVE_CODIFICATION_TYPES.googleMigration],
  [PROVIDERS.azurerm]: [ACTIVE_CODIFICATION_TYPES.awsMigration, ACTIVE_CODIFICATION_TYPES.googleMigration],
  [PROVIDERS.gcp]: [ACTIVE_CODIFICATION_TYPES.awsMigration, ACTIVE_CODIFICATION_TYPES.azureMigration],
}
export const onCodifyDrawerVisible = ({ selectedResources = [], setActiveCodificationType = () => null, setActiveTab = () => null, 
fetchIacImport = () => null, fetchCloudMigration = () => null, fetchNewCodify = () => null, activeTab = IAC_TYPES.terraform, activeLanguage, activeCodificationType }) => {
  const firstResource = selectedResources?.[0] || {};
  const provider = firstResource.provider;
  const isCloudMigrationActiveCodificationType = includes(keys(MIGRATION_PROVIDERS_TYPES), activeCodificationType);
  const isCloudMigration = firstResource.isCloudMigration || isCloudMigrationActiveCodificationType;
  if (isCloudMigration) {
    setActiveTab(CODIFY_TYPES.terraform, initialActiveCodificationType);
    const initialActiveCodificationType = allowedMigrationTypesByProvider[provider].includes(activeCodificationType) ? activeCodificationType : DEFAULT_CLOUD_MIGRATION_CODIFICATION_TYPE[provider];
    fetchCloudMigration(MIGRATION_PROVIDERS_TYPES[DEFAULT_CLOUD_MIGRATION_CODIFICATION_TYPE[provider]]);
  } else if (!isEmpty(selectedResources) && providers[provider]?.codifyDefault === CODIFY_TYPES.terraform) {
    const defaultActiveTab = PROVIDERS_CODIFY_TYPES[provider]?.includes(activeTab) ? activeTab : CODIFY_TYPES.terraform;
    setActiveTab(defaultActiveTab, activeCodificationType);
    const shouldFetchIacImport = [CODIFY_TYPES.terraform, CODIFY_TYPES.tfcdk].includes(activeTab) && REVERSE_LEARNING_REQUEST_TABS.includes(activeCodificationType) 
    if (shouldFetchIacImport) {
      return fetchIacImport(activeTab, activeTab === CODIFY_TYPES.tfcdk ? activeLanguage : "", activeCodificationType);
    }
    if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.naive) {
      return fetchNewCodify(activeTab, activeLanguage, true);
    }
  } else {
    setActiveTab(K8S, activeCodificationType);
    fetchIacImport(K8S);
  }
  // send codify events
  if (!isEmpty(selectedResources)) {
    sendEvent(inventoryEvents.codifyDrawerOpen,
      {
        assetType: firstResource.assetType,
        assetName: firstResource.name,
        assetNumber: selectedResources.length,
        assetStatus: firstResource.state
      });
  }
}
export const getModuleCallPreparedContent = (moduleData = {}, isImportBlocksClicked) => {
  const files = moduleData.Files || {};
  const mainTfFile = files["main.tf"];
  if (mainTfFile) {
    files["main.tf"] = `${isImportBlocksClicked && !isEmpty(moduleData.TerraformImportBlocks) ? `${moduleData.TerraformImportBlocks?.join("\n")}`: ''} \n${mainTfFile}`
  }
  const mappedFiles = Object.keys(files).map((filePath) => ({ filePath, content: files[filePath] })) || [];
  if (mappedFiles.length > 0) {
    mappedFiles[0].terraformCMDs = moduleData.TerraformCMDs || [];
  }
  return mappedFiles;
}


export const sendGeneratedEvent = ({activeCodificationType, visible, codifySessionId, activeTab, activeLanguage, isImportBlocksClicked, isProviderBlockClicked, selectedResources }) => {
  if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules || !visible) return;
  let payload = { iacUUID: codifySessionId, iacType: activeTab, codificationType: activeCodificationType,
    isImportBlocksEnabled: isImportBlocksClicked, isProviderBlockEnabled: isProviderBlockClicked, assetType: (selectedResources || []).map((res) => res?.assetType) };
  const providerMigration = MIGRATION_PROVIDERS_TYPES[activeCodificationType]
  const isLanguageSupported = [CODIFY_TYPES.tfcdk, CODIFY_TYPES.pulumi, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2, CODIFY_TYPES.cdk8s].includes(activeTab);
  payload = { ...payload, ...(isLanguageSupported ? { language: activeLanguage } : {}), ...(providerMigration ? { providerMigration } : {}) }
  sendEvent(codifyEvents.generatedIac, payload);
};

export const getIacMappedData = (terraformIacData, tfCdkData, otherIacData, k8sIacData, activeCodificationType) => {
  return ({
    terraform: terraformIacData?.[activeCodificationType] || [],
    tfcdk: tfCdkData?.[activeCodificationType] || [],
    pulumi: otherIacData[CODIFY_TYPES.pulumi],
    k8s: k8sIacData,
    cloudformation: otherIacData[CODIFY_TYPES.cloudformation],
    cdk: otherIacData[CODIFY_TYPES.cdk],
    cdkl2: otherIacData[CODIFY_TYPES.cdkl2],
    crossplane: otherIacData[CODIFY_TYPES.crossplane],
    ansible: otherIacData[CODIFY_TYPES.ansible],
    configConnector: otherIacData[CODIFY_TYPES.configConnector],
    googleCloudSdk: otherIacData[CODIFY_TYPES.googleCloudSdk],
    helm: otherIacData[CODIFY_TYPES.helm],
    cdk8s: otherIacData[CODIFY_TYPES.cdk8s],
    arm: otherIacData[CODIFY_TYPES.arm],
    bicep: otherIacData[CODIFY_TYPES.bicep],
  });
}
export const getIacImportPayload = (firstResource, type, lang, resources, codificationMode) => {
  return ({
    accountId: firstResource.accountId,
    resourceType: firstResource.assetType,
    resourceId: firstResource.assetId,
    iacType: type,
    driftNumber: firstResource.driftNumber,
    returnPayloadType: type === CODIFY_TYPES.tfcdk ? lang : "hcl",
    resources,
    provider: firstResource.provider,
    providerId: firstResource.integrationId,
    codificationMode: CODIFICATION_MODES[isEmpty(codificationMode) ? "naive" : codificationMode],
    revisionId: firstResource.revisionId,
    isDeleted: firstResource.state === RESOURCE_STATE_TYPES.deleted,
    frn: firstResource.frn,
  })
}
export const checkIfSelectedDataDisabled = ({ activeTab,activeCodificationType, moduleDataCreated, moduleFileSelected,
   moduleData, terraformIacData, isModuleCallActiveCodificationTab, tfCdkData, k8sIacData, otherIacData
}) => {
  if(activeTab === CODIFY_TYPES.terraform && includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType)) {
    return isEmpty(moduleDataCreated[moduleFileSelected])
  }
  switch (activeTab) {
    case CODIFY_TYPES.terraform:
      return isModuleCallActiveCodificationTab ? isEmpty(moduleData?.Files) : isEmpty(terraformIacData[activeCodificationType]);
    case CODIFY_TYPES.tfcdk:
      return isModuleCallActiveCodificationTab ? isEmpty(moduleData?.Files) : isEmpty(terraformIacData[activeCodificationType]);
    case IAC_TYPES.k8s:
      return isEmpty(k8sIacData);
    default:
      return isEmpty(otherIacData[activeTab])
  }
};
export const getFileType = ({activeTab, isModuleCallActiveCodificationTab, selectedModuleCallFilePath, activeLanguage, otherIacData, moduleFileSelected}) => {
  switch (activeTab) {
    case CODIFY_TYPES.terraform:
      if (isModuleCallActiveCodificationTab) {
       return selectedModuleCallFilePath?.split(".")?.pop() || "tf";
      }
      return "tf";
    case CODIFY_TYPES.tfcdk: case CODIFY_TYPES.pulumi: case CODIFY_TYPES.cdk: case CODIFY_TYPES.cdkl2: case CODIFY_TYPES.cdk8s:
      return LANGS_SUFFIX[activeLanguage];
    case CODIFY_TYPES.cloudformation: return "yaml";
    
    default:
      const isMultipleFilesCodify = isArray(otherIacData[activeTab]) && otherIacData[activeTab].length > 1;
      return isMultipleFilesCodify ? (!isEmpty(moduleFileSelected)?  moduleFileSelected.split(".").pop() : '') : "yaml";
  }
};
export const handleSetFileExportName = ({activeTab, isModuleCallActiveCodificationTab, selectedModuleCallFormat, selectedModuleCallFilePath, activeCodificationType, 
  otherIacData, moduleFileSelected, compositionData = [], tfCdkData = [], terraformIacData = [], k8sIacData = []
}) => {
  if(activeTab === CODIFY_TYPES.pulumi) {
    return CODIFY_TYPES.pulumi;
  }
  if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.createComposition) {
    const selectedFile = compositionData?.find((file) => file?.filePath === moduleFileSelected) || {};
    const fileName = selectedFile?.filePath?.split(".");
    return fileName?.length > 0 ? fileName[0] : fileName;
  }
  if (isModuleCallActiveCodificationTab) {
    return [MODULE_CALL_FORMAT_TYPES.JSON, MODULE_CALL_FORMAT_TYPES.TFVARS_JSON].includes(selectedModuleCallFormat) ? selectedModuleCallFilePath.replace(".json", "") :
    selectedModuleCallFilePath?.split(".")?.shift() || "main";
  }
  // set different file names for create module tab
  const isTerraformCreateModule = activeTab === CODIFY_TYPES.terraform && includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType);
  const isOtherCodifyMultipleFiles = activeTab !== CODIFY_TYPES.terraform && activeCodificationType === ACTIVE_CODIFICATION_TYPES.naive && isArray(otherIacData[activeTab]) && otherIacData[activeTab].length > 1;
  const isTfOrCdkMultipleFiles = (activeTab === CODIFY_TYPES.terraform ?  terraformIacData[activeCodificationType]?.length > 1 : activeTab === CODIFY_TYPES.tfcdk ? tfCdkData[activeCodificationType]?.length > 1 : false);
  const isK8sMultipleFiles = activeTab === IAC_TYPES.k8s && k8sIacData?.length > 1;
  if( (isTerraformCreateModule || isOtherCodifyMultipleFiles || isTfOrCdkMultipleFiles || isK8sMultipleFiles) && !isEmpty(moduleFileSelected)) {
    const fileName = moduleFileSelected.split(".");
    return fileName?.length > 0 ? fileName[0] : fileName
  }
  return 'main'
}
export const getSelectedData = ({
  isTerraformOrTfCdk, isModuleCallActiveCodificationTab, moduleData, selectedModuleCallFilePath,
  activeCodificationType, activeTab, terraformIacData = [], tfCdkData = [], k8sIacData, otherIacData, moduleDataCreated, moduleFileSelected, isImportBlocksClicked,
  compositionData
}) => {
  if (isTerraformOrTfCdk && isModuleCallActiveCodificationTab) {
    const fileContent = moduleData?.Files?.[selectedModuleCallFilePath]
    return selectedModuleCallFilePath === "main.tf" ? `${isImportBlocksClicked && !isEmpty(moduleData?.TerraformImportBlocks) ? `${moduleData.TerraformImportBlocks.join("\n")}\n`: ''}${fileContent}`: fileContent; 
  }
  if (activeCodificationType === ACTIVE_CODIFICATION_TYPES.createComposition) {
    const selectedFile = compositionData?.find((file) => file?.filePath === moduleFileSelected) || {};
    return selectedFile?.content;
  }
  if(isTerraformOrTfCdk && includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType)) {
    return moduleDataCreated[moduleFileSelected]
  }

  switch (activeTab) {
    case CODIFY_TYPES.terraform:
      if (isEmpty(terraformIacData[activeCodificationType])) return "";
      if (terraformIacData[activeCodificationType].length === 1) {
        return terraformIacData[activeCodificationType][0].content;
      }
      return terraformIacData[activeCodificationType].find((file) => file?.filePath === moduleFileSelected)?.content || "";
    case CODIFY_TYPES.tfcdk:
      if (isEmpty(tfCdkData[activeCodificationType])) return "";
      return tfCdkData[activeCodificationType].find((file) => file?.filePath === moduleFileSelected)?.content || tfCdkData[activeCodificationType][0].content;
    case IAC_TYPES.k8s:
      return k8sIacData?.find((file) => file?.filePath === moduleFileSelected)?.content || "";
    default:
      const otherDataByTab = otherIacData[activeTab];
      const isOtherDataArray = isArray(otherDataByTab);
      const selectedData = isOtherDataArray ? (
          otherDataByTab.length === 1 ? otherDataByTab[0].content 
        : otherDataByTab.find((file) => file?.filePath === moduleFileSelected)?.content) // case of multiple codify files
        : ''
      return selectedData;
  }
};

export const configureBasicArr = (isCodifyAI, isRevisionOrDeletedCodify, t, isCrossplaneActiveTab) => [
  { value:  ACTIVE_CODIFICATION_TYPES.naive, text: t("menu.explicitValues"), isBeta: isCodifyAI },
  { value: ACTIVE_CODIFICATION_TYPES.smart, text: t("menu.dataSources"), disabled: isRevisionOrDeletedCodify, hide: isCrossplaneActiveTab },
];

export const configureAdvancedArr = (isRevisionOrDeletedCodify, providerType, isTfCdkCodifyType, t, isCrossplaneActiveTab) => (
  isCrossplaneActiveTab ? 
  [
    {
      value: ACTIVE_CODIFICATION_TYPES.createComposition,
      text: t("menu.createComposition"),
      isBeta: true,
    }
  ]
  : [
      {
        value: ACTIVE_CODIFICATION_TYPES.dependencies,
        text: t("menu.unmanagedDeps"),
        disabled: isRevisionOrDeletedCodify,
      },
      {
        value: ACTIVE_CODIFICATION_TYPES.fullDependencies,
        text: t("menu.allDeps"),
        disabled: isRevisionOrDeletedCodify,
      },
      {
        value: ACTIVE_CODIFICATION_TYPES.resourceGroup,
        text: t("menu.codifyResourceGroup"),
        hide: providerType !== PROVIDERS.azurerm ,
      },
      {
        value: ACTIVE_CODIFICATION_TYPES.modules,
        text: t("menu.moduleCall"),
        isBeta: false,
        disabled: isRevisionOrDeletedCodify,
        unvisible: isTfCdkCodifyType,
      },
      {
        value: ACTIVE_CODIFICATION_TYPES.createModule,
        text: t("menu.createModule"),
        isBeta: false,
        disabled: isRevisionOrDeletedCodify || isTfCdkCodifyType,
        unvisible: isTfCdkCodifyType,
      },
      {
        value: ACTIVE_CODIFICATION_TYPES.createModuleDependencies,
        text: t("menu.createModuleWithDeps"),
        isBeta: false,
        disabled: isRevisionOrDeletedCodify || isTfCdkCodifyType,
        unvisible: isTfCdkCodifyType,
      },
    ]);
export const configureCloudMigrationArr = (isRevisionOrDeletedCodify, providerType) => (
  [
    {
      value: ACTIVE_CODIFICATION_TYPES.awsMigration,
      text: "AWS",
      disabled: isRevisionOrDeletedCodify || providerType === PROVIDERS.aws,
      hide: providerType === PROVIDERS.aws,
      icon: awsGrayIcon,
      selectedIcon: awsWhiteIcon,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.azureMigration,
      text: "Azure",
      disabled: isRevisionOrDeletedCodify || providerType === PROVIDERS.azurerm,
      hide: providerType === PROVIDERS.azurerm,
      icon: azureGrayIcon,
      selectedIcon: azureWhiteIcon,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.googleMigration,
      text: "Google Cloud",
      disabled: isRevisionOrDeletedCodify || providerType === PROVIDERS.gcp,
      hide: providerType === PROVIDERS.gcp,
      icon: googleGrayIcon,
      selectedIcon: googleWhiteIcon,
    },
  ]
)

export const getUniqueModuleFileName = (moduleFiles = []) => {
  let newName = "New file";
  let i = 1;
  const moduleFileWithoutExtension = moduleFiles.map((file = "") => file.split(".")[0]);
  while (moduleFileWithoutExtension.includes(newName)) {
    newName = `New file (${i})`;
    i++;
  }
  return newName;
}
const getResourceName = (code) => {
  if (isString(code)) {
    const words = code?.split(" ") || [];
    let name = words[2];
    name = name?.replaceAll(`"`, "");
    return name;
  }
  return "";
};
export const getTerraformContentByType = (type, data = []) => {
  switch(type) {
    case ACTIVE_CODIFICATION_TYPES.naive:
      const hclArray = data.map((source = {}) => source?.HclAsString);
      return hclArray.join("");
    case ACTIVE_CODIFICATION_TYPES.smart:
       const output = data.map((source = {}) => {
        const codeBlock = `# ${source?.AssetType} \n# ${getResourceName(source?.HclAsString)} [${formatIacStatus(source?.State)}] \n\n${source?.HclAsString}`;
        return codeBlock;
       });
       return output.join("");
    case ACTIVE_CODIFICATION_TYPES.dependencies:
    case ACTIVE_CODIFICATION_TYPES.fullDependencies:
    case ACTIVE_CODIFICATION_TYPES.resourceGroup:
      const assetTypeGroups = groupBy(data, "AssetType");
      const code = map(keys(assetTypeGroups), (assetType) => {
        const typeTitle = `# ${assetType}\n`;
        const inner = map(assetTypeGroups[assetType], (source) => {
          const name = `# ${getResourceName(source?.HclAsString)} [${formatIacStatus(source?.State)}]\n\n`;
          const innerCode = source?.HclAsString;
          const output = typeTitle + name + innerCode;
          return output;
        });
        const x = inner.join("");
        return x;
      });
      return code.join("");
  }
}