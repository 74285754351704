import React from "react";
import { useSelector } from "react-redux";
import { Form, Select } from "antd";
import { exportInventory } from "../../../redux/actions/inventoryv3Actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import { faDownload, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { sendEvent } from "../../../utils/amplitude";
import AppModal from "../../../shared/appModal/appModal";
import { appToast } from "../../../shared/appToast/appToast";

import "./exportModal.scss";

const ExportModal = ({ visible, handleClose }) => {
  const [form] = Form.useForm();
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const handleSubmitExport = async (event) => {
    const format = form.getFieldValue("format");
    sendEvent(inventoryEvents.export, { format });
    appToast("info", "Downloading", "The file is downloading in the background...", undefined, undefined, { autoClose: 10000});
    exportInventory(screenFilters, searchVal, format);
    handleClose();
    form.resetFields();
  };

  return (
    <AppModal
        visible={visible}
        handleClose={handleClose}
        title="Inventory Export"
        handleOnSubmit={handleSubmitExport}
        form="export-inventory-form"
        submitBtnIcon={
            <FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px" }} />
        }
        submitBtnText="Export"
        subtitle="The Inventory export will include all assets, according to the applied filters."
    >
        <Form
            name="export-inventory-form"
            form={form}
            className="ExportModal"
            initialValues={{
                format: "csv",
            }}
        >
            <Form.Item
                label="File Type"
                name="format"
                style={{ marginBottom: "1.5rem", flexDirection: "column" }}
            >
                <Select>
                    <Select.Option value={"csv"} key="csv">
                        <span className="ExportModal-select row">
                            <FontAwesomeIcon icon={faFileCsv} />
                            CSV
                        </span>
                    </Select.Option>
                    <Select.Option value={"json"} key="json">
                        <span>{`{ } JSON`}</span>
                    </Select.Option>
                </Select>
            </Form.Item>
        </Form>
    </AppModal>
  );
};

export default ExportModal;
