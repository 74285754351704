import React, { useEffect, useState } from "react";
import { ReactComponent as IacFix } from "../../../../Images/general_icons/governance/remediation/iacFix.svg";
import { ReactComponent as CloudFix } from "../../../../Images/general_icons/governance/remediation/cloudFix.svg";
import "./aiRemediationSteps.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../../../utils/formatting";
import { useDispatch, useSelector } from "react-redux";
import { getAiRemediationSummary } from "../../../../redux/actions/aiRemediationActions";
import Loading from "../../../../shared/loading/loading";
import { sendEvent } from "../../../../utils/amplitude";
import { aiRemediationEvents } from "../../../../utils/amplitudeEvents";

const AiRemediationSelection = ({ data, setCurrentStep }) => {
  const [hoverDescription, setHoverDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const summaryData = useSelector(
    (state) => state.aiRemediationReducer?.summary
  );
  const classificationsId = data?._id;

  const { t } = useTranslation("remediation", {
    keyPrefix: "aiRemediationeSelection",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    fetchSummary();
  }, []);

  const fetchSummary = async () => {
    setLoading(true);
    const response = await dispatch(getAiRemediationSummary(classificationsId));
    if (response?.abort) return;
    setLoading(false);
  };

  const handleItemClick = (key = "", isExists = false) => {
    if (!isExists) return;

    const fixPath = key === "code" ? " Patch IaC" : "Cloud Patch"; 
    const policyName = data?.name || "";
    const dataSource = data?.providers || [];
    const severity = data?.severity || "";

    const payload = {
      fixPath,
      policyName,
      dataSource,
      severity,
    };

    sendEvent(aiRemediationEvents.tiles, payload);

    if (key === "cloud") {
      return setCurrentStep(3);
    }
    setCurrentStep(1);
  };

  const getSummaryCount = (data, name) => {
    const item = data.find((item) => item.type === name);
    return item ? item.count : 0;
  };

  const items = [
    {
      key: "code",
      title: t("iac_fix.title"),
      icon: IacFix,
      description: t("iac_fix.description"),
      count: getSummaryCount(summaryData, "iac_fix"),
      recommended: true,
    },
    {
      key: "cloud",
      title: t("cloud_fix.title"),
      icon: CloudFix,
      description: t("cloud_fix.description"),
      count: getSummaryCount(summaryData, "cloud_fix"),
    },
  ];

  return loading ? (
    <div className="AiRemediationSteps__AiRemediationeSelection__loading center">
      <Loading />
    </div>
  ) : (
    <div className="AiRemediationSteps__AiRemediationeSelection col center">
      <span className="AiRemediationSteps__AiRemediationeSelection-title">
        {t("title")}
      </span>
      <div className="AiRemediationSteps__AiRemediationeSelection__list">
        {items.map((item = "") => {
          const isExists = item.count !== 0;
          const IconComponent = item.icon;
          return (
            <div
              key={item.key}
              className={`AiRemediationSteps__AiRemediationeSelection__list-gradient ${
                !isExists ? "disabled" : ""
              }`}
              onClick={() => handleItemClick(item.key, isExists)}
              onMouseEnter={() => setHoverDescription(item.description)}
              onMouseLeave={() => setHoverDescription("")}
            >
              <div
                className={`basic-card AiRemediationSteps__AiRemediationeSelection__list-item col center ${
                  !isExists ? "disabled" : ""
                }`}
              >
                <div
                  className={`AiRemediationSteps__AiRemediationeSelection__list-item-icon row center ${
                    !isExists ? "disabled" : ""
                  }`}
                >
                  <div
                    className={`AiRemediationSteps__AiRemediationeSelection__list-item-icon-container row center ${
                      !isExists ? "disabled" : ""
                    }`}
                  >
                    <div
                      className={`AiRemediationSteps__AiRemediationeSelection__list-item-icon-mask row center ${
                        !isExists ? "disabled" : ""
                      }`}
                    >
                      <IconComponent
                        className={`AiRemediationSteps__AiRemediationeSelection__list-item-icon-img ${
                          !isExists ? "disabled" : ""
                        }`}
                      />
                    </div>
                  </div>
                </div>
                {item.recommended && (
                  <div
                    className={`AiRemediationSteps__AiRemediationeSelection__recommended mix-purple-green-flag bold ${
                      !isExists ? "disabled" : ""
                    }`}
                  >
                    Recommended
                  </div>
                )}
                <div
                  className={`AiRemediationSteps__AiRemediationeSelection__list-item-title ${
                    !isExists ? "disabled" : ""
                  }`}
                >
                  {item.title}
                </div>
                {isExists ? (
                  <div className="purple-flag row g5 mgTtm5">
                    <FontAwesomeIcon
                      icon="layer-group"
                      className="purple-text"
                    />
                    {numberWithCommas(item.count)}
                  </div>
                ) : (
                  <div className="col center mgTtm5">
                    <div className="grey-flag row g5">
                      <FontAwesomeIcon icon="layer-group" className="text" />
                      {numberWithCommas(item.count)}
                    </div>
                    <div className="text mgTtm5 font-11">No assets to patch</div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <span className="AiRemediationSteps__AiRemediationeSelection-description">
        {hoverDescription}
      </span>
    </div>
  );
};

export default AiRemediationSelection;
