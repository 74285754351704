import React from "react";
import CodeDiff from "../../../../shared/codeDiff/codeDiff";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import LongLoader from "../../../../shared/longLoader/longLoader";
import Loading from "../../../../shared/loading/loading";
import TypingDots from "../../../../shared/typingDots/typingDots";
import "./AiRemediationCompere.scss";
import { formatUrl, getIcon } from "./aiRemediation.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AiRemediationCompere = ({
  setFilesInfo,
  selectedPath = "",
  loadingFix,
}) => {
  const iacFix = useSelector(
    (state) => state.aiRemediationReducer?.iacFix || {}
  );

  const codeData = useSelector(
    (state) => state.aiRemediationReducer?.code || {}
  );

  const assetdata = codeData[selectedPath] || {};

  const { assetCount = 0, vcsType = "" } = assetdata;

  const icon = getIcon(vcsType);
  const title = formatUrl(selectedPath);

  const oldValue = iacFix.before || "";
  const newValue = iacFix.after || "";

  return (
    <>
      <div className="AiRemediationCompere__path">
        <div className="AiRemediationCompere__path__content">
          <span className="AiRemediationCompere__path__icon">{icon}</span>
          <span
            className="AiRemediationCompere__path__title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></span>
          <span className="AiRemediationCompere__path__count purple-flag g5 row">
            <FontAwesomeIcon icon="layer-group" className="purple-text" />
            {assetCount}
          </span>
        </div>
      </div>
      <div className="AiRemediationCompere__header row g10">
        <span className="AiRemediationCompere__title">Before</span>
        <span className="AiRemediationCompere__title">After</span>
      </div>
      <div className="AiRemediationCompere__codeDiff">
        <CodeDiff
          lang="hcl"
          oldValue={oldValue}
          newValue={newValue}
          hideLineNumbers={false}
          showDiffOnly
        />
      </div>
    </>
  );
};

export default AiRemediationCompere;
