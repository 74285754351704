import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_SUMMARY_REMEDITIONS = "GET_SUMMARY_REMEDITIONS";
export const GET_CODE_REMEDITIONS = "GET_CODE_REMEDITIONS";
export const GET_CODE_ASSETS_REMEDITIONS = "GET_CODE_ASSETS_REMEDITIONS";
export const GET_AI_REMEDITIONS = "GET_AI_REMEDITIONS";
export const CREATE_PR_REMEDITIONS = "CREATE_PR_REMEDITIONS";
export const GET_CLOUD_REMEDITIONS = "GET_CLOUD_REMEDITIONS";
export const GET_CLOUD_ASSETS_REMEDITIONS = "GET_CLOUD_ASSETS_REMEDITIONS";
export const GET_AI_REMEDITIONS_CLOUD = "GET_AI_REMEDITIONS_CLOUD";
export const CLEAR_DATA = "CLEAR_DATA";

let abortWorkspacesController;
const getAiRemediationSummary = (classificationsId) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/summary`,
        "GET",
        null,
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_SUMMARY_REMEDITIONS,
        payload: [...data],
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const getAiRemediationCode = (classificationsId, rowsPerPage, skip) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/code?size=${encodeURIComponent(
          rowsPerPage
        )}&skip=${encodeURIComponent(skip)}`,
        "GET",
        null,
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_CODE_REMEDITIONS,
        payload: { ...data },
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const getAiRemediationCodeAssets = (classificationsId, path, skip) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/code/assets?path=${encodeURIComponent(
          path
        )}&skip=${encodeURIComponent(skip)}`,
        "GET",
        null,
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_CODE_ASSETS_REMEDITIONS,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const getAiRemediate = (
  classificationsId,
  asset_type,
  policy_name,
  policy_description,
  selectedPath
) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/code/remediate`,
        "POST",
        {
          asset_type,
          policy_name,
          policy_description,
          selectedPath,
        },
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_AI_REMEDITIONS,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const createPullRequestRemediations = (
  classificationsId,
  selectedPath,
  filesInfo,
  repoData,
  name
) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/create-pr`,
        "POST",
        {
          filesInfo,
          selectedPath,
          repoData,
          name,
        },
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();

        dispatch({
          type: CREATE_PR_REMEDITIONS,
          payload: data,
        });
      }

      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const getAiRemediationCloud = (classificationsId, rowsPerPage, skip) => {
  let a = "";
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/cloud?size=${encodeURIComponent(
          rowsPerPage
        )}&skip=${encodeURIComponent(a)}`,
        "GET",
        null,
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_CLOUD_REMEDITIONS,
        payload: { ...data },
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const getAiRemediationCloudAssets = (
  classificationsId,
  integrationId,
  size,
  skip
) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/cloud/${integrationId}/assets?size=${encodeURIComponent(
          size
        )}&skip=${encodeURIComponent(skip)}`,
        "GET",
        null,
        true,
        null,
        false,
        null,
        abortWorkspacesController.signal
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_CLOUD_ASSETS_REMEDITIONS,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const getAiRemediateCloud = (
  classificationsId,
  integrationId,
  asset_type,
  policy_name,
  policy_description,
  asset_id
) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/insights/${classificationsId}/remediations/cloud/${integrationId}/assets/remediate`,
        "POST",
        {
          asset_type,
          policy_name,
          policy_description,
          asset_id,
        },
        true,
        null,
        false,
        null
      );

      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      dispatch({
        type: GET_AI_REMEDITIONS_CLOUD,
        payload: data,
      });
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        return { abort: true };
      }
      return {};
    }
  });
};

const clearAiRemediationData = () =>
  action(async (dispatch) => dispatch({ type: CLEAR_DATA }));

export {
  getAiRemediationSummary,
  getAiRemediationCode,
  getAiRemediationCodeAssets,
  getAiRemediate,
  createPullRequestRemediations,
  getAiRemediationCloud,
  getAiRemediationCloudAssets,
  getAiRemediateCloud,
  clearAiRemediationData,
};
