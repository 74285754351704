import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { clouds } from "../../utils/icons";
import  compact from 'lodash/compact';
import  isEmpty from 'lodash/isEmpty';
import  map from 'lodash/map';
import  sortBy  from 'lodash/sortBy';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { deleteIntegration, runFullScan, scanGcpAssociatedProjects, scanIntegrationPrimaryProject } from '../../redux/actions/integrationsActions';
import { Popover, Tooltip } from 'antd';
import SearchBox from '../../shared/searchBox/searchBox'
import TableWrapper from '../../shared/tableWrapper/tableWrapper';
import IntegrationDiscoveryCard from './components/IntegrationDiscoveryCard/IntegrationDiscoveryCard';
import { fetchIntegrationDiscoveryResources, fetchIntegrationDiscoveryStatus } from '../../apis/discovery.api';
import DiscoveryMenuActionButtons from './components/DiscoveryMenuActionButtons/DiscoveryMenuActionButtons';
import DiscoveryActionButtons from './components/DiscoveryActionButtons/DiscoveryActionButtons';
import ConfirmationModal from '../../shared/confirmationModal/confirmationModal';
import { dateTimeFormat, formatIacStatus, numberWithCommas } from '../../utils/formatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appToast } from '../../shared/appToast/appToast';
import { ReactComponent as ExcludedIcon } from '../../Images/general_icons/Excluded_assets_icon.svg';
import { ReactComponent as SyncedIcon } from '../../Images/general_icons/Synced_icon.svg';
import { ReactComponent as BinIcon } from '../../Images/general_icons/Bin.svg';
import { ReactComponent as StacksIcon } from '../../Images/general_icons/Stacks.svg';
import { ReactComponent as ProjectsIcon } from '../../Images/general_icons/Projects.svg';
import { ReactComponent as NoTaggingCoverage } from '../../Images/general_icons/governance/noTaggingCoverage.svg';
import './integrationDiscovery.scss'
import { PROVIDERS, RESOURCE_STATE_TYPES } from '../../consts/general';
import PercentageBar from "../../shared/percentageBar/percentageBar";
import { sendEvent } from "../../utils/amplitude";
import { integrationEvents } from '../../utils/amplitudeEvents';


const STATUSES = [
    RESOURCE_STATE_TYPES.managed,
    RESOURCE_STATE_TYPES.ghost,
    RESOURCE_STATE_TYPES.unmanaged,
    RESOURCE_STATE_TYPES.modified
];
const NA = "N/A"

const IntegrationDiscovery = () => {
    const { t } = useTranslation("integrations");

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [integration, setIntegration] = useState(location.state || {});
    const [discoveryLoading, setLoading] = useState(true)
    const [resourcesLoading, setResourcesLoading] = useState(true)
    const [assetsSyncLoading, setAssetsSyncLoading] = useState(false)
    const [stacksSyncLoading, setStacksSyncLoading] = useState(false)
    const [resourcesScanLoading, setResourcesScanLoading] = useState(false)
    const [discoveryStatus, setDiscoveryStatus] = useState({})
    const [discoveryResources, setDiscoveryResources] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [searchVal, setSearchVal] = useState("")
    const [sorting, setSorting] = useState()
    const [didMount, setDidMount] = useState(false)

    const [selectedIntegrationForDeletion, setSelectedIntegrationForDeletion] = useState();
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false)

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    const hasResources = ['gcp'].includes(integration.type) && integration.isPrimary;

    useEffect(() => {
        if (!location.state || !integration) {
            history.push('/integrations');
            return null;
        }
        (async function () {
            const [discovery, _] = await Promise.all(compact([
                fetchIntegrationDiscoveryStatus({ integrationType: integration.type, integrationId: integration.id }),
                hasResources && handleFetchDiscoveryResources()
            ]))
            setDiscoveryStatus(discovery);
            setLoading(false);
            setDidMount(true);
        })()
    }, []);

    useEffect(() => {
        if(didMount){
            handleFetchDiscoveryResources()
        }
    }, [pageNumber, searchVal, sorting]);

    const handleFetchDiscoveryResources = async () => {
        setResourcesLoading(true);
        const integrationResources = await fetchIntegrationDiscoveryResources({
            integrationId: integration.id,
            integrationType: integration.type,
            search: searchVal,
            pageNumber: pageNumber,
            pageSize:10,
            sorting
        })
        if(integrationResources){
            setDiscoveryResources(integrationResources);
            setResourcesLoading(false);
        }
    }

    const handleDeleteIntegration = async () => {
        setDeleteLoading(true);
        let provider = integration.type;
        if (integration.type === 'azurerm') provider = 'azure';

        const isDeleted = await dispatch(deleteIntegration(provider, selectedIntegrationForDeletion.id));
        if(isDeleted)
            history.replace(`/integrations/${integration.type}`);
        setDeleteLoading(false)

    }
    
    const handleOnClickDelete = (selectedIntegration) => {
        setSelectedIntegrationForDeletion(selectedIntegration);
        setConfirmDeleteOpen(true);
    }

    const handleScanByType = async (scanType, integrationId) => {
        const localLastFetched = localStorage.getItem(`Discovery-${scanType}-Scan-${integrationId}`);
        const isAlreadyScanned = alreadyScanned(localLastFetched);
        if (!isAlreadyScanned) {
            let provider = integration.type;
            let remoteType;
            if (integration.type === 'gcp') provider = 'google', remoteType = 'gcs';
            else if (integration.type === 'azurerm') provider = remoteType = 'azure';

            let scanResult;
            switch (scanType) {
                case "assets":
                    setAssetsSyncLoading(true)
                    scanResult = await dispatch(scanIntegrationPrimaryProject(integrationId, integration.accountId, provider));
                    setAssetsSyncLoading(false)
                    break;
                case "stacks":
                    setStacksSyncLoading(true)
                    scanResult = await dispatch(runFullScan(integrationId, remoteType));
                    setStacksSyncLoading(false)
                    break;

            }
            if (scanResult) {
                localStorage.setItem(`Discovery-${scanType}-Scan-${integrationId}`, moment().unix());
                appToast("success", "", "Started a new scan");
            }
        }

    }

    const handleScanResources = async () => {
        const localLastFetched = localStorage.getItem(`Discovery-resources-Scan-${integration.id}`);
        const isAlreadyScanned = alreadyScanned(localLastFetched);
        if (!isAlreadyScanned) {
            setResourcesScanLoading(true);
            let scanResult = await dispatch(scanGcpAssociatedProjects(integration.id, integration.accountId))
            if (scanResult) {
                localStorage.setItem(`Discovery-resources-Scan-${integration.id}`, moment().unix());
                appToast("success", "", "Started a new scan");
            }
            setResourcesScanLoading(false);
        }
    }

    const alreadyScanned = (value) => {
        if (!isEmpty(value)) {
            const thirtyMinutesFromFetch = moment.unix(parseInt(value)).add(30, "minutes");
            const difference = moment().diff(thirtyMinutesFromFetch);
            const isScanned = difference < 0;
            if(isScanned){
                appToast("info", "", "Last scan occurred less than 30 minutes ago, please try again later");
                return true;
            }
        }
        return false
    }

    const handleNavigateToInventory = (integration) => {
        sendEvent(integrationEvents.noTagsClicked, { page: "gcp integration" });
        history.push({
            pathname: `/inventory`,
            search: `?integration=${integration?._id}&taggingStatus=NOT_TAGGED`,
        });
    }

    const handleUpdateIntegration = (updatedValues) => {
        history.replace({ ...history.location, state: { ...location.state, ...updatedValues } });
        setIntegration({ ...integration, ...updatedValues });
    }

    const formatLastSyncDatetime = (datetime) => {
        if (!datetime) return "-"
        return moment(datetime).format(dateTimeFormat);
    }

    const formatLastScanDate = () => {
        if (isEmpty(integration.createdAt)) {
            return NA;
        }
        return moment(integration.createdAt).format(dateTimeFormat);
    };

    const renderStatusIcon = (project) => {
        let icon, tooltip;
        if (project.isDeleted) {
            tooltip = "Deleted"
            icon = <BinIcon />
        } else if (project.isExcluded) {
            tooltip = "Excluded"
            icon = <ExcludedIcon />
        } else {
            tooltip = "Synced"
            icon = <SyncedIcon />
        }
        return <Tooltip title={tooltip}>
            <span className="row center"> {icon}{tooltip}</span>
        </Tooltip>
    }

    let columns = [
        {
            headerName: "Project Name",
            field: "name",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            renderCell: ({ row: resource }) => {
                return <div className={`integrationDiscovery__subProjectsTable__body-row-name ${(resource.isDeleted || resource.isExcluded) && 'disabled'}`}   >{resource?.name}</div>
            }
        },
        {
            headerName: "Status",
            field: "status",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            renderCell: ({ row: resource }) => {
                return <div className='integrationDiscovery__subProjectsTable__body-row-status'>{renderStatusIcon(resource)}</div>
            }
        },
        {
            headerName:  "IaC Coverage",
            field: "iac",
            filterable: false,
            disableColumnMenu: true,
            width: 165,
            sortable:false,
            renderCell: ({ row }) => {
                let bucketsByStatuses = (row?.coverage?.buckets ?? []).filter(b => STATUSES.includes(b.key));
                const sortedBuckets =  sortBy(bucketsByStatuses ?? [], bucket => STATUSES.indexOf(bucket.key));
                const totalCount = sortedBuckets?.reduce((acc,bucket )=> acc += bucket?.doc_count,0);
                return <div className="integrationDiscovery__subProjectsTable__coverage">
                    <Popover title="IaC Coverage" content={(
                        <ul className="integrationDiscovery__subProjectsTable__coverage__tooltip">
                            {map(sortedBuckets, item => {
                                return <li className={item?.key} key={item.key}>
                                    <span className={item?.key}></span>
                                    <span>{formatIacStatus(item.key)}</span>
                                    <span>{((item?.doc_count / totalCount) * 100).toFixed(2)}%</span>
                                </li>
                            })}
                        </ul>
                    )}>
                        <ul className="integrationDiscovery__subProjectsTable__coverage__progress">
                            {map(sortedBuckets, item => {
                                return <li key={item.key} className={item?.key} style={{ width: `${(item?.doc_count / totalCount) * 100}%` }}></li>
                            })}
                        </ul>
                    </Popover>
                </div>
            }
        },
        {
            headerName: t('integrations:discovery.table.headers.tagging-coverage'),
            field: t('integrations:discovery.table.headers.tagging-coverage'),
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => {
                return <div className="SingleIntegrationTable__wrapper">
                <div className="SingleIntegrationTable__percentage-bar-style">
                    <PercentageBar
                        count={row?.taggingCoverage?.count}
                        total={row?.taggingCoverage?.total}
                        tooltipTitle={t('integrations:discovery.table.cell-tooltip.tagging-coverage')}
                    />
                </div>
                <Tooltip title={`${t('integrations:discovery.table.cell-tooltip.icon-untagged')} ${row?.taggingCoverage?.total-row?.taggingCoverage?.count || 0}`}>
                    <div className="SingleIntegrationTable__percentage-no-tagging-style" onClick={()=>handleNavigateToInventory(row)}>
                        <NoTaggingCoverage width="18px" height="18px"/>
                    </div>
                </Tooltip>
            </div>
            }
        },
        {
            headerName: "Creation Date",
            field: "createdAt",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            sortComparator: (a, b) => moment(a).isBefore(moment(b)) ? -1 : 1,
            renderCell: ({ row: resource }) => {
                return <div className='integrationDiscovery__subProjectsTable__body-row-date'>{formatLastSyncDatetime(resource.createdAt)}</div>
            }
        }, {
            headerName: "Discovered Assets",
            field: "totalAssets",
            flex: 1,
            filterable: false,
            sortable:false,
            disableColumnMenu: true,
            align: "center",
            sortComparator: (a, b) => b - a,
            renderCell: ({ row: resource }) => {
                return <div className={`integrationDiscovery__subProjectsTable__body-row-${resource.totalAssets? 'badge': ''}`}>{resource.totalAssets ?  numberWithCommas(resource.totalAssets) : '-'}</div>
            }
        }, {
            headerName: "Last Assets Scan",
            field: "lastScanTime",
            flex: 1,
            filterable: false,
            sortable:false,
            disableColumnMenu: true,
            sortComparator: (a, b) => moment(a).isBefore(moment(b)) ? -1 : 1,
            renderCell: ({ row: resource }) => {
                return <div className='integrationDiscovery__subProjectsTable__body-row-date'>{formatLastSyncDatetime(resource?.assetsLastSync)}</div>
            }
        }, {
            headerName: "Discovered Stacks",
            field: "stacks",
            flex: 1,
            filterable: false,
            sortable:false,
            disableColumnMenu: true,
            sortComparator: (a, b) => b?.terraform?.doc_count - a?.terraform?.doc_count,
            align: "center",
            renderCell: ({ row: resource }) => {
                return <div className={`integrationDiscovery__subProjectsTable__body-row-${resource.totalAssets? 'badge': ''}`}>{resource.stacks ? numberWithCommas(resource.stacks?.doc_count): '-'}</div>
            }
        }, {
            headerName: "Last Stacks Scan",
            field: "lastStacksScan",
            flex: 1,
            filterable: false,
            sortable:false,
            disableColumnMenu: true,
            sortComparator: (a, b) => moment(a).isBefore(moment(b)) ? -1 : 1,
            renderCell: ({ row: resource }) => {
                return <div className='integrationDiscovery__subProjectsTable__body-row-date'>{formatLastSyncDatetime(resource.stacks?.lastSync?.value)}</div>
            }
        },
        {
            headerName: "",
            field: "",
            width: 50,
            filterable: false,
            sortable: false,
            disableColumnMenu: true,
            align: 'right',
            renderCell: ({ id, row, api }) => {
                const handleScan = async (scanType, integrationId) => {
                    api.updateRows([{ _id:id, loading: true }]);
                    await handleScanByType(scanType, integrationId);
                    api.updateRows([{ _id:id, loading: false }]);
                }
                return <DiscoveryMenuActionButtons
                    rowData={row}
                    loading={row.loading}
                    handleScanAssetsClick={(integrationId) => handleScan("assets", integrationId)}
                    handleScanStacksClick={(integrationId) => handleScan("stacks", integrationId)}
                    handleDeleteClick={() => handleOnClickDelete(row) }
                />
            }
        },
    ]

    const handleDeleteDescription = () => {
        if (integration.type === 'gcp' && selectedIntegrationForDeletion?.isPrimary) {
            return t("gcpProviderIntegration.deleteDescriptionDiscovery");
        }
        return t("general.defaultDeleteDescription");
    };


    const tooltipsContent = {
        gcp: "Terraform state files discovered in Google Cloud Storage",
        azurerm: "Terraform state files discovered in Azure Storage Accounts",
        aws:  "Terraform state files discovered in S3 Buckets and CloudFormation stacks"
    }
    
    const resourcesCardDisplayData = {
        gcp: {
            title: "Projects",
            icon: <ProjectsIcon />,
            tooltipContent: "Total accounts discovered in this integration and appear in the table below"
        }
    }

    return (
        <>
            <div className="integrationDiscovery col">
                <div className="row between">
                    <div className="integrationDiscovery__account row">
                        <img
                            alt="cloud"
                            src={clouds(integration.type, themeDark)}
                        />
                        <div className="integrationDiscovery__account-title col">
                            <span>{integration?.name}</span>
                            <div className="integrationDiscovery__account-title-date row">
                                <span>
                                    Creation Date:
                                </span>
                                <span>
                                    {integration?.createdAt ? formatLastScanDate() : "-"}
                                </span>
                            </div>
                        </div>
                    </div>
                    <DiscoveryActionButtons 
                        integration={integration} 
                        onUpdateIntegration={handleUpdateIntegration} 
                        onClickDelete={handleOnClickDelete} 
                        loading={discoveryLoading} />
                </div>
                <div className="integrationDiscovery__cards">
                    <IntegrationDiscoveryCard
                        title={"Assets"}
                        icon={<FontAwesomeIcon icon="layer-group" />}
                        tooltipContent={"Total assets discovered in this integration including IaC-Ignored"}
                        displayCount={numberWithCommas(discoveryStatus?.totalAssets)}
                        lastScanDate={!hasResources && formatLastSyncDatetime(discoveryStatus?.totalAssetsLastSync)}
                        onScanHandler={!hasResources ? () => handleScanByType("assets", integration.id) : null}
                        isLoading={discoveryLoading}
                        scanLoading={assetsSyncLoading}
                    />
                    <IntegrationDiscoveryCard
                        title={"Stacks"}
                        icon={<StacksIcon />}
                        tooltipContent={tooltipsContent[integration.type]}
                        displayCount={discoveryStatus?.totalStacks}
                        lastScanDate={!hasResources && formatLastSyncDatetime(discoveryStatus?.totalStacksLastSync)}
                        onScanHandler={!hasResources ? () => handleScanByType("stacks", integration.id) : null}
                        scanLoading={stacksSyncLoading}
                        isLoading={discoveryLoading}

                    />
                    {hasResources && (
                        <IntegrationDiscoveryCard
                            title={resourcesCardDisplayData[integration.type].title}
                            icon={resourcesCardDisplayData[integration.type].icon}
                            tooltipContent={resourcesCardDisplayData[integration.type].tooltipContent}
                            displayCount={discoveryStatus?.totalResourcesCount ?? 0}
                            lastScanDate={formatLastSyncDatetime(discoveryStatus?.resourcesLastScanTime)}
                            onScanHandler={handleScanResources}
                            isLoading={discoveryLoading}
                            scanLoading={resourcesScanLoading}
                            showScanNowButton={integration?.type === PROVIDERS.gcp}
                        />
                    )}
                </div>
                {hasResources &&
                    <SearchBox className="integrationDiscovery__searchBox" darkMode placeholder="Search Project"
                        value={(val) => {
                            setPageNumber(1);
                            setSearchVal(val);
                        }}
                        resetSearch={(val) => setSearchVal(val)}
                    />
                }
                {hasResources && <TableWrapper
                    rowKey="_id"
                    columns={columns}
                    disableSelectionOnClick
                    // disablePagination
                    handleSortChange={sorting => setSorting(sorting)}
                    hideRowCount
                    tableData={discoveryResources?.resources ?? []}
                    loading={resourcesLoading}
                    className="integrationDiscovery__projectsTable"
                    headerHeight={45}
                    rowHeight={44}
                    serverSide
                    height={600}
                    pageSize={10}
                    rowCount={discoveryResources?.totalCount}
                    handlePageChange={page => setPageNumber(page)}
                    autoHeight={(discoveryResources?.resources?.length ?? 0) <= 6}
                />}
            </div>

            <ConfirmationModal
                visible={confirmDeleteOpen}
                handleClose={() => setConfirmDeleteOpen(false)}
                title={`Delete ${selectedIntegrationForDeletion?.name ?? selectedIntegrationForDeletion?.projectId} integration?`}
                description={handleDeleteDescription()}
                loadingConfirm={deleteLoading}
                onConfirm={handleDeleteIntegration}
            />
        </>
    );
    
    
}



export default IntegrationDiscovery;
