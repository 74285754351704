import { Button } from "antd";
import AppModal from "../../../shared/appModal/appModal";
import Diagram from "./diagram";

import "./diagramModal.scss";

const DiagramModal = ({
  visible,
  handleClose,
  title,
  stacksData,
}) => {
  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      hideFooter
      bodyClassName="DiagramModal"
      width="70vw"
      centered
    >
      <span className="modal-title">{title}</span>
      <Diagram
        stacksData={stacksData}
      />
      <div className="DiagramModal__btns row">
        <Button
          className="app-form-button close"
          type="Default"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </AppModal>
  );
};

export default DiagramModal;
