import React from "react";
import _ from 'lodash';

// components
import Loading from "../../../shared/loading/loading";
import AppEmpty from '../../../shared/appEmpty/appEmpty';


import CodeSyntaxEditor from "../smartCodifyTab/codeSyntaxEditor";
import { useDispatch } from "react-redux";
import { changeCodifyDrawerProperty } from "../../../redux/actions/iacImportActions";
import { codifyEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";
import CreateModuleTab from "../smartCodifyTab/createModuleTab/createModuleTab";
import { CODIFY_TYPES } from "../../../consts/general";
import { getUniqueModuleFileName } from "../codifyDrawer/codifyHelpers";
import "./terraformCodification.scss";

const TerraformCodification = ({ isFetchingIaC, iacData = [], language="hcl"}) => {
  const dispatch = useDispatch();
  const isSingleFile = iacData?.length === 1;
  if (isFetchingIaC) {
    return (
      <div className="full-page center">
        <Loading />
      </div>
    );
  }

  if (!isFetchingIaC && _.isEmpty(iacData)) {
    return (
      <div className="full-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  }

  const onAddFile = async () => {
      sendEvent(codifyEvents.clickedCodificationAddNewFile);
      const fileName = getUniqueModuleFileName(iacData?.map((item) => item.filePath));
      const extensionFile = "yaml";
      const newFileName = `${fileName}.${extensionFile}`;
      const newData = [...iacData];
      newData.push({ filePath: newFileName, content: "" });
      const payload = { editMode: true, moduleFileSelected: newFileName, k8sIacData: newData };
      await dispatch(changeCodifyDrawerProperty("addFile", payload, true));

  }

  const onSaveK8sNewCode = async(newCode, selectedPath) => {
    if (isSingleFile) {
      await dispatch(changeCodifyDrawerProperty("k8sIacData", [{ filePath: iacData[0].filePath, content: newCode }]));
    } else {
      const updatedData = iacData.map((data = {}) => data.filePath === selectedPath ? { ...data, content: newCode } : data);
      const payload = { k8sIacData: updatedData, moduleFileSelected: selectedPath };
      await dispatch(changeCodifyDrawerProperty("saveFile", payload, true));
    }
  }
  const onEditFileName = async(newName, oldName) => {
    const updatedData = iacData.map((data = {}) => data.filePath === oldName ? { ...data, filePath: newName } : data);
    const payload = { k8sIacData: updatedData, moduleFileSelected: newName };
    await dispatch(changeCodifyDrawerProperty("editFileName", payload, true));
  };

  const onDeleteFile = async(fileName) => {
    const updatedData = iacData.filter((data = {}) => data.filePath !== fileName);
    const payload = { k8sIacData: updatedData, moduleFileSelected: updatedData[0]?.filePath };
    await dispatch(changeCodifyDrawerProperty("deleteFile", payload, true));
  };

  if(!_.isEmpty(iacData)) {
    return (
      <div className="TerraformCodification">
        {isSingleFile ? <CodeSyntaxEditor code={iacData[0]?.content} language={language} onSave={onSaveK8sNewCode} onClickAddFile={onAddFile}/>
        : <CreateModuleTab codifyType={CODIFY_TYPES.manifest} iacData={iacData} isMultiCodify handleOverweightData={() => null} 
        onSaveCode={(newCode, selectedFilePath) => onSaveK8sNewCode(newCode, selectedFilePath)} onAddFile={() => onAddFile()}
        onEditFileName={(newName, oldName) => onEditFileName(newName, oldName)} onDeleteFile={(fileName) => onDeleteFile(fileName)} />}
        </div>
    );
  }
};

export default TerraformCodification;
