import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import compact from 'lodash/compact';
import TableWrapper from '../../../shared/tableWrapper/tableWrapper';
import { Tooltip } from 'antd';
import { ReactComponent as CostPendingIcon } from "../../../Images/ci/icons/cost-pending.svg";
import { ReactComponent as CreateDeleteIcon } from "../../../Images/ci/icons/createdelete.svg";
import { ReactComponent as DeleteCreateIcon } from "../../../Images/ci/icons/deletecreate.svg";
import { ReactComponent as TagIcon } from "../../../Images/ci/guardrails/tag.svg";
import { ReactComponent as NoTagsIcon } from "../../../Images/ci/no-tags.svg";
import { ReactComponent as DecreaseCostIcon } from "../../../Images/general_icons/integration-filter-icons/dollar_decrease.svg";
import { ReactComponent as IncreaseCostIcon } from "../../../Images/general_icons/integration-filter-icons/dollar_increase.svg";
import { ReactComponent as CostNoChangeIcon } from "../../../Images/general_icons/integration-filter-icons/cost_nochange.svg";
import { sendEvent } from '../../../utils/amplitude';
import { CiEvents } from '../../../utils/amplitudeEvents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirst, formatAwsStringWithUnderscore } from '../../../utils/formatting';
import { handleRowClick as  handleOpenCloseRowById} from '../../../utils/table';
import VulnerabilityLine from "../../../shared/vulnerabilityLine/vulnerabilityLine";
import AppEmpty from '../../../shared/appEmpty/appEmpty';
import DiagnosticArr from './postApply/diagnostic/diagnosticArr';
import AssetTypeImage from '../../../shared/assetTypeImage/assetTypeImage';
import { WORKFLOWS_ACTIONS_MAP } from '../../../consts/ci-workflows';

const CONFIG_TABS = {
  plan: 'plan',
  cost: 'cost',
  scan: 'policy',
  tag: 'tag'
}
const iconsMap = {
  create: <FontAwesomeIcon className="PostPlanTable__action-icon create" icon="plus-circle" />,
  delete: <FontAwesomeIcon className="PostPlanTable__action-icon delete" icon="minus-circle" />,
  update: <FontAwesomeIcon className="PostPlanTable__action-icon update" icon="pen" />,
  read: <FontAwesomeIcon className="PostPlanTable__action-icon read" icon="check-double" />,
  "no-op": <FontAwesomeIcon className="PostPlanTable__action-icon no-op" icon="ban" />,
  import: <FontAwesomeIcon className="PostPlanTable__action-icon import" icon="download" />,
  createdelete: <CreateDeleteIcon className="PostPlanTable__action-icon-createdelete" />,
  deletecreate: <DeleteCreateIcon className="PostPlanTable__action-icon-deletecreate" />,
}

const PostPlanTable = ({ data = [], loading, parsedDataSummary = {}, pageSize = 5, 
  setPageSize, setPageNumber, handleOpenConfig, hasDiagnosticResources, workspaceData = {},
  setPlanTableSort, taskId, openRemediationByDefault
  }) => {
    const { t } = useTranslation("ci-pipeline");
    const apiRef = useRef();

    const dataLength = data?.length;
    const expandedRowIds = data.filter((element = {}) => !isEmpty(element.diagnostics) && element?.highestDiagnosticSeverity?.toLowerCase() === 'error').map((element = {}) => element.id);
    
    const getDetailPanelContent = hasDiagnosticResources ? useCallback((data = {}) => data.row?.diagnostics && <DiagnosticArr diagnostics={data.row.diagnostics} withHeaderBg={false} taskId={taskId} openRemediationByDefault={openRemediationByDefault}/> ,[openRemediationByDefault]) : null;
    const getDetailPanelHeight = useCallback(() => "auto", []);

    const onFlagClick = (event, data, triggerTab = '') => {
      event.stopPropagation();
      const horizontalTabAvailability = compact(["resource plan", data?.isCostResults ? "cost analysis" : "", data?.scanResults?.length ? "policy violations" : ""]);
      sendEvent(CiEvents.ciRunResourceClick, { from_graph: false, actionType: data?.action, assetType: data?.type, workspaceID: workspaceData?.workspaceId, workspaceRunID: workspaceData?.runId, horizontalTabAvailability });
      handleOpenConfig(data, triggerTab);
    }
    
    const handleRowClick = (params = {}) => {
      const data = params.row || {};
      const { diagnostics = [], id: rowId, highestDiagnosticSeverity = '' } = data;
      if (diagnostics.length > 0 && highestDiagnosticSeverity === 'error') {
        return handleOpenCloseRowById(apiRef, rowId);
      }
      const horizontalTabAvailability = compact(["resource plan", data?.isCostResults ? "cost analysis" : "", data?.scanResults?.length ? "policy violations" : ""]);
      sendEvent(CiEvents.ciRunResourceClick, { from_graph: false, actionType:  data?.action, assetType: data?.type, workspaceID: workspaceData?.workspaceId, workspaceRunID: workspaceData?.runId, horizontalTabAvailability });
      handleOpenConfig(data, CONFIG_TABS.plan);
    }
    const handleSortChange = (sorter = []) => {
      const { field, sort } = sorter[0] || {};
      setPlanTableSort({ [field]: sort === "asc" ? 1 : -1 });
    };
    const columns = [
        {
          headerName: t("columns.action"),
          field: "action",
          width: 80,
          filterable: false,
          sortable: true,
          disableColumnMenu: true,
          align: "center",
          renderCell: (params) => {
            apiRef.current = params.api;
            const action = params.row?.action;
            const actionComponent = iconsMap[action] || action;
            return (
                <Tooltip title={WORKFLOWS_ACTIONS_MAP[action]|| capitalizeFirst(action)}>
                  {actionComponent}
                </Tooltip>
            );
          }
        },
        {
          headerName: t("columns.providerType"),
          field: "providerName",
          flex: 2,
          sortable: true,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const providerName = params.row?.providerName;
            return (
                <Tooltip title={providerName}>
                  {providerName}
                </Tooltip>
            );
          },
        },
        {
          headerName:  t("columns.name"),
          field: "name",
          flex: 2,
          sortable: true,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const name = params.row?.name;
            return (
                <Tooltip title={name}>
                  {name}
                </Tooltip>
            );
          },
        },
        {
          headerName:  t("columns.assetType"),
          field: "type",
          flex: 2,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const type = params.row?.type;
            return (
               <div className="row g8">
                <AssetTypeImage assetType={type} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>
                <span>{formatAwsStringWithUnderscore(type)}</span>
               </div>
            );
          },
        },
        {
          headerName:  t("columns.address"),
          field: "address",
          flex: 2,
          sortable: true,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const address = params.row?.address;
            return (
                <Tooltip title={address}>
                  {address}
                </Tooltip>
            );
          },
        },
        {
          headerName:  t("columns.policyViolations"),
          field: "policyViolations",
          width: 135,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const data = params?.row || {};
            const severities = { HIGH: 0, MEDIUM: 0, LOW: 0 };
            const { scanResults = []} = data;
       
            const isScanResutls = scanResults.length > 0;
            scanResults.forEach((scanResult = {}) => { severities[scanResult.severity] += 1 });
            return <VulnerabilityLine data={severities} isCiTable onClick={(e) => isScanResutls && onFlagClick(e, data, CONFIG_TABS.scan)} tooltip={{ title: t("columns.policyViolations") }}/>
          }
        },
        {
          headerName:  t("columns.costEstimation"),
          field: "costEstimation",
          width: 100,
          sortable: false,
          filterable: false,
          align: "center",
          disableColumnMenu: true,
          renderCell: (params) => {
            const data = params?.row || {};
            const { isCostResults = false, costResults = [] } = data;
            const costResultsSummary = costResults[0];
            const beforeCost = costResultsSummary?.before?.monthlyCost || 0;
            const afterCost = costResultsSummary?.after?.monthlyCost || 0; 
            const diffCost = costResultsSummary?.diff?.monthlyCost || 0;

            const wasPrice = beforeCost == 0 ? 0 : parseFloat(beforeCost).toFixed(2);
            const nowPrice = afterCost == 0 ? 0 : parseFloat(afterCost).toFixed(2);
            const diffPrice = diffCost == 0 ? 0 : parseFloat(Math.abs(diffCost)).toFixed(2);
            const component = diffCost > 0 ? <IncreaseCostIcon />
            : diffCost < 0 ? <DecreaseCostIcon /> 
            : <CostNoChangeIcon />;
            const showDetails = !!component;
            return <div className="row g5" onClick={(e) => isCostResults && onFlagClick(e,data, CONFIG_TABS.cost)}>
                      {showDetails ? <Tooltip title={t("tooltips.costPending")}><CostPendingIcon className="CiStepsCollapse__table-cost-pending" /></Tooltip> : null}
                      <Tooltip title={showDetails ? 
                        <div className="CiStepsCollapse__table-cost-tooltip-header col g10">
                          <span className="CiStepsCollapse__table-cost-tooltip-header-title">Cost Estimation</span>
                          {diffCost === 0 ? <span className='CiStepsCollapse__table-cost-tooltip-header-content col g8'>Remains ${nowPrice}</span>
                          :<span className='CiStepsCollapse__table-cost-tooltip-header-content col g8'>
                            <span>Current - ${wasPrice}</span>
                            <span>After apply - ${nowPrice}</span>
                            <span>Diff - ${diffPrice}</span>
                          </span>}
                      </div>: ""} overlayClassName="CiStepsCollapse__table-cost-tooltip"> 
                        <div className="row g5">
                          {showDetails ? <span>${nowPrice}</span> : <span></span>}
                          {component}
                        </div>
                      </Tooltip>
                    </div>
          }, 
        },
        {
          headerName: t("columns.tagsCoverage"),
          field: "tagsCoverage",
          width: 130,
          sortable: false,
          filterable: false,
          align: "center",
          disableColumnMenu: true,
          renderCell: (params) => {
            const { tags = {}, isTaggable = false } = params?.row || {};
            const tagsLength = Object.keys(tags).length;
            const isTags = tagsLength > 0;
            const showNoTags =  !isTags && isTaggable;
            if (!isTags && !showNoTags) return null;
            return (
            <Tooltip overlayClassName="CiStepsCollapse__table-cost-tooltip" title={isTags ?
              <div className="CiStepsCollapse__table-cost-tooltip-header col g10">
              <span className="CiStepsCollapse__table-cost-tooltip-header-title">Tags Coverage</span>
              <div className='CiStepsCollapse__table-cost-tooltip-header-content col g5'>
                {Object.keys(tags).map((tagKey = "") => (
                  <span className='row g5' key={uuidv4()}>
                    <TagIcon className="img12" />
                    <span>{tagKey}: {tags[tagKey]}</span>
                  </span>
                ))}
              </div>
              </div> : ""}>
              <div className={`row g5 ${isTags ? 'pointer': ''}`} onClick={(e) => isTags && onFlagClick(e, params.row, CONFIG_TABS.tag)}>
                {isTags ? <>
                  <TagIcon className="img16" />
                  <span>{tagsLength} tags</span>
                </>
                : showNoTags ? <>
                  <NoTagsIcon />
                  <span>No tags</span>
                </> : null}
              </div>
            </Tooltip>)
          }
        },
        {
          headerName: "",
          field: "highestDiagnosticSeverity",
          width: 95,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { diagnosticsCount = 0, highestDiagnosticSeverity = '', isDrifted } = params.row || {};
            if (!diagnosticsCount && !isDrifted) return <div></div>;
            const title = `${diagnosticsCount} error message${diagnosticsCount > 1 ? 's' : ''} when tried to apply this resource`;
            const highestLower = highestDiagnosticSeverity.toLowerCase();
            return (
              <div className="row g8">
                {diagnosticsCount > 0 && 
                <Tooltip title={title}>
                  <div onClick={(e) => {
                    if (highestLower === 'error') return
                    e.stopPropagation();
                    handleOpenCloseRowById(apiRef, params?.row?.id);
                  }}>
                    <span className={`Diagnostic__severityBtn ${highestLower}`}>
                        {capitalizeFirst(highestLower)}
                    </span>
                  </div>
                </Tooltip>}
                {isDrifted && 
                <Tooltip title={`${t("tooltips.driftedResource")} ${workspaceData.iacType}`}>
                  <div className="IacStatusFlag driftFlag row g5 pointer">
                    <FontAwesomeIcon icon="code-branch" className="purple-text"/>
                    <span>{t("diagramResource.drifted")}</span>
                  </div>
                </Tooltip>}
              </div>
            );
          },
        },
    ];

    if (!loading && isEmpty(data)) {
        return (
          <div className="PostPlanGraph center">
            <AppEmpty customStyle="ciResourcesNotFound" text="The current Terraform plan does not contain any infrastructure changes" />
          </div>
        );
    }
    
    return (
      <div className="PostPlanTable">
        <TableWrapper
            autoHeight
            height="100%"
            loading={loading}
            rowKey="id"
            tableData={data || []}
            columns={columns}
            disableSelectionOnClick
            pageSize={pageSize}
            rowCount={parsedDataSummary?.notNoOpResourcesCount > dataLength ? parsedDataSummary?.notNoOpResourcesCount : dataLength}
            rowsPerPageOptions={[5, 10, 25, 50]}
            handlePageChange={setPageNumber}
            onPageSizeChange={setPageSize} 
            expandedRowIds={expandedRowIds}
            onRowClick={handleRowClick}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            handleSortChange={handleSortChange}
            serverSide
        />
      </div>)
};

export default PostPlanTable;
