import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import _, { isEmpty } from "lodash";
import { Button } from "antd";
import {
  deleteClassification,
  createClassification,
  updateClassification,
  getClassifications,
} from "../../redux/actions/classificationsActions";
import Base64 from "../../utils/base64";
import { appToast } from "../../shared/appToast/appToast";

// components
import InsightDetails from "../../components/createInsight/insightDetails/insightDetails";
import InsightRego from "../../components/createInsight/insightRego/insightRego";
import ConfirmationModal from "../../shared/confirmationModal/confirmationModal";
import { fetchPdAdminIntegrationKey } from "../../redux/actions/integrationsActions";
import { deleteNotification, insightSubscription, updateNotification } from "../../redux/actions/notificationsActions";

import "./createInsight.scss";
import { useAuth0 } from "@auth0/auth0-react";
import CreateNotification2 from "../../components/notifications/createNotification/createNotification2";

const CreateInsight = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const providers = useSelector((state) => state.globalAppReducer.clouds);
  const { user } = useAuth0();

  const [editMode, setEditMode] = useState(!isEmpty(location.state?.data?.isEditMode));
  const [readOnly, setReadOnly] = useState(false);
  const [wizardState, setWizardState] = useState({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [createdPolicy, setCreatedPolicy] = useState();
  const [insightsLoading, setInsightsLoading] = useState(false);
  const globalNotificationIntegrations = useSelector(
    (state) => state.globalAppReducer.notifications
  );

  useEffect(() => {
    if (location?.state?.data) {
      setWizardState({
        ...location?.state?.data,
        scope: location?.state?.data?.type,
        rego: Base64.decode(location?.state?.data?.rego),
        providers: location?.state?.data?.providerIds || [],
      });
      setReadOnly(location?.state?.data?.isDefault);
    }
  }, [location?.state?.data]);

  const handleClose = () => {
    return history.push("/governance");
  };

  const handleDeleteConfirmed = async () => {
    const { id = "", type = [], isEnabled = false } = wizardState || {};

    setDeleteInProgress(true);
    await dispatch(deleteClassification(id, type, isEnabled));
    setDeleteInProgress(false);
    setConfirmDeleteOpen(false);
    return history.push("/governance");
  };

  const validateSubmit = () => {
    // validate the test button
    let error = "";

    if (wizardState.notification?.subscribed && !wizardState.notification?.notificationFormValid)
      error = "Please complete notification wizard";

    if (_.isEmpty(wizardState?.name)) {
      error = "You must write a name for your insight";
    }
    if (_.isEmpty(wizardState?.scope)) {
      error = "You must select asset type";
    }
    if (_.isEmpty(wizardState?.rego)) {
      error = "You must write a code for your rule";
    }
    if (_.isEmpty(wizardState?.description)) {
      error = "You must write a description for your rule";
    }
    // check if the same data source is selected
    if (!_.isEmpty(wizardState?.scope)) {
      let providers = [];
      for (const type of wizardState?.scope) {
        providers.push(type.split("_")[0]);
      }
      if (_.uniq(providers)?.length > 1) {
        error = "Only asset types from the same data source type are allowed";
      }
    }

    // check if different provider type integrations was selected - e.g. aws + k8s
    if (!_.isEmpty(wizardState?.providers)) {
      // arrange providers to be an array
      let providerArr = [];
      if (!_.isEmpty(providers) && _.isObject(providers)) {
        for (const key in providers) {
          const arr = providers[key];
          for (const item of arr) {
            item.type = key;
            providerArr.push(item);
          }
        }
      }

      let filteredProviderArr = _.filter(providerArr || [], item => wizardState?.providers?.includes(item?.id));
      let providerTypes = _.map(filteredProviderArr || [], item => item?.type);
      let allTypesArr = _.filter(wizardState?.providers || [], item => item?.includes("_all"));
      allTypesArr = _.map(allTypesArr || [], item => item?.replace("_all", ""));
      let output = _.uniq([...allTypesArr, ...providerTypes]);

      if (!_.isEmpty(output) && output?.length > 1) {
        error = "Please select data sources from the same type";
      }
    }

    if (error) {
      throw appToast("info", "", error);
    }
  };

  const fetchInsights = async () => {
    setInsightsLoading(true);
    await dispatch(getClassifications());
    setInsightsLoading(false);
  }
  const handleUpdate = async () => {
    validateSubmit();
    setLoadingSubmit(true);

    const payload = {
      name: wizardState?.name,
      description: wizardState?.description,
      code: Base64.encode(wizardState?.rego),
      type: wizardState?.scope,
      providerIds: wizardState?.providers,
      labels: wizardState?.category,
      severity: wizardState?.severity,
      category: wizardState?.category,
    };

    const req = await dispatch(updateClassification(wizardState?.id, payload));
    if (req?.ok) {
      if(!isEmpty(wizardState.notification))
        await handleSubscription();
      return history.push("/governance");
    }
    setLoadingSubmit(false);
  };

  const handleCreate = async () => {
    validateSubmit();
    setLoadingSubmit(true);
    const payload = {
      name: wizardState?.name,
      description: wizardState?.description,
      code: Base64.encode(wizardState?.rego),
      type: wizardState?.scope,
      providerIds: wizardState?.providers,
      labels: wizardState?.labels || wizardState?.category,
      severity: wizardState?.severity,
      category: wizardState?.labels || wizardState?.category,
    };
    const req = await dispatch(createClassification(payload));
    if (req?.ok) {
      await fetchInsights();
      appToast("success", "Custom Policy", "A new policy has been added successfully Please wait for the initial scan to be completed.");
      setCreatedPolicy(req?.result)
      setNotificationModalOpen(true);
    }
    setLoadingSubmit(false);
  };

  const getPdIntegrationKeys = async () => {
    const { pagerduty } = globalNotificationIntegrations;
    const { notificationIntegration } = wizardState.notification;

    const pdAdminOnly = pagerduty.find((item) => {
      return (
        notificationIntegration == item?.id && (!_.has(item, 'publicKey') || isEmpty(item?.publicKey))
      );
    });

    let adminIntegrationKeys = []
    if (!_.isEmpty(pdAdminOnly)) {
      const pd_service = wizardState.notification.pd_service;
      adminIntegrationKeys = await dispatch(fetchPdAdminIntegrationKey(pdAdminOnly?.id, pd_service))
    }

    const pdPublicIntegrationKeys = pagerduty.filter((item) => {
      return (
        notificationIntegration == item?.id && _.has(item, 'publicKey') && !isEmpty(item?.publicKey)
      );
    }).map((item) => item?.publicKey);

    let outputAdmin = _.isArray(adminIntegrationKeys) ? adminIntegrationKeys : [];

    return [...outputAdmin, ...pdPublicIntegrationKeys];
  }


  const mapSubscriptionPayload = async (insightId = wizardState.id) => {
    const { notificationIntegration, channels, pd_service } = wizardState.notification;

    const integrationKey = pd_service ? await getPdIntegrationKeys() : []
    const payload = {
      eventType: 'InsightDetected',
      notificationIntegrations: [notificationIntegration],
      isEnabled: true,
      scope: {
        providers: [],
        environments: [],
        insights: [insightId],
      },
      name: user.name,
      description: "",
      channels: channels ?? [],
      pd_service: pd_service,
      integrationKey,
    }

    return payload;
  }


  const handleSubscribeToInsight = async (insightId) => {

    const payload = await mapSubscriptionPayload(insightId);
    const res = await dispatch(insightSubscription(payload));
    if (res.ok) appToast("success", "Notification", "Subscribed to insight successfully")
    else appToast("warning", "Error", "something went wrong while subscribing to insight")

    return res.ok;
  }

  const handleUpdateNotificationForInsight = async () => {

    const { notificationId } = wizardState.notification;
    const payload = await mapSubscriptionPayload();

    const res = await dispatch(updateNotification(notificationId, payload));

    if (res.ok) appToast("success", "Notification", "Notification has been updated")
    else appToast("error", "Notification", "Failed to update notification");

    return res.ok;

  }

  const handleUnsubscribeNotificationForInsight = async () => {
    const { notificationId } = wizardState.notification;

    const res = await dispatch(deleteNotification(notificationId));

    if (res.ok) appToast("success", "Notification", "Unsubscribed successfully")
    else appToast("error", "Notification", "Failed to unsubscribed");

    return res.ok;
  }


  const handleSubscription = async (insightId = wizardState.id) => {
    const { notification } = wizardState;

    if (notification?.subscribed && !notification?.notificationFormValid)
      return appToast("info", "Notification", "Please complete notification wizard");

    setLoadingSubmit(true);

    if (notification?.notificationId) {

      if (notification.isUpdated) {
        if (notification.subscribed)
          await handleUpdateNotificationForInsight();
        else
          await handleUnsubscribeNotificationForInsight()
      }

    } else if (notification?.subscribed) {
      await handleSubscribeToInsight(insightId)
    }

    setLoadingSubmit(false);
    history.push("/governance");
  }
  
  const handleNavigateToGovernance = () => {
    setNotificationModalOpen(false);
    history.push("/governance");
  }

  const isSubmitDisabledForReadOnly = () => {
    const { notification } = wizardState;
    if (!readOnly || location?.state?.data?.severity !== wizardState?.severity)
      return false;
    if (notification?.isUpdated)
      return false;
    return !notification?.notificationFormValid;
  }

  return (
    <div className="CreateInsight col g20">
      <InsightDetails
        updateWizard={(obj) => {
          setWizardState({ ...wizardState, ...obj });
        }}
        wizardState={wizardState}
        readOnly={readOnly}
      />
      <InsightRego
        updateWizard={(obj) => {
          setWizardState({ ...wizardState, ...obj });
        }}
        wizardState={wizardState}
        readOnly={readOnly}
      />
      <div className="CreateInsight__footer row between">
        <Button
          type="primary"
          danger
          onClick={() => {
            setConfirmDeleteOpen(true);
          }}
          style={{ visibility: editMode && !readOnly ? "visible" : "hidden" }}
        >
          Delete
        </Button>

        <div className="row g20">
          <button className="btn-cancel" onClick={handleClose}>
            Cancel
          </button>
          <Button
            type="primary"
            disabled={isSubmitDisabledForReadOnly()}
            loading={loadingSubmit}
            onClick={() => {
              if (!readOnly || location?.state?.data?.severity !== wizardState?.severity)
                editMode ? handleUpdate() : handleCreate();
              else
                handleSubscription()
            }}
          >
            {editMode ? "Update" : "Create"}
          </Button>
        </div>
      </div>

      <ConfirmationModal
        visible={confirmDeleteOpen}
        handleClose={() => {
          setConfirmDeleteOpen(false);
        }}
        title={`Delete Policy "${wizardState?.name}"`}
        description="Are you sure? This cannot be undone"
        loadingConfirm={deleteInProgress}
        onConfirm={handleDeleteConfirmed}
      />
      <CreateNotification2
          visible={notificationModalOpen}
          skippable
          handleClose={() => setNotificationModalOpen(false)}
          insights={createdPolicy?._id}
          insightsLoading={insightsLoading}
          onCancelCallback={handleNavigateToGovernance}
          onSubmitCallback={handleNavigateToGovernance}
      />
    </div>
  );
};

export default CreateInsight;
