import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import isEmpty from "lodash/isEmpty";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { getStackDiagram, setFilters } from "../../../redux/actions/iacStacksActions";

import LongLoader from "../../../shared/longLoader/longLoader";
import Loading from "../../../shared/loading/loading";
import TypingDots from "../../../shared/typingDots/typingDots";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import "./diagramModal.scss";
import { remoteTypes } from "../../../utils/icons";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import { IAC_TYPES } from "../../../consts/general";

const LARGE_STATE_FILE_ERROR = "State file too large to generate diagram";

const Diagram = ({stacksData}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const [isFetchingStack, setIsFetchingStack] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [error, setError] = useState("");

    const isArgoRemoteType = stacksData?.alternativeStateRemoteLocation?.argocdLocation?.appName;
    const icon = isArgoRemoteType ? "argocd" : stacksData?.remoteType;

    useEffect(() => {
        setImageUrl("");
        getDiagramPhoto();
    }, [stacksData]);

    const getDiagramPhoto = async () => {
        setIsFetchingStack(true);
        if (!isEmpty(stacksData?.stateLocalPath)) {
            const image = await dispatch(getStackDiagram(stacksData?.stateLocalPath, themeDark));
            if (image?.ok) {
                setError(false);
                setImageUrl(image?.imageUrl);
            } else {
                const error = image?.message || "";
                handleInvalidImage(error);
            }
            setIsFetchingStack(false);
        } else {
            handleInvalidImage();
        }
    };

    const handleInvalidImage = (error = "general") => {
        setError(error);
    };

    const stackClick = (stackId, stateType) => {
        let view = stateType;
        const filters = {
          ...emptyIacScreenFilters,
          stackId: [stackId],
          stateType: [stateType],
        };
        if (stateType === IAC_TYPES.opentofu) {
          filters.stateType = [stateType, IAC_TYPES.terraform];
          view = IAC_TYPES.terraform;
        }
        dispatch(setFilters(filters));
        history.push({
          pathname: `/iac`,
          search: `?view=${view}`,
        });
    };
    const handleArgoRemoteClick = (e, stack = {}) => {
        e.stopPropagation();
        window.open(stacksData.metadata?.k8sMetadata?.home, "_blank");
    };

    return (
        <div className="center DiagramModal__content">
            {isFetchingStack ? (
                  <div className="center">
                  <LongLoader customLoader={<Loading />} duration={4000}
                    customClassName="col g10 center font-18 bold" loading={isFetchingStack}
                    msg1={<span className="row g8">Generating diagram<TypingDots isInText/></span>}
                              msg2={<span>
                                  <span className="row g8 center">Processing your request <TypingDots isInText/></span>
                                  <span className="row g9 font-14">This may take a minute. Feel free to come back in a bit.</span>
                  </span>}/>
                  </div>
            ) : (
                !isEmpty(imageUrl) ? (
                    <TransformWrapper
                        initialScale={0.5}
                        centerZoomedOut={true}
                        initialPositionY={0}
                        centerOnInit
                        minScale={0.1}
                        maxScale={3}
                        smooth
                        wheel={{
                            step: 0.01,
                        }}
                    >
                        {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                            <React.Fragment>
                                {imageUrl && (
                                    <>
                                        <div className="row DiagramModal__content-tools">
                                            <IconBtn
                                                type="zoom-in"
                                                onClick={() => zoomIn()}
                                                colorClass="purple"
                                            />
                                            <IconBtn
                                                type="zoom-out"
                                                onClick={() => zoomOut()}
                                                colorClass="purple"
                                            />
                                            <IconBtn
                                                type="center"
                                                onClick={() => centerView()}
                                                colorClass="purple"
                                            />
                                            <IconBtn
                                                type="reset"
                                                onClick={() => resetTransform()}
                                                colorClass="purple"
                                            />
                                        </div>
                                        <div className="IaCTab__list-item row DiagramModal__content-stackName">
                                            <div className="IaCTab__list-item-type row">
                                            <img
                                                src={remoteTypes(icon, themeDark)}
                                                alt={stacksData?.remoteType}
                                            />
                                            <span className={`${isArgoRemoteType ? 'purple-text underline pointer' : ''}`} onClick={(e) => isArgoRemoteType && handleArgoRemoteClick(e, stacksData)}>{isArgoRemoteType ? stacksData.alternativeStateRemoteLocation.argocdLocation.appName : stacksData?.stateMainRemoteLocationString}</span>
                                            </div>
                                            <div
                                            className="underline"
                                            style={{ cursor: "pointer" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                stackClick(stacksData?.stackId, stacksData?.stateType);
                                            }}
                                            >
                                            {stacksData?.stateSubRemoteLocationString}
                                            </div>
                                        </div>
                                    </>
                                )}
                                <TransformComponent>
                                    <img
                                        src={imageUrl}
                                        alt="map-object"
                                    />
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                )
                : error === LARGE_STATE_FILE_ERROR ? <AppEmpty text="Stack content too large to generate diagram" customStyle="code" />
                : <AppEmpty text="Failed to generate diagram, try again in a few minutes" customStyle="code" />
            )}
        </div>
    );
};

export default Diagram;
