import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  fetchGeneratedIaC,
  setPullRequestParameters,
  fetchGeneratedCodify,
  fetchGeneratedCloudMigration,
  changeCodifyDrawerProperty,
  clearCodifyDrawerState,
} from "../../../redux/actions/iacImportActions";
import { setTerraformCmds, setTerraformCmdsRedacted } from "../../../redux/actions/inventoryv3Actions";
import { CODIFY_TYPES, IAC_TYPES, RESOURCE_STATE_TYPES } from "../../../consts/general";

import { codifyIcons } from "../../../utils/icons";

import {
  ACTIVE_CODIFICATION_TYPES,
  handleCallbackByActiveTab,
  onCodifyDrawerVisible,
  MIGRATION_PROVIDERS_TYPES,
  CREATE_MODULE_CODIFICATION_MODES,
  sendGeneratedEvent,
  getIacMappedData,
  getIacImportPayload,
  getTerraformContentByType,
  LANGS_SUFFIX,
} from "./codifyHelpers";
import CodifyTabs from "./codifyTabs/codifyTabs";
import CodifyFooter from "./codifyFooter";
import { useTranslation } from "react-i18next";
import UnsavedCodifyModal from "./unsavedCodifyModal/unsavedCodifyModal";
import "./codifyDrawer.scss";

const CodifyDrawer = ({
  visible,
  closeDrawer,
  handleOpenPullRequest,
  selectedResources = [],
  handleOpenTerraformImport,
  codifySessionId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('inventory', { keyPrefix: "codifyDrawer"});
  const iacImportState = useSelector((state) => state.iacImportReducer) || {};
  
  const {
    terraformIacData, tfCdkData, k8sIacData, otherIacData,
    activeIacTab: activeTab, activeLanguage, activeCodificationType,
    isImportBlocksClicked, isProviderBlockClicked, unsavedCodeModal,
    editMode, tempCode, hasSavedCode
  } = iacImportState;

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const [isFetchingIaC, setIsFetchingIaC] = useState(false);
  const [isLoadingOtherIac, setIsLoadingOtherIac] = useState(false);
  const [didMount, setDidMount] = useState(false);

  const firstResource = selectedResources[0] || {};

  const revisionIdFromCofifyButton = firstResource.revisionId || undefined;
  
  const mapData = getIacMappedData(terraformIacData, tfCdkData, otherIacData, k8sIacData, activeCodificationType);
  
  const isSelectedResourceDeleted = firstResource.state === RESOURCE_STATE_TYPES.deleted;
  const isUnsavedCodeIdentified = editMode && tempCode.new !== tempCode.old;

  useEffect(() => {
      onCodifyDrawerVisible({selectedResources, setActiveCodificationType, setActiveTab, fetchIacImport, fetchCloudMigration, fetchNewCodify, activeTab, activeLanguage, activeCodificationType});
      sendIacGeneratedEvent();
      setDidMount(true);
  }, []);

  useEffect(() => {
    setIsImportBlocksClicked(false);
    setIsProviderBlockClicked(false);
    setIsOverweightResponse(false);
    dispatch(setPullRequestParameters(null));
    if (didMount) {
      handleOnChangeSmartType(activeCodificationType, activeTab, activeLanguage);
      sendGeneratedEvent({ activeCodificationType, visible, codifySessionId, activeTab, activeLanguage, isImportBlocksClicked: false, isProviderBlockClicked: false, selectedResources });
    }
  }, [activeCodificationType]);
  
  useEffect(() => {
    if (didMount) {
      sendIacGeneratedEvent()
      handleCallbackByActiveTab(activeTab, fetchIacImport, activeLanguage, fetchNewCodify, selectedResources, isUnsavedCodeIdentified);
    }
  }, [activeTab]);

  useEffect(() => didMount && sendIacGeneratedEvent(), [activeLanguage]);

  const onChangeCodifyDrawerStateProperty = async(key, value) => await dispatch(changeCodifyDrawerProperty(key, value));
  const setTerraformIacData = (value, newCodificationMode) => {
    const key = newCodificationMode || activeCodificationType || ACTIVE_CODIFICATION_TYPES.naive;
    onChangeCodifyDrawerStateProperty("terraformIacData", { ...terraformIacData, [key]: value });
  }
  const setOtherIacData = (value) => onChangeCodifyDrawerStateProperty("otherIacData", value);
  const setActiveTab = async (newTab, newActiveCodification) => {
    const payload = { activeIacTab: newTab}
    if (!isUnsavedCodeIdentified) {
      payload.activeCodificationType = newActiveCodification || ACTIVE_CODIFICATION_TYPES.naive;
    }
    await dispatch(changeCodifyDrawerProperty("activeIacTab", payload, true));
  }
  const setActiveLanguage = (value) => onChangeCodifyDrawerStateProperty("activeLanguage", value);
  const setActiveCodificationType = (value) => onChangeCodifyDrawerStateProperty("activeCodificationType", value);
  const setIsImportBlocksClicked = (value) => onChangeCodifyDrawerStateProperty("isImportBlocksClicked", value);
  const setIsProviderBlockClicked = (value) => onChangeCodifyDrawerStateProperty("isProviderBlockClicked", value);
  const setImportBlocks = (value) => onChangeCodifyDrawerStateProperty("importBlocks", value);
  const setProviderBlock = (value) => onChangeCodifyDrawerStateProperty("providerBlock", value);
  const setIsOverweightResponse = (value) => onChangeCodifyDrawerStateProperty("isOverweightResponse", value);

  const sendIacGeneratedEvent = () => sendGeneratedEvent({ activeCodificationType, visible, codifySessionId, activeTab, activeLanguage, isImportBlocksClicked, isProviderBlockClicked, selectedResources })

  const handleCloseDrawer = async() => {
    if (isUnsavedCodeIdentified) return onChangeCodifyDrawerStateProperty("tryingToCloseModal", true);
    if (editMode) return onChangeCodifyDrawerStateProperty("editMode", false);
    closeDrawer();
    await dispatch(clearCodifyDrawerState());
  };

  const onChangeTab = (newTab) => setActiveTab(newTab);
  
  const fetchNewCodify = async (type, language, isLanguageSwitcher = false) => {
    // check if data already exists, if so, don't fetch again
    if (!isEmpty(otherIacData[type]) && !isLanguageSwitcher){
      if (type === CODIFY_TYPES.cloudformation || type === CODIFY_TYPES.cdk || type === CODIFY_TYPES.cdkl2) {
        const importScript = otherIacData[type][0]?.importScript;
        dispatch(setTerraformCmds([importScript], true));
      } 
      return setIsLoadingOtherIac(false);
    }
    setActiveCodificationType(ACTIVE_CODIFICATION_TYPES.naive);
    setIsLoadingOtherIac(true);

    const payload = {
      codeProvider: type,
      language,
      accountId: firstResource.accountId,
      integrationId: firstResource.integrationId,
      isDeleted: isSelectedResourceDeleted,
      FRNs: map(selectedResources, (resource) => resource?.frn),
    }
    const data = await dispatch(fetchGeneratedCodify(payload));
    if (data?.abort) return;
    setOtherIacData({...otherIacData, [type]: data});

    if ((type === CODIFY_TYPES.cloudformation || type === CODIFY_TYPES.cdk) && !isEmpty(data)) {
      const { importScript = '' } = data[0] || {};
      dispatch(setTerraformCmds([importScript], true));
    }
    setIsLoadingOtherIac(false);
  }

  const fetchCloudMigration = async (type) => {
    setIsFetchingIaC(true);
    const payload = {
      codeProvider: CODIFY_TYPES.terraform,
      accountId: firstResource.accountId,
      integrationId: firstResource.integrationId,
      isDeleted: isSelectedResourceDeleted,
      originCloud: firstResource.provider,
      desiredCloud: type,
      FRNs: map(selectedResources, (resource) => resource?.frn),
    }
    const data = await dispatch(fetchGeneratedCloudMigration(payload));
    if (data?.abort) return;
    setTerraformIacData(data)
    setIsFetchingIaC(false);
  }

  const fetchIacImport = async (codifyType, lang, newCodificationMode) => {
    const shouldFetch = codifyType === CODIFY_TYPES.terraform ? isEmpty(terraformIacData[newCodificationMode]) : 
    (codifyType === CODIFY_TYPES.tfcdk ? (isEmpty(tfCdkData[newCodificationMode]) || activeCodificationType === ACTIVE_CODIFICATION_TYPES.naive)  : codifyType === IAC_TYPES.k8s ? isEmpty(k8sIacData) : false);
    if (hasSavedCode || !shouldFetch) return setIsFetchingIaC(false);
    setIsFetchingIaC(true);
    setIsImportBlocksClicked(false);
    setIsProviderBlockClicked(false);
    
    const isK8sCodifyTypeButNotProvider = codifyType === IAC_TYPES.k8s && firstResource.provider !== IAC_TYPES.k8s;
    const selectedResourcesWithoutFirst = selectedResources.slice(1) || [];
    const resources = isK8sCodifyTypeButNotProvider ? selectedResources 
    : selectedResourcesWithoutFirst.map((resource = {}) => ({ terraformAssetType: resource.assetType, providerId: resource.integrationId, assetId: resource.assetId, isDeleted: resource.state === RESOURCE_STATE_TYPES.deleted, frn: resource.frn}))

    const payload = getIacImportPayload(firstResource, codifyType, lang, resources, newCodificationMode);

    const res = await dispatch(fetchGeneratedIaC(payload));
    if (isEmpty(res)) return setIsFetchingIaC(false);
    if (res?.abort) return;
    const { terraformProviderBlock: providerBlocks = "", terraformImportBlocks: terraformBlocks, terraformImportCommand: terraformCmds, isK8sRedacted: k8sRedacted = false } = res;
    const isK8sProvider = firstResource.provider === IAC_TYPES.k8s;
    const isTerraformCodifyTypes = codifyType === CODIFY_TYPES.terraform || codifyType === CODIFY_TYPES.tfcdk;
    //set data by provider
    const codiMode = newCodificationMode || activeCodificationType

    switch (codifyType) {
      case IAC_TYPES.k8s: 
        let k8sData = res.hcl
        if (Array.isArray(res.hcl)) {
          k8sData = res.hcl?.join("\n---\n");
        }
        onChangeCodifyDrawerStateProperty("k8sIacData", [{ content: k8sData, filePath: "main.yaml" }]);
        onChangeCodifyDrawerStateProperty("isK8sRedacted", k8sRedacted);
        break;
      case CODIFY_TYPES.terraform:
        const tfData = isK8sProvider ? res.hcl : res.codifiedSources;
        setTerraformIacData([{ content: getTerraformContentByType(codiMode, tfData), filePath: "main.tf" }], codiMode);
        onChangeCodifyDrawerStateProperty("isK8sRedacted", k8sRedacted);
        break;
      case CODIFY_TYPES.tfcdk:
        const tfCdkResData = isK8sProvider ? res.hcl : res.codifiedSources;
        onChangeCodifyDrawerStateProperty("tfCdkData", { ...tfCdkData, [codiMode]: [{ content: getTerraformContentByType(codiMode, tfCdkResData), filePath: `main.${LANGS_SUFFIX[lang]}` }] });
        break;
      default: break;
    }
    // additions
    if (!isEmpty(terraformCmds) && isTerraformCodifyTypes) {
      await dispatch(setTerraformCmds(terraformCmds));
      await dispatch(setTerraformCmdsRedacted(res?.isImportCommandRedacted))
    }
    if (!isEmpty(terraformBlocks) && isTerraformCodifyTypes) {
     setImportBlocks(flatten(terraformBlocks))
    }
    if (!isEmpty(providerBlocks)) {
      setProviderBlock(providerBlocks);
    }
    setIsFetchingIaC(false);
  };

  const handleSetActiveSmartTab = (type) => setActiveCodificationType(type);

  const handleOnChangeSmartType = (type, codifyType, lang) => {
    if (type === "modules" || includes(CREATE_MODULE_CODIFICATION_MODES, type)) {
      return setIsFetchingIaC(false);
    }

    if (includes(keys(MIGRATION_PROVIDERS_TYPES), type)) {
      return fetchCloudMigration(MIGRATION_PROVIDERS_TYPES[type]);
    }
    if (activeTab === CODIFY_TYPES.crossplane) {
      return type === ACTIVE_CODIFICATION_TYPES.naive ? fetchNewCodify(CODIFY_TYPES.crossplane) : null;
    }
    return fetchIacImport(codifyType, lang ? lang : "hcl", type);
  };

  const renderTabContent = (title, key) => { 
    // Now we only support terraform and tfcdk (no ai capibility) 
    if (revisionIdFromCofifyButton && key !== CODIFY_TYPES.terraform && key !== CODIFY_TYPES.tfcdk) {
      return;
    }
    const icon = codifyIcons(key, themeDark);
    const isTerraform = key === CODIFY_TYPES.terraform;
    return ( 
      <div className="CodifyDrawer__tab row">
        {icon && <img className="CodifyDrawer__tab-icon" src={icon} alt={key} />}
        {title}
        {isTerraform ? <>
          <span>|</span>
          <img className="CodifyDrawer__tab-icon-opentofu" src={codifyIcons("opentofu")} alt={key} />
          <span>OpenTofu</span>
        </>: null}
      </div>
  )}
  return (
    <Drawer
      title={null}
      placement="right"
      closable={false}
      onClose={handleCloseDrawer}
      visible={visible}
      key="right"
      className="CodifyDrawer"
      width="75vw"
      maskStyle={{ backgroundColor: "rgba(0,0,0,.65)" }}
      zIndex={1001}
    >
      <div className="CodifyDrawer__content">
        <span className="title CodifyDrawer__content-title">
          {t("title")}
        </span>
        <FontAwesomeIcon icon={faTimes} onClick={handleCloseDrawer} className="PullRequestForm__content-close" />

        {!isEmpty(selectedResources) && 
        <CodifyTabs onChangeTab={onChangeTab} handleSetActiveSmartTab={handleSetActiveSmartTab} isFetchingIaC={isFetchingIaC} codifySessionId={codifySessionId} iacImportState={iacImportState}
          setIsOverweightResponse={setIsOverweightResponse} selectedResources={selectedResources} renderTabContent={renderTabContent} setActiveLanguage={setActiveLanguage} 
          fetchIacImport={fetchIacImport} mapData={mapData} isLoadingOtherIac={isLoadingOtherIac} fetchNewCodify={fetchNewCodify} />}

        <CodifyFooter selectedResources={selectedResources} mapData={mapData} visible={visible} codifySessionId={codifySessionId} onChangeCodifyDrawerStateProperty={onChangeCodifyDrawerStateProperty}
          handleOpenTerraformImport={handleOpenTerraformImport} sendGeneratedEvent={sendGeneratedEvent} handleOpenPullRequest={handleOpenPullRequest} />
        {unsavedCodeModal && <UnsavedCodifyModal />}

      </div>
    </Drawer>
  );
};

export default CodifyDrawer;