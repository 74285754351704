import React, { useState } from 'react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as ImportCmdsIcon } from "../../../Images/codify/import_cmds.svg";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";

import { ACTIVE_CODIFICATION_TYPES, alertIfCodifyRedact, checkIfSelectedDataDisabled, CODIFICATION_MODES, 
    CREATE_MODULE_CODIFICATION_MODES, getFileType, getModuleCallPreparedContent, getSelectedData, handleSetFileExportName,
    MODULE_CALL_FORMATS_WITHOUT_IMPORT_BLOCKS } from './codifyHelpers';
import AppToggle from '../../../shared/appToggle/appToggle';
import AppBtn from '../../../shared/appBtn/appBtn';
import { useDispatch, useSelector } from 'react-redux';
import { CODIFY_TYPES, IAC_TYPES, RESOURCE_STATE_TYPES } from '../../../consts/general';
import { sendEvent } from '../../../utils/amplitude';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { codifyEvents } from '../../../utils/amplitudeEvents';
import ActionBtn from '../../../shared/actionBtn/actionBtn';
import { downloadZipModuleFiles, setPullRequestParameters } from '../../../redux/actions/iacImportActions';
import { useTranslation } from 'react-i18next';

const CREATE_MODULE_PORTKEY = "CREATE_MODULE_PORTKEY";
const CREATE_MODULE_PORTKEY_WITH_DEPENDENCIES = "CREATE_MODULE_PORTKEY_WITH_DEPENDENCIES";

const CodifyFooter = ({ selectedResources = [], mapData, visible, codifySessionId, onChangeCodifyDrawerStateProperty, handleOpenTerraformImport, sendGeneratedEvent, handleOpenPullRequest }) => {
    const [loadingZipModuleFiles, setLoadingZipModuleFiles] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation('inventory', { keyPrefix: "codifyDrawer.footer"});

    const isViewer = useSelector((state) => state.profilesReducer.isViewer);
    const moduleData = useSelector((state) => state.iacImportReducer.moduleData);
    const iacImportState = useSelector((state) => state.iacImportReducer) || {};
    const firstResource = selectedResources[0] || {};
  
    const { selectedModuleCallFilePath, selectedModuleCallFormat, pullReqParams, moduleFileSelected, moduleDataCreated, redactedValues: createModulesRedactedFiles = {},
      terraformIacData, tfCdkData, otherIacData, activeIacTab: activeTab, activeLanguage, activeCodificationType, k8sIacData,
      isImportBlocksClicked, isProviderBlockClicked, importBlocks, providerBlock ,isK8sRedacted, compositionData = []
    } = iacImportState;
    const isModuleCallActiveCodificationTab = activeCodificationType === ACTIVE_CODIFICATION_TYPES.modules;
    const isTerraformOrTfCdk = includes([CODIFY_TYPES.terraform, CODIFY_TYPES.tfcdk], activeTab);
    const isTerraform = activeTab === CODIFY_TYPES.terraform;


    const isK8sSelectedProvider = firstResource.provider === IAC_TYPES.k8s;
    const isSelectedResourceDeleted = firstResource.state === RESOURCE_STATE_TYPES.deleted;
    const isActiveCodificationCreateModuleModes = includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType);
    const shouldShowImportAndProviderBlocks = !includes([ACTIVE_CODIFICATION_TYPES.awsMigration, ACTIVE_CODIFICATION_TYPES.azureMigration, ACTIVE_CODIFICATION_TYPES.googleMigration], activeCodificationType);
    const shouldShowImportCommands = !includes([ACTIVE_CODIFICATION_TYPES.createModule, ACTIVE_CODIFICATION_TYPES.createModuleDependencies, ACTIVE_CODIFICATION_TYPES.awsMigration,
        ACTIVE_CODIFICATION_TYPES.azureMigration, ACTIVE_CODIFICATION_TYPES.googleMigration], activeCodificationType);

    const setIsImportBlocksClicked = (value) => onChangeCodifyDrawerStateProperty("isImportBlocksClicked", value);
    const setIsProviderBlockClicked = (value) => onChangeCodifyDrawerStateProperty("isProviderBlockClicked", value);
    const setTerraformIacData = (value) => onChangeCodifyDrawerStateProperty("terraformIacData", { ...terraformIacData, [activeCodificationType]: value});

    const isSelectedDataDisabled = checkIfSelectedDataDisabled({ activeTab, activeCodificationType, moduleDataCreated, moduleFileSelected, moduleData, terraformIacData, isModuleCallActiveCodificationTab, tfCdkData, k8sIacData, otherIacData });
    const codifyFileType = getFileType({ activeTab, isModuleCallActiveCodificationTab, selectedModuleCallFilePath, activeLanguage, otherIacData, moduleFileSelected });
    const selectedCodeData = getSelectedData({ isTerraformOrTfCdk, isModuleCallActiveCodificationTab, moduleData, selectedModuleCallFilePath, 
      activeCodificationType, activeTab, terraformIacData, tfCdkData, k8sIacData, otherIacData, moduleDataCreated, moduleFileSelected, 
      isImportBlocksClicked, compositionData });

    const handleInsertCode = (insertBlock, isToggleClicked, setToggleClickedFunc, isProviderBlock) => {
        const insertAddition = `${isProviderBlock ? insertBlock : insertBlock?.join("\n")}\n`
        const terraformClone = terraformIacData[activeCodificationType] || [];
        if (!isToggleClicked) {
          setToggleClickedFunc(true);
          sendGeneratedEvent({ activeCodificationType, visible, codifySessionId, activeTab, activeLanguage, isImportBlocksClicked: isProviderBlock ? isImportBlocksClicked : true, isProviderBlockClicked: isProviderBlock ? true : isProviderBlockClicked, selectedResources });
          if (isModuleCallActiveCodificationTab) return;
          terraformClone[0].content = `${insertAddition}${terraformClone[0]?.content}`; //import
        }
        else {
          setToggleClickedFunc(false);
          sendGeneratedEvent({ activeCodificationType, visible, codifySessionId, activeTab, activeLanguage, isImportBlocksClicked: isProviderBlock ? isImportBlocksClicked : false, isProviderBlockClicked: isProviderBlock ? false : isProviderBlockClicked, selectedResources });
          if (isModuleCallActiveCodificationTab) return;
          terraformClone[0].content = terraformClone[0].content?.replace(insertAddition, "") //destruct
        }
        setTerraformIacData(terraformClone);
    }

    const handleImportBlocks = () => handleInsertCode(importBlocks, isImportBlocksClicked, setIsImportBlocksClicked);
    const handleProviderBlock = () => handleInsertCode(providerBlock, isProviderBlockClicked, setIsProviderBlockClicked, true);

    const handleDownloadZipModuleFiles = async () => {
        setLoadingZipModuleFiles(true);
        alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles })
    
        const selectedResourcesWithoutFirst = selectedResources.slice(1) || [];
        const resources = selectedResourcesWithoutFirst.map((resource = {}) => ({ terraformAssetType: resource.assetType, providerId: resource.integrationId, assetId: resource.assetId }))
    
        const payload = {
          assetType: firstResource.assetType,
          assetId: firstResource.assetId,
          providerId: firstResource.integrationId,
          provider: firstResource.provider,
          resources,
          codificationMode: activeCodificationType === ACTIVE_CODIFICATION_TYPES.createModule ? CREATE_MODULE_PORTKEY : CREATE_MODULE_PORTKEY_WITH_DEPENDENCIES,
        };
    
        const response = await dispatch(downloadZipModuleFiles(payload));
        if (!response?.req?.ok) {
          return setLoadingZipModuleFiles(false);
        }
        const fileName = "module";
        const downloadUrl = URL.createObjectURL(response?.result);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", fileName); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        setLoadingZipModuleFiles(false);
    }
    return (
    <div className="CodifyDrawer__content-footer row between">
        {shouldShowImportAndProviderBlocks && (
          <div className="row g10">
            <AppToggle
              text={t("importBlocks")}
              checked={isTerraform ? isImportBlocksClicked : false}
              toggleChecked={handleImportBlocks}
              disabled={(isEmpty(terraformIacData[activeCodificationType]) && !isModuleCallActiveCodificationTab) || !isTerraform || isActiveCodificationCreateModuleModes ||
               isSelectedResourceDeleted || isK8sSelectedProvider ||
              (isModuleCallActiveCodificationTab && ((isEmpty(moduleData?.TerraformImportBlocks) || MODULE_CALL_FORMATS_WITHOUT_IMPORT_BLOCKS.includes(selectedModuleCallFormat))))
              }
            />
            <AppToggle
              text={t("providerBlock")}
              checked={isTerraform ? isProviderBlockClicked : false}
              toggleChecked={handleProviderBlock}
              disabled={isEmpty(terraformIacData[activeCodificationType]) || !isTerraform || isSelectedResourceDeleted ||
              [ACTIVE_CODIFICATION_TYPES.createModule, ACTIVE_CODIFICATION_TYPES.createModuleDependencies, ACTIVE_CODIFICATION_TYPES.modules].includes(activeCodificationType) || isK8sSelectedProvider }
            />
          </div>
        )}
        <div className="row g10">
        {shouldShowImportCommands && (
        <div className="CodifyDrawer__content-footer-importCmds">
          <AppBtn
            plain
            text={[CODIFY_TYPES.cloudformation, CODIFY_TYPES.tfcdk, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2].includes(activeTab) ? t("importScript") : t("importCommands")}
            onClick={() => handleOpenTerraformImport(activeTab)}
            icon={<ImportCmdsIcon />}
            disabled={
              ![CODIFY_TYPES.terraform, CODIFY_TYPES.cloudformation, CODIFY_TYPES.tfcdk, CODIFY_TYPES.cdk, CODIFY_TYPES.cdkl2].includes(activeTab) || 
               isK8sSelectedProvider ||
              (isEmpty(terraformIacData[activeCodificationType]) && isEmpty(tfCdkData[activeCodificationType]) && isEmpty(otherIacData?.[CODIFY_TYPES.cloudformation])) ||
              isSelectedResourceDeleted ||
              (activeTab === CODIFY_TYPES.cloudformation && isEmpty(otherIacData?.[CODIFY_TYPES.cloudformation]?.[0]?.importScript)) || 
              (activeTab === CODIFY_TYPES.cdk && isEmpty(otherIacData?.[CODIFY_TYPES.cdk]?.[0]?.importScript)) ||
              (activeTab === CODIFY_TYPES.cdkl2 && isEmpty(otherIacData?.[CODIFY_TYPES.cdkl2]?.[0]?.importScript))
            }/>
        </div>)}
            <ActionBtn
              text={t("download")}
              disabled={isSelectedDataDisabled}
              action="download"
              icon="donwload"
              fileType={codifyFileType}
              fileName={handleSetFileExportName({ activeTab, isModuleCallActiveCodificationTab, selectedModuleCallFormat, selectedModuleCallFilePath, activeCodificationType, otherIacData, moduleFileSelected, compositionData, terraformIacData, tfCdkData, k8sIacData })}
              stringToAction={selectedCodeData}
              onClickDifferentActions={() => {
                sendEvent(codifyEvents.downloadCodification, { iacUUID: codifySessionId });
                alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles, isK8sRedacted, isK8sSelectedProvider })
              }}
              refresh={visible}
            />
           {isActiveCodificationCreateModuleModes && 
           <AppBtn
              text={t("exportAll")}
              icon={<FontAwesomeIcon icon="folder-open" />}
              onClick={handleDownloadZipModuleFiles}
              disabled={isEmpty(moduleDataCreated)}
              loading={loadingZipModuleFiles}
            />}
            <ActionBtn
              text={t("copy")}
              icon="copy"
              disabled={isSelectedDataDisabled}
              action="copy"
              onClickDifferentActions={() => {
                sendEvent(codifyEvents.copyCodification, { iacUUID: codifySessionId });
                alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles, isK8sRedacted, isK8sSelectedProvider })}
              }
              stringToAction={selectedCodeData}
              refresh={visible}
            />
            {<AppBtn
              text={t("pullRequest")}
              onClick={() => {
                const isNaiveActiveCodificationType = activeCodificationType === ACTIVE_CODIFICATION_TYPES.naive;
                const isCreateModulePreparedContent = isActiveCodificationCreateModuleModes && !isEmpty(moduleDataCreated);
                const isCreateComposition = activeCodificationType === ACTIVE_CODIFICATION_TYPES.createComposition && !isEmpty(compositionData);
                const preparedContent = isTerraformOrTfCdk ? mapData[activeTab]
                : isNaiveActiveCodificationType ? mapData[activeTab]
                : (isCreateModulePreparedContent ? Object.entries(moduleDataCreated).map(([filePath, content] = []) => ({ filePath, content }))
                : isCreateComposition ? compositionData
                : isModuleCallActiveCodificationTab ? getModuleCallPreparedContent(moduleData, isImportBlocksClicked)
                : null);

                  dispatch(
                    setPullRequestParameters({
                      ...pullReqParams,
                      codificationMode: CODIFICATION_MODES[activeCodificationType],
                      codifyType: isActiveCodificationCreateModuleModes ? activeCodificationType : undefined,
                      withImportBlocks: isImportBlocksClicked,
                      withProviderBlock: isProviderBlockClicked,
                      iacType: activeTab === CODIFY_TYPES.tfcdk ? CODIFY_TYPES.tfcdk : null,
                      language: activeTab === CODIFY_TYPES.tfcdk ? activeLanguage : null,
                      preparedContent,
                      fileExtension: isModuleCallActiveCodificationTab ? "tf" : codifyFileType,
                    })
                  );
                handleOpenPullRequest();
                alertIfCodifyRedact({ activeCodificationType, activeTab, mapData, moduleFileSelected, createModulesRedactedFiles, isK8sRedacted, isK8sSelectedProvider })
              }}
              icon={<FontAwesomeIcon icon={faGitAlt} />}
              disabled={
                isViewer 
                || !isActiveCodificationCreateModuleModes && isEmpty(mapData[activeTab])
                || isActiveCodificationCreateModuleModes && isEmpty(moduleDataCreated)}
            />}
        </div>
      </div>)
}

export default CodifyFooter;