import { useDispatch, useSelector } from "react-redux";
import SpinnerLoader from "../../../../shared/spinnerLoader/spinnerLoader";
import MinimalCopy from "../../../../shared/minimalCopy/minimalCopy";
import { useEffect, useState } from "react";
import { getAiRemediateCloud } from "../../../../redux/actions/aiRemediationActions";
import "./aiRemediationCli.scss";

const AiRemediationCli = ({
  assetId = "",
  isOpen,
  classificationData,
  integrationId,
  setIsOpen,
}) => {
  const cloudFix = useSelector(
    (state) => state.aiRemediationReducer?.cloudFix || {}
  );

  const dispatch = useDispatch();

  const [loadingRemediate, setLoadingRemediate] = useState(false);

  const {
    _id = "",
    type = [],
    name = "",
    description = "",
  } = classificationData || {};

  const splitCloudFix = cloudFix[assetId]?.split("\n\n");

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        setLoadingRemediate(true);
        await fetchCode();
        setIsOpen(false);
        setLoadingRemediate(false);
      }
    };

    fetchData();
  }, [isOpen]);

  const fetchCode = async () => {
    await dispatch(
      getAiRemediateCloud(
        _id,
        integrationId,
        type[0],
        name,
        description,
        assetId
      )
    );
  };

  return !cloudFix[assetId] && loadingRemediate ? (
    <div className="row center mgTtm10 mgBtm5">
      <SpinnerLoader />
    </div>
  ) : (
    <>
      <div className="AiRemediationCli col g10">
        {splitCloudFix?.map((item, index) => {
          return (
            <div key={index} className="row g5">
              <span>{item}</span>
              <span>
                <MinimalCopy text={item} purple={true} />
              </span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AiRemediationCli;
