import React, { useEffect } from "react";
import HeaderSearchBox from "../../../shared/headerSearchBox/headerSearchBox";
import { useDispatch, useSelector } from "react-redux";
import { setSearchVal } from "../../../redux/actions/globalAppActions";
import "./headerSearch.scss";
import { setSearchKeywordNavigation } from "../../../redux/actions/navigationActions";

const HeaderSearch = ({ page, width }) => {
  const dispatch = useDispatch();
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);

  const activeFeatures = useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const isInventoryAIChatEnabled = activeFeatures.find((feature = {}) => feature.path === '/inventory-ai-chat');
  const navigationSearchValue = useSelector(
      (state) => state.navigationReducer.searchValue
  );
  useEffect(() => {
    if(!isInventoryAIChatEnabled && navigationSearchValue) {
        dispatch(setSearchVal(navigationSearchValue));
    }

    return () => {
        dispatch(setSearchKeywordNavigation(''));
    };
}, [navigationSearchValue]);

  return (
    <div className="HeaderSearch row">
      <HeaderSearchBox
        placeholder="Search"
        value={(val) => {
          dispatch(setSearchVal(val));
        }}
        currentValue={searchVal}
        resetSearch={(val) => {
          dispatch(setSearchVal(val));
        }}
        width={width}
        page={page}
      />
    </div>
  );
};

export default HeaderSearch;
