import React from "react";
import { Tooltip } from "antd";
import { prettifyStackName } from "../../../utils/formatting";

const AssetStacksCell = ({ data }) => {
  const stateLocationString = data?.stateLocationString || "";
  
  if (!stateLocationString) return null;

  const formattedStackName = prettifyStackName(stateLocationString) || "";
  
  return (
    <Tooltip
      placement="top"
      overlayInnerStyle={{
        width: "220px",
      }}
      title={stateLocationString}
    >
      <span>{formattedStackName}</span>
    </Tooltip>
  );
};

export default AssetStacksCell;
