import React, { useEffect } from "react";
import isString from "lodash/isString";
import map from "lodash/map";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import { sendEvent } from "../../../../utils/amplitude";
import { inventoryEvents } from "../../../../utils/amplitudeEvents";
import { ReactComponent as InfoIcon } from "../../../../Images/inventory_asset_tabs/info_icon.svg";
import { ReactComponent as Cog } from "../../../../Images/inventory_asset_tabs/cog.svg";
import { ReactComponent as History } from "../../../../Images/inventory_asset_tabs/history.svg";
import { ReactComponent as Comments } from "../../../../Images/inventory_asset_tabs/comments.svg";
import { ReactComponent as Relations } from "../../../../Images/inventory_asset_tabs/relations.svg";
import { ReactComponent as EventViewer } from "../../../../Images/inventory_asset_tabs/event_viewer.svg";
import { PROVIDERS_SUPPORT_EVENT_VIEWER, TABS_KEYS } from "../../../../consts/general";
import Spin from 'antd/lib/spin';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAbbreviateNumber } from "../../../../utils/helpers";
import "./assetTabs.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const AssetTabs = ({ activeTab, handleSetTab, assetData = {}, loadingEvents, handleResetTab }) => {
  const { t } = useTranslation('inventory')
  const assetEventsTotal = useSelector((state) => state.assetEventsReducer.total);

  useEffect(() => {
    if(tabs.find(tab => tab.key == activeTab)?.disabled){
      handleResetTab()
    }
  }, [assetData, assetEventsTotal])
  
  // **** visible key can be used for feature flag or dev necessities ****
  const isEventViewerDisabled = !PROVIDERS_SUPPORT_EVENT_VIEWER.includes(assetData.provider) || !assetEventsTotal;

  const tabs = [
    { name: t('asset-popup.tabs.information'), key: TABS_KEYS.general, visible: true, icon: <InfoIcon /> },
    { name: t('asset-popup.tabs.configuration'), key: TABS_KEYS.config, visible: true, icon: <Cog /> },
    {
      name: t('asset-popup.tabs.history'),
      key: TABS_KEYS.history,
      count: assetData?.revisionsNumber > 0 ? assetData?.revisionsNumber - 1 : 0,
      visible: assetData?.state !== 'deleted',
      disabled: !(assetData.revisionsNumber - 1 > 0),
      icon: <History />,
    },
    {
      name: t('asset-popup.tabs.events'),
      key: TABS_KEYS.eventViewer,
      visible: true,
      disabled: isEventViewerDisabled,
      count: !isEventViewerDisabled ? getAbbreviateNumber(assetEventsTotal): null,
      loading: loadingEvents ,
      icon: <EventViewer />
    },
    {
      name: t('asset-popup.tabs.comments'),
      key: TABS_KEYS.comments,
      count: assetData?.comments,
      visible: true,
      icon: <Comments />,
    },
    {
      name: t('asset-popup.tabs.relationships'),
      key: TABS_KEYS.relationship,
      visible: true,
      disabled: !assetData?.hasOwnerTargets && (!has(assetData, 'terraformObjectReferencesAncestry') || isEmpty(assetData?.terraformObjectReferencesAncestry)),
      icon: <Relations />,
    },
    {
      name: t('asset-popup.tabs.diagram'),
      key: TABS_KEYS.diagram,
      visible: true,
      disabled: isEmpty(assetData?.stateLocationString),
      icon: <FontAwesomeIcon icon="sitemap" />,
    },
  ];

  const renderTabs = () => {
    return map(tabs || [], (tab) => {
      const disabledTab = tab.soon || tab.disabled
      if (tab?.visible) {
        return (
          <div
            className={`AssetTabs__tab row between ${
              activeTab === tab?.key ? "active" : ""
            } ${disabledTab? "soon" : ""}`}
            key={tab?.key}
            onClick={() => {
              if (!disabledTab) {
                handleSetTab(tab?.key);
                sendEvent(inventoryEvents.infoDrawerTabClick, {
                  tab: tab?.key,
                  assetType: assetData?.assetType,
                  assetName: assetData?.name,
                });
              }
            }}
          >
            <span className="AssetTabs__tab-name row">
              {tab?.icon}
              {tab?.name}
            </span>
            {tab?.loading ?  <Spin indicator={<LoadingOutlined/>}/> : 
            (tab?.count > 0 || isString(tab.count)) && (
              <span className="AssetTabs__tab-count center">{tab?.count}</span>
            )}
            {tab?.soon && (
              <div className="purple-flag-outline">Coming Soon</div>
            )}
          </div>
        );
      }
    });
  };
  return <div className="AssetTabs col">{renderTabs()}</div>;
};

export default AssetTabs;
