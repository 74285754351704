import { GET_SUMMARY_REMEDITIONS, GET_CODE_REMEDITIONS, GET_CODE_ASSETS_REMEDITIONS, GET_AI_REMEDITIONS, CREATE_PR_REMEDITIONS, GET_CLOUD_REMEDITIONS, GET_CLOUD_ASSETS_REMEDITIONS, GET_AI_REMEDITIONS_CLOUD, CLEAR_DATA } from "../actions/aiRemediationActions";

const initialState = {
  summary: [],
  code: {},
  totalFiles: 0,
  afterKeyCode: [],
  codeAssets: {},
  afterKeyAssets: {},
  iacFix: {},
  repoData: {},
  prData: {},
  cloud: {},
  afterKeyCloud: [],
  totalFilesCloud: 0,
  cloudAssets: {},
  cloudFix: {},
};

export default function (state = initialState, { type, payload = {} }) {
  switch (type) {
    case GET_SUMMARY_REMEDITIONS:
      return { ...state, summary: payload };
    case GET_CODE_REMEDITIONS:
      return { ...state, code: payload.formattedResult, afterKeyCode: [...state.afterKeyCode, payload.afterKey], totalFiles: payload.totalBuckets?.value };
    case GET_CODE_ASSETS_REMEDITIONS:
      const keys = Object.keys(payload.afterKey) || [];
      const key = keys[0] || "";
      const value = payload.afterKey[key];
      return {
        ...state,
        codeAssets: {
          ...state.codeAssets,
          ...payload.formattedResult
        },
        afterKeyAssets: {
          ...state.afterKeyAssets,
          [key]: [...(state.afterKeyAssets[key] || []), value]
        }
      };
    case GET_AI_REMEDITIONS:
      return { ...state, iacFix: payload.iacFixResult, repoData: payload.repoData };
    case CREATE_PR_REMEDITIONS:
      return {
        ...state,
        prData: {
          ...state.prData,
          ...payload
        }
      };
      case GET_CLOUD_REMEDITIONS:
        return { ...state, cloud: payload.data, afterKeyCloud: [...state.afterKeyCode, payload.afterKey], totalFilesCloud: payload.total };
      case GET_CLOUD_ASSETS_REMEDITIONS:
        return { ...state, cloudAssets:{ ...state.cloudAssets, [payload.integrationId]: payload.formattedResult?.data }}
        case GET_AI_REMEDITIONS_CLOUD:
          return { ...state, cloudFix:{ ...state.cloudFix, [payload.assetId]: payload.aiResult }};
        case CLEAR_DATA:
          return { ...state, afterKeyCode: initialState.afterKeyCode, afterKeyAssets: initialState.afterKeyAssets, afterKeyCloud: initialState.afterKeyCloud };
    default:
      return state;
  }
}