import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import round from "lodash/round";
import Popover from "antd/lib/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import { filter, isNumber } from "lodash";
import { iacProviders } from "../../../../utils/icons";
import {
  formatIacStatus,
  formatUTCDateTime,
  prettifyStackName,
} from "../../../../utils/formatting";
import CFNIcon from '../../../../Images/general_icons/cfn.png'
import CDKIcon from '../../../../Images/general_icons/cdk.png'
import IacIcon from "../../../../shared/iacIcon/iacIcon";
import { terraformConfig } from "./terraformConfig";
import { k8sConfig } from "./k8sConfig";
import { cloudFormationConfig } from "./cloudFormationConfig";
import { pulumiConfig } from "./pulumiConfig";
import { IAC_TYPES } from "../../../../consts/general";
import { Tooltip } from "antd";
import { getPartiallyModulesArr } from "../../iacStacksHelper";

export const tableColumns = (
  setStackViewAssets,
  setStackDrawerOpen,
  setStackRowData,
  themeDark,
  selectedIacType,
  apiRef,
  integrations,
  handleClickPartialModule,
) => {
  const orderedManagedTypes = ["managed", "modified", "ghost", "iacIgnored"];
  const emptyStatuses = ["empty", "corrupted", "unavailable"];

  const formatList = (managedSplit, notSupportedCount) => {
    let formattedList = map(orderedManagedTypes, (type) => {
      if (!isEmpty(managedSplit)) {
        return { key: type, count: managedSplit[type] || 0 };
      }
    });
    formattedList?.push({ key: "unknown", count: managedSplit?.unknown });
    formattedList?.push({ key: "notSupported", count: notSupportedCount });
    formattedList = filter(formattedList, (agg) => agg?.count > 0);
    return formattedList;
  };

  const renderVersion = (stackRow) => {
    if (
        (stackRow?.stateType === "terraform" || stackRow?.stateType === "opentofu") &&
      !isEmpty(stackRow?.metadata?.terraformMetadata?.terraformVersion)
    ) {
      return stackRow?.metadata?.terraformMetadata?.terraformVersion;
    }
    if (
      stackRow?.stateType === "helm" &&
      !isEmpty(stackRow?.metadata?.helmMetadata?.appVersion)
    ) {
      return stackRow?.metadata?.helmMetadata?.appVersion;
    }
    return "";
  };

  const renderManagedGraph = (managedSplit, orderedList) => {
    const notSupportedCount =
      orderedList?.find((state) => state?.key === "notSupported")?.count || 0;
    const total = notSupportedCount + (managedSplit?.total || 0) - (managedSplit?.undetermined || 0);
    const isMultiChild = orderedList.length > 1;
    const renderContent = () => {
      return (
        <div className="col g5 padIn10">
          {map(orderedList, (state) => {
            const percentage = (state?.count / total) * 100;
            return (
              <div
                className="IacStacksTable__item-graph-popup row"
                key={uuidv4()}
              >
                <span
                  className={`IacStacksTable__item-graph-popup-circle ${state?.key}`}
                ></span>
                <span>{formatIacStatus(state?.key)}</span>
                <span>{`${round(percentage, 2).toFixed(2)}%`}</span>
              </div>
            );
          })}
        </div>
      );
    };
    if (isEmpty(managedSplit)) {
      return (
        <div className="IacStacksTable__item-graph center">
          <Popover
            content={
              <div className="IacStacksTable__item-graph-popup row">
                <span className="IacStacksTable__item-graph-popup-circle notSupported"></span>
                <span>Not Supported</span>
                <span>100%</span>
              </div>
            }
            title="IaC Status"
            placement="bottomRight"
          >
            <span
              className="IacStacksTable__item-graph-inner singleChild notSupported"
              style={{ width: "100%" }}
            ></span>
          </Popover>
        </div>
      );
    }
    return (
      <div className="IacStacksTable__item-graph center row">
        {map(orderedList, (state) => {
          const percentage = (state?.count / total) * 100;
          return (
            <Popover
              content={renderContent()}
              title="IaC Status"
              placement="bottomRight"
              key={uuidv4()}
            >
              <span
                className={`IacStacksTable__item-graph-inner ${
                  isMultiChild ? "multipleChildren" : "singleChild"
                } ${state?.key}`}
                style={{ width: `${percentage}%` }}
              ></span>
            </Popover>
          );
        })}
      </div>
    );
  };

  const mutualColumns = {
    name: {
      k8s: {
        headerName: "Name",
        field: "stateType",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          const k8sName = params?.row?.stackName
          const metadata = selectedIacType === IAC_TYPES.helm ? params?.row?.metadata?.helmMetadata : { ...params?.row?.metadata?.k8sMetadata ,name: k8sName }
          const icon = params?.value === IAC_TYPES.k8s ? "argo" : params?.value
          return (
            <div className="row" style={{ gap: "10px" }}>
              <div className="IacStacksTable__item-cloudIcon">
                {!isEmpty(icon) && (
                  <IacIcon iacType={icon} customStyle="table" />
                )}
              </div>
              {metadata?.home ? (
                <a
                  href={metadata.home}
                  target="_blank"
                  rel="noreferrer"
                >
                  {metadata.name || ""}
                </a>
              ) : (
                metadata?.name || ""
              )}
            </div>
          );
        },
      },
      terraform: {
        headerName: "Name",
        field: "stateType",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          apiRef.current = params?.api;
          const partiallyArr = getPartiallyModulesArr(params?.row);
          return (
            <div className="row g10">
              {!isEmpty(partiallyArr)&&
              <Tooltip title={
              <div className="col g5"> 
                  <span className="underline">{partiallyArr.length > 1 ? partiallyArr.length :  ""} Partially Applied Modules</span>
                  <div className="IacStacksTable__item-partials col g10">
                    {partiallyArr.map((elem = {}) => <span className="IacStacksTable__item-partial pointer" key={elem?.id} onClick={() => handleClickPartialModule(elem.id) }>• {elem.value}</span>)}
                  </div>
                </div>}>
                <FontAwesomeIcon className="IacStacksTable__item-warningIcon" icon="exclamation-triangle" />
              </Tooltip>}
              <div className="IacStacksTable__item-cloudIcon">
                {!isEmpty(params?.value) && (
                  <img
                    src={iacProviders(params?.value, themeDark)}
                    className={`IacStacksTable__cloudIcon ${params?.value}`}
                    alt="iac icon"
                    style={{ height: "15px" }}
                  />
                )}
              </div>
              <Tooltip title={params?.row?.stateSubRemoteLocationString}>
                {prettifyStackName(params?.row?.stateSubRemoteLocationString)}
              </Tooltip>
            </div>
          );
        },
      },
      cloudformation: {
        headerName: "Name",
        field: "stateType",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          const isCdk = params?.row?.metadata?.cloudFormationMetadata?.isCdk;
          return (
            <div className="row" style={{ gap: "10px" }}>
              <div className="IacStacksTable__item-cloudIcon">
                {!isEmpty(params?.value) && (
                  <img
                    src={isCdk ? CDKIcon : CFNIcon}
                    className={`IacStacksTable__cloudIcon ${params?.value}`}
                    alt="iac icon"
                    style={{ height: "15px" }}
                  />
                )}
              </div>
              {params?.row?.metadata?.remoteLink ? (
                <a
                  href={params?.row?.metadata?.remoteLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {params?.row?.stateSubRemoteLocationString}
                </a>
              ) : (
                params?.row?.stateSubRemoteLocationString
              )}
            </div>
          );
        },
      },
      others: {
        headerName: "Name",
        field: "stateType",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          return (
            <div className="row" style={{ gap: "10px" }}>
              <div className="IacStacksTable__item-cloudIcon">
                {!isEmpty(params?.value) && (
                  <img
                    src={iacProviders(params?.value, themeDark)}
                    className={`IacStacksTable__cloudIcon ${params?.value}`}
                    alt="iac icon"
                    style={{ height: "15px" }}
                  />
                )}
              </div>
              {params?.row?.stateSubRemoteLocationString}
            </div>
          );
        },
      },
    },
    status: {
      headerName: "IaC Status",
      field: "managedSplit",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.status === "scanning") {
          return (
            <span className="IacStacksTable__item-status row">
              <FontAwesomeIcon icon="spinner" spin />
              Scanning
            </span>
          );
        }
        if (emptyStatuses?.includes(params?.row?.status)) {
          return (
            <div className="IacStacksTable__item-graph center">
              <Popover
                content={
                  <div className="IacStacksTable__item-graph-popup row">
                    <span className="IacStacksTable__item-graph-popup-circle empty"></span>
                    <span>{formatIacStatus(params?.row?.status)}</span>
                  </div>
                }
                title="IaC Status"
                placement="bottomRight"
              >
                <span
                  className="IacStacksTable__item-graph-inner singleChild empty"
                  style={{ width: "100%" }}
                ></span>
              </Popover>
            </div>
          );
        }
        const formattedList = formatList(
          params?.value,
          params?.row?.notSupportedCount
        );
        return renderManagedGraph(params?.value, formattedList);
      },
    },
    lastApplied: {
      headerName: "Last Applied",
      field: "lastModified",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        if (
          !isEmpty(params?.value) &&
          new Date(params?.value)?.getFullYear() !== 1
        ) {
          return formatUTCDateTime(new Date(params?.value))
        }
        return "-";
      },
    },
    assets: {
      headerName: "Assets",
      field: "notSupportedCount",
      filterable: false,
      disableColumnMenu: true,
      width: 120,
      sortable: false,
      renderCell: (params) => {
        let shouldRender = false;
        let assetsNum = 0;
        if (
          !isEmpty(params?.row?.managedSplit) &&
          isNumber(params?.row?.managedSplit?.total)
        ) {
          assetsNum = params?.row?.managedSplit?.total;
          if (
            isNumber(params?.row?.managedSplit?.unknown) &&
            params?.row?.managedSplit?.unknown > 0
          ) {
            assetsNum = assetsNum - params?.row?.managedSplit?.unknown;
          }
          if (assetsNum > 0) {
            shouldRender = true;
          }
        }
        return (
          <span className="row IacStacksTable__item">
            {shouldRender && (
              <div
                className="row IacStacksTable__item-view-asstes-btn-flag"
                onClick={(e) => {
                  e.stopPropagation();
                  setStackViewAssets([
                    {
                      id: params?.row?.stackId,
                      name: params?.row?.stackName,
                      iacType: params?.row?.stateType,
                    },
                  ]);
                }}
              >
                <FontAwesomeIcon icon="layer-group" />
                {assetsNum}
              </div>
            )}
          </span>
        );
      },
    },
    stackData: {
      headerName: " ",
      field: "id",
      type: "boolean",
      width: 50,
      filterable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const output = (
          <span
            className="IacStacksTable__actions-configIcon"
            onClick={(e) => {
              e.stopPropagation();
              setStackRowData(params?.row);
              setStackDrawerOpen(true);
            }}
          >
            <FontAwesomeIcon icon="ellipsis-v" />
          </span>
        );
        return output;
      },
    },
  };

  const commonFinalColumns = [
    mutualColumns?.lastApplied,
    mutualColumns?.assets,
    mutualColumns?.stackData,
  ];

  const columns = {
    terraform: terraformConfig(mutualColumns, commonFinalColumns, renderVersion),
    helm: k8sConfig(mutualColumns, commonFinalColumns, IAC_TYPES.helm, integrations, themeDark),
    k8s: k8sConfig(mutualColumns, commonFinalColumns, IAC_TYPES.k8s, integrations, themeDark),
    kustomize: k8sConfig(mutualColumns, commonFinalColumns, IAC_TYPES.kustomize, integrations, themeDark),
    cloudformation: cloudFormationConfig(mutualColumns, commonFinalColumns),
    pulumi: pulumiConfig(mutualColumns, commonFinalColumns, renderVersion),
  };
  
  return columns[selectedIacType] || [];
};