import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import "./assetHistoryV2.scss";
import { getHistoryRevisions } from "../../../../redux/actions/historyActionsV2";
import { isEmpty } from "lodash";
import RevisionCompareV2 from "./historyContent/revisionCompreV2";
import { ReactComponent as CelandarIcon } from "../../../../Images/general_icons/celandar.svg";
import { ReactComponent as ArrowIcon } from "../../../../Images/general_icons/arrow.svg";
import { sendEvent } from "../../../../utils/amplitude";
const CHUNK_SIZE = 10;

const AssetHistoryV2 = ({
  asset,
  pageAssetHistory,
  setPageAssetHistory,
  skipAssetHistory,
  setSkipAssetHistory,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("asset-history");

  const [loadingInit, setLoadingInit] = useState(true);
  const [pullLoading, setPullLoading] = useState(false);
  const [revisions, setRevisions] = useState([]);
  const assetHistoryDiv = useRef(null);
  const hasMoreToPull = revisions?.length < asset?.revisions;

  useEffect(() => {
    handleFetchInitialHistoryV2();
    return () => {
      setPageAssetHistory(0);
      setSkipAssetHistory(0);
    };
  }, [asset]);

  const handleScroll = async () => {
    const divToScroll = assetHistoryDiv.current;
    if (hasMoreToPull && divToScroll) {
      setPullLoading(true);
      sendEvent("clicked navigation timeline arrow");
      const newSkip = (pageAssetHistory + 1) * CHUNK_SIZE;
      setSkipAssetHistory(newSkip);
      const fetchRevisions = await fetchData(newSkip);
      setPageAssetHistory((prev) => prev + 1);
      if (Array.isArray(fetchRevisions)) {
        setRevisions((prevRevisions) => [...prevRevisions, ...fetchRevisions]);
      }
      setPullLoading(false);
      divToScroll.scrollTop = divToScroll.scrollHeight;
    }
  };

  const handleFetchInitialHistoryV2 = async () => {
    const initSkip = 0;
    setLoadingInit(true);
    const fetchRevisions = await fetchData(initSkip);
    setRevisions(fetchRevisions);
    setLoadingInit(false);
  };

  const fetchData = async (skip) => {
    const limit = CHUNK_SIZE;
    const { provider, integrationId, assetType, frn } = asset;
    return dispatch(
      getHistoryRevisions(provider, integrationId, assetType, frn, skip, limit)
    );
  };

  return (
    <div
      className={
        loadingInit || isEmpty(revisions)
          ? "tab-page center"
          : "AssetHistoryV2 col g20"
      }
    >
      {loadingInit ? (
        <Loading />
      ) : isEmpty(revisions) ? (
        <AppEmpty customStyle="code" text={t("no-history-revisions")} />
      ) : (
        <>
          <span className="AssetHistoryV2__title text bold font-20">
            Mutation Log
          </span>
          <div className="AssetHistoryV2__revisions col g20">
            <div className="AssetHistoryV2__revisions__calandar-container">
              <div className="AssetHistoryV2__revisions__calandar-row">
                <CelandarIcon className="AssetHistoryV2__calendarIcon" />
              </div>
              <div className="AssetHistoryV2__sub-title text bold row g20">
                <span>Before</span>
                <span>After</span>
              </div>
            </div>
            <div
              id="assetHistory-scroll"
              className="AssetHistoryV2__revisions-items col g20 "
              ref={assetHistoryDiv}
            >
              {revisions?.map((revision, index) => (
                <div key={index}>
                  <RevisionCompareV2
                    currentRevision={revision?.after}
                    requestedRevision={revision?.before}
                    revisionId={revision?.revisionId}
                    timestamp={revision?.timestamp}
                    revision={revision}
                  />
                </div>
              ))}
            </div>
            <div className="AssetHistoryV2__line" />
            {
              <>
                <ArrowIcon
                  className={`AssetHistoryV2__scrollIcon ${
                    hasMoreToPull ? "" : "disabled"
                  } ${pullLoading ? "loading" : ""}`}
                  onClick={() => handleScroll(assetHistoryDiv)}
                />
                {hasMoreToPull && (
                  <span
                    className={`AssetHistoryV2__loadMore ${
                      pullLoading ? "loading" : ""
                    } ${
                      hasMoreToPull ? "fadeIn-animation" : ""
                    }`}
                  >
                    Load More
                  </span>
                )}
              </>
            }
          </div>
        </>
      )}
    </div>
  );
};

export default AssetHistoryV2;
