import AppWizard from "../../../../shared/appWizard/appWizard";
import NewAppModal from "../../../../shared/newAppModal/newAppModal";
import AiRemediationManagementFooter from "./aiRemediationFooter";
import AiRemediationSelection from "./aiRemediationSelection";
import { ReactComponent as Tool } from "../../../../Images/general_icons/governance/remediation/tool.svg";
import { ReactComponent as Thinkerbell } from "../../../../Images/general_icons/governance/remediation/thinkerbell.svg";

import "./aiRemediationManagement.scss";
import { useState } from "react";
import AiRemediationVc from "./aiRemediationVc";
import AiRemediationCompere from "./aiRemediationCompere";
import { useDispatch, useSelector } from "react-redux";
import {
  createPullRequestRemediations,
  getAiRemediate,
} from "../../../../redux/actions/aiRemediationActions";
import AssetTypeImage from "../../../../shared/assetTypeImage/assetTypeImage";
import { isEmpty } from "lodash";
import { appToast } from "../../../../shared/appToast/appToast";
import AiRemediationProvider from "./aiRemediationProvider";
import { sendEvent } from "../../../../utils/amplitude";
import { aiRemediationEvents } from "../../../../utils/amplitudeEvents";

const AiRemediationManagement = ({ visible = false, handleClose, data }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedPath, setSlectedPath] = useState("");
  const [filesInfo, setFilesInfo] = useState({});
  const [loadingFix, setLoadingFix] = useState(false);
  const [loadingPR, setLoadingPR] = useState(false);

  const iacFix = useSelector(
    (state) => state.aiRemediationReducer?.iacFix || {}
  );

  const repoData = useSelector(
    (state) => state.aiRemediationReducer?.repoData || {}
  );

  const prData = useSelector(
    (state) => state.aiRemediationReducer?.prData || {}
  );

  const { _id = "", type = [], name = "", description = "", providers = [] } = data || {};

  const assetType = type[0] || "";

  const dispatch = useDispatch();

  const renderModalTitle = () => {
    const { name = "" } = data || {};
    return (
      <div className="AiRemeditionManagement__header">
        <Tool className="AiRemeditionManagement__header-icon" />
        <span className="bold">
          {`Remediation for ${name}`}
          <span className="AiRemeditionManagement__header-icon-type">
            <AssetTypeImage
              customStyle={{ width: "18px" }}
              assetType={assetType}
            />
          </span>
        </span>

        <span
          className={`AiRemeditionManagement__header-preview mix-purple-green-flag bold`}
        >
          Preview
        </span>
        <span className="AiRemeditionManagement__header-icon-thinkerbell">
          <Thinkerbell />
        </span>
      </div>
    );
  };

  const configWizard = [
    {
      step_title: "",
      step_description: "",
      content: (
        <AiRemediationSelection data={data} setCurrentStep={setCurrentStep} />
      ),
      invisible: true,
    },
    {
      step_title: "",
      step_description: "",
      content: (
        <AiRemediationVc
          data={data}
          setSlectedPath={setSlectedPath}
          selectedPath={selectedPath}
          loadingFix={loadingFix}
        />
      ),
      invisible: true,
      nextButtonText:"Review Fix",
      beforeNextStep: async () => {
        setLoadingFix(true);
        await dispatch(
          getAiRemediate(_id, assetType, name, description, selectedPath)
        );
        const payload = {
          policyName: name,
          dataSource: providers
        }
        
        sendEvent(aiRemediationEvents.reviewFix, payload);
        return setLoadingFix(false);
      },
    },
    {
      step_title: "",
      step_description: "",
      content: (
        <AiRemediationCompere
          data={data}
          setFilesInfo={setFilesInfo}
          loadingFix={loadingFix}
          selectedPath={selectedPath}
        />
      ),
      invisible: true,
      nextButtonText: prData[selectedPath] ? "Pull request" : "Create pull request",
      beforeNextStep: async (dataPr, selectedPath) => {
        if(dataPr[selectedPath]){
          window.open(dataPr[selectedPath], "_blank");
          return;
        }
        setLoadingPR(true);
        const prData = await dispatch(createPullRequestRemediations(_id, selectedPath, iacFix, repoData, name));

        const payload =  { policyName: name, dataSource: providers};
        sendEvent(aiRemediationEvents.createPullRequest, payload);

        const prUrl = prData ? prData[selectedPath] : null;
        setLoadingPR(false);

        if (!isEmpty(prUrl)) {
          return window.open(prUrl, "_blank");
        }

        return appToast("error", "", `Failed to create pull request`);
      },
    },
    {
      step_title: "",
      step_description: "",
      content: (
        <AiRemediationProvider
        data={data}
        />
      ),
      invisible: true,
      nextButtonText: "Done",
      beforeNextStep: () => {
        handleClose();
      },
    },
  ];

  return (
    <NewAppModal
      visible={visible}
      handleClose={handleClose}
      destroyOnClose
      centered
      width="85vw"
      bodyClassName="AiRemeditionManagement__content stepsUnvisible"
      customFooterClassName="AiRemeditionManagement__footer"
      title={renderModalTitle()}
      iconSrc={null}
      footer={
        <AiRemediationManagementFooter
          stepsUnvisible={true}
          dontShowNextButton={true}
          configWizard={configWizard}
          currentStep={currentStep}
          handleClose={handleClose}
          setCurrentStep={setCurrentStep}
          selectedPath={selectedPath}
          loadingPR={loadingPR}
        />
      }
      headerSuffix={null}
    >
      <div className="AiRemeditionManagement">
        <AppWizard
          configWizard={configWizard}
          stepsUnvisible={true}
          handleClose={handleClose}
          customCurrent={currentStep}
        />
      </div>
    </NewAppModal>
  );
};

export default AiRemediationManagement;
