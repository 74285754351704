import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import Popover from "antd/lib/popover";
import DropMenu from "../../../shared/dropMenu/dropMenu";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import { ToolFilled } from "@ant-design/icons";

const MenuCell = ({ row, handleOpenIssue, handleOpenSuggetions }) => {
  const { t } = useTranslation("insights");
  const history = useHistory();
  const dispatch = useDispatch();
  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const inventoryFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const isCustomPolicy = !row.isDefault

  const handleEdit = () => {
    return history.push({
      pathname: "/governance/create-custom-control",
      state: {
        data: {...row, id: row?._id, isEditMode: true},
      },
    });
  }
  const handleViewAssets = () => {
    dispatch(setFilters({...inventoryFilters, classifications: [row?._id], excluded: showExclusion, masterType: []}))
    return history.push('/inventory');
  }
  const handleIssue = () => {
    return handleOpenIssue(row)
  }
  const handleSavings = () => {
    return handleOpenSuggetions('', row)
  }

  const handleDuplicate = () => {
    return history.push({
      pathname: "/governance/create-custom-control",
      state: {
        data: {...row, name: `Copy - ${row?.name}`, isEditMode: false},
      },
    });
  }

  const links = [
    {
      text: t("table.menu.assets"),
      icon: <FontAwesomeIcon icon="layer-group" />,
      disabled: false,
      onClick: handleViewAssets
    },
    {
      text: t("table.menu.edit"),
      icon: <FontAwesomeIcon icon="pen" />,
      disabled: false,
      onClick: handleEdit
    },
    {
      text: t("table.menu.ticket"),
      icon: <FontAwesomeIcon icon={["fab", "jira"]} />,
      disabled: isViewer,
      onClick: handleIssue
    },
  ];

  if(isCustomPolicy){
    links.push(
      {
        text: t("table.menu.duplicate"),
        icon: <FontAwesomeIcon icon={faClone} />,
        disabled: isViewer,
        onClick: handleDuplicate
      },
  )
  }

  // add savings
  if(row?.cost && row?.isEnabled && row?.total_assets) {
    links.push({
      text: t("table.menu.remediation"),
      icon: <ToolFilled />,
      disabled: false,
      onClick: handleSavings
    })
  }

  return (
    <Popover
      content={<DropMenu links={links} />}
      title={null}
      placement="left"
      overlayClassName="InsightMenuCell"
    >
      <span style={{ padding: "0 7px", cursor: 'pointer' }}>
        <FontAwesomeIcon icon="ellipsis-v" style={{ fontSize: "14px" }} />
      </span>
    </Popover>
  );
};

export default MenuCell;
