import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import AppBtn from "../../../shared/appBtn/appBtn";
import { ReactComponent as BackupDRIcon } from "../../../Images/general_icons/sidebar/backup.svg";
import { ReactComponent as PolicyDetailsIcon } from "../../../Images/backup/icons/policy-details.svg";
import { ReactComponent as ScopeIcon } from "../../../Images/backup/icons/scope.svg";
import { ReactComponent as NotificationIcon } from "../../../Images/general_icons/insight-notification.svg";
import { Form, Input, Select } from "antd";
import { ASSET_TYPES_BY_PROVIDER, BACKUP_PERIODS, REGIONS_BY_PROVIDER } from "../backupHelper";
import { getAllClouds } from "../../../redux/actions/globalAppActions";

import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";

import CheckedMultiSelect from "../../../shared/checkedMultiSelect/CheckedMultiSelect";
import { getNotificationsAggs } from "../../../redux/actions/notificationsActions";
import './backupPolicyModal.scss';

const BackupPolicyModal = ({ modalVisible, onClose, onComplete, selectedPolicy = {} }) => {
    const { t: tBackup } = useTranslation("backupDR");
    const { t: tCommon } = useTranslation("common");
    
    const [policy, setPolicy] = useState(selectedPolicy);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const tagsAgg = useSelector((state) => state.notificationsReducer.tags) || [];
    const tags = useMemo(() => tagsAgg.map((tag) => tag?.key), [tagsAgg]);
    const providers = useSelector((state) => state.globalAppReducer.clouds) || {};

    const providersList = useMemo(() => {
        const list = [];
        Object.keys(providers).forEach((key) => {
            const providerArray = providers[key] || [];
            providerArray.forEach((item) => list.push({ provider: key, id: item.id, name: item.name }));
        });
        return list;
    }, [providers]);

    const [form] = Form.useForm();

    const isEditMode = !!selectedPolicy.id;
    const onValuesChange = (changedValues) => {
        setPolicy({ ...policy, ...changedValues });
    };

    useEffect(() => {
        initScreen();
    },[])
    
    const initScreen = async() => {
        if (isEditMode) return;
        setLoading(true);
        if (isEmpty(providers)) {
            await dispatch(getAllClouds());
        }
        if (isEmpty(tags)) {
            await dispatch(getNotificationsAggs());
        }
        setLoading(false);
    };

    const renderProviderOption = (item) => {
        if (isEmpty(item)) return <div style={{ color: "rgb(97 97 104)" }}>{tBackup("modal.placeholders.dataSource")}</div>
        return <div className="row g8">
                    <ProviderIcon provider={item.provider} customStyle="inventory" />
                    {item.name}
        </div>
    };
    const renderAssetType = (item) => {
        if (isEmpty(item)) return <div style={{ color: "rgb(97 97 104)" }}>{tBackup("modal.placeholders.assetType")}</div>
        return (<div className="row g8">
        <AssetTypeImage assetType={item} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>
        <span>{formatAwsStringWithUnderscore(item)}</span>
       </div>)
    };
    return (
        <NewAppModal
                visible={modalVisible}
                handleClose={onClose}
                destroyOnClose
                centered
                width="80vw"
                bodyClassName="BackupPolicyModal__body"
                customFooterClassName="BackupPolicyModal__footer row"
                title={<div className="BackupPolicyModal__header row g10">
                        <BackupDRIcon />
                        {<span className="BackupPolicyModal__header-title">{tBackup(isEditMode ? "modal.edit-title" : "modal.create-title")}</span>}
                    </div>}
                iconSrc={null}
                footer={<div className="row g8">
                    <AppBtn text={tCommon("close")} onClick={onClose}/>
                    <AppBtn text={tCommon("create")} disabled={!policy?.name || !policy.dataSource || !policy?.assetType || !policy?.period} onClick={() => onComplete(policy)}/>
                    </div>}
                headerSuffix={null}
                >
                 <div className="BackupPolicyModal__content col">
                 <Form
                        name="general-form"
                        form={form}
                        initialValues={{
                            name: policy.name,
                            period: policy.period,
                            dataSource: policy.dataSource,
                            assetType: policy.assetType,
                            region: policy.region,
                            assetIacStatus: policy.assetIacStatus,
                        }}
                        className="BackupPolicyModal__form col g20"
                >
                    <div className="col g20">
                        <div className="row g8">
                            <PolicyDetailsIcon />
                            <span className="bold wb-text">{tBackup("modal.backupPolicyDetails")}</span>
                        </div>
                    <div className="BackupPolicyModal__section-row">
                        <div className="BackupPolicyModal__section-row-item col g8">
                            <span className="wb-text">{tBackup("modal.policyName")}</span>
                            <Input placeholder={tBackup("modal.placeholders.policyName")} value={policy.name || ""}
                             onChange={(e) => onValuesChange({ name: e.target.value })} disabled={isEditMode}/>
                        </div>
                        <div className="BackupPolicyModal__section-row-item col g8">
                            <span className="wb-text">{tBackup("columns.period")}</span>
                            <Select
                                placeholder={tBackup("modal.placeholders.period")}
                                showSearch
                                filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                value={policy.period}
                                style={{ width: "100%" }}
                                onChange={(val) => onValuesChange({ period: val })}
                      >
                        {BACKUP_PERIODS.map((version) => <Select.Option key={version} value={version}>{version}</Select.Option>)}
                      </Select>
                        </div>
                    </div>
                    </div>
                    <div className="BackupPolicyModal-divider"/>
                    <div className="col g10">
                        <div className="row g8">
                            <ScopeIcon />
                            <span className="bold wb-text">{tBackup("modal.scope")}</span>
                        </div>
                        <div className="BackupPolicyModal__section-row">
                            <div className="BackupPolicyModal__section-row-item col g8">
                                <span className="wb-text">{tBackup("columns.dataSource")}</span>
                                <Select
                                    placeholder={tBackup("modal.placeholders.dataSource")}
                                    showSearch
                                    filterOption={(input, option) => option.value?.[1]?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                    labelInValue
                                    value={{ value: policy.dataSource?.name || undefined , label: renderProviderOption(policy.dataSource)}}
                                    loading={loading}
                                    style={{ width: "100%" }}
                                    onChange={(val) => onValuesChange({ dataSource: {provider: val?.key?.[0], name: val?.key?.[1] } })}
                                >
                                    {providersList.map((item) => <Select.Option key={item.id} value={[ item?.provider, item?.name ]}>
                                        {renderProviderOption(item)}
                                        </Select.Option>)}
                                </Select>
                            </div>
                            <div className="BackupPolicyModal__section-row-item col g8">
                                <span className="wb-text">{tBackup("columns.region")}</span>
                                <Select
                                    placeholder={tBackup("modal.placeholders.region")}
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={policy.region}
                                    style={{ width: "100%" }}
                                    onChange={(val) => onValuesChange({ region: val })}
                                >
                                     {(REGIONS_BY_PROVIDER[policy.dataSource?.provider] || []).map((region) => <Select.Option key={region} value={region}>{region}</Select.Option>)}
                                </Select>
                            </div>
                        </div>
                        <div className="BackupPolicyModal__section-row">
                            <div className="BackupPolicyModal__section-row-item col g8">
                                <span className="wb-text">{tBackup("columns.assetType")}</span>
                                <Select
                                    placeholder={tBackup("modal.placeholders.assetType")}
                                    showSearch
                                    filterOption={(input, option) => option.value?.[0]?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                                    labelInValue
                                    value={{ value: policy.assetType, label: renderAssetType(policy.assetType)}}
                                    loading={loading}
                                    style={{ width: "100%" }}
                                    onChange={(val) => onValuesChange({ assetType: val?.key?.[0] })}
                                >
                                    {(ASSET_TYPES_BY_PROVIDER[policy.dataSource?.provider] || []).map((item) => <Select.Option key={item} value={[item]}>
                                        {renderAssetType(item)}
                                        </Select.Option>)}
                                </Select>
                            </div>
                            <div className="BackupPolicyModal__section-row-item col g8">
                                <span className="wb-text">Asset IaC Status</span>
                                <Select
                                    placeholder={tBackup("modal.placeholders.assetIacStatus")}
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={policy.iacStatus}
                                    style={{ width: "100%" }}
                                    onChange={(val) => onValuesChange({ iacStatus: val })}
                                >
                                    <Select.Option key="unmanaged" value="unmanaged">Unmanaged</Select.Option>
                                    <Select.Option key="allTypes" value="All Types">All Types</Select.Option>
                                </Select>
                            </div>
                        </div>
                        <Form.Item label="Tags (optional)" name="tags" style={{ flexDirection: "column" }}>
                            <CheckedMultiSelect
                                disabled={loading}
                                maxTagCount={2}
                                loading={loading}
                                placeholder="Select tags"
                                treeData={tags.map((tag, index) => ({
                                    title: (
                                        <span className={`CheckedMultiSelect__dropdown-item AppTag minimal`}
                                          key={index}>
                                          <span style={{ marginLeft: 0 }}>{tag}</span>
                                        </span>
                                      ),
                                      value: tag,
                                }))}
                                onChange={(tags) => {
                                    form.setFieldsValue({ tags });
                                    // setSelectedFilters({ ...selectedFilters, tags });
                                }}
                                value={form.getFieldValue("tags")}
                            />
                            </Form.Item>
                    </div>
                    <div className="BackupPolicyModal-divider"/>
                    <div className="col g10">
                        <div className="row g8">
                            <NotificationIcon className="BackupPolicyModal__section-row-item-notifIcon" />
                            <span className="bold wb-text">{tBackup("modal.notifications")}</span>
                        </div>
                        <div className="BackupPolicyModal__section-row">
                            <div className="BackupPolicyModal__section-row-item col g8">
                                <span className="wb-text">Send Notification to (optional)</span>
                                <Select
                                    placeholder={tBackup("modal.placeholders.notification")}
                                    showSearch
                                    filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={policy.notification}
                                    style={{ width: "50%" }}
                                    onChange={(val) => onValuesChange({ notification: val })}
                                    disabled
                                >
                                    {BACKUP_PERIODS.map((version) => <Select.Option key={version} value={version}>{version}</Select.Option>)}
                                </Select>
                            </div>
                        </div>
                    </div>
                </Form>
                </div>
         </NewAppModal>
    )
};

export default BackupPolicyModal;