import _ from "lodash";
import { emptyInventoryScreenFilters } from "../../consts/inventory";
import {
  GET_INVENTORY_AGGREGATIONS_V2,
  SET_FEATCHING_TYPE,
  GET_INVENTORY_FIREFLY_AGGREGATIONS,
  SET_FILTERS,
} from "../actions/inventoryActions";

import {
  GET_INVENTORY_FIREFLY_AGGREGATIONS_V3,
  GET_INVENTORY_BASE_AGGREGATIONS_V3,
  GET_INVENTORY_HITS_V3,
  GET_INVENTORY_YEARS_AGGREGATIONS_V3,
  SET_TERRAFORM_COMMANDS,
  SET_CFT_COMMANDS,
  SET_SELECTED_SERVICE_VIEW,
  GET_INVENTORY_FLAGS_AGGS,
  GET_INVENTORY_DELETED_AGGS,
  GET_INVENTORY_MASTER_TYPES,
  SET_TF_CMDS_REDACTED,
  GET_INVENTORY_DYNAMIC_AGSS
} from "../actions/inventoryv3Actions";

const initialState = {
  responseObjects: [],
  totalObjects: 0,
  aggregationsProviderType: [],
  aggregationsProviderId: [],
  aggregationsIsManaged: [],
  aggregationsAssetType: [],
  aggregationsRegion: [],
  featchingType: "ALL",
  is_managed_by_env: [],
  aggregationsAssetsVolume: [],
  aggregationsClassificaition: [],
  providerIdByProviderType: [],
  aggregationsExcludedAssets: [],
  aggregationsIacStacks: [],
  aggregationsIacTypes: [],
  aggregationsStackTypes: [],
  aggregationsYears: {},
  aggregationsOwners: [],
  aggregationsTags: [],
  terraformCommands: [],
  cftCommands: [],
  inventoryFilters: emptyInventoryScreenFilters,
  selectedService: null,
  aggregationsGit: false,
  aggregationsComments: false,
  aggregationsHistory: false,
  aggregationsRelationship: false,
  aggregationsNoTags: false,
  aggregationsClassification: false,
  aggregationsDeleted: {},
  dynamicAggsSearchInputs: {
    aggregationsTags: "",
    aggregationsIacStacks: "",
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // ********************************* NEW API ******************************************
    case GET_INVENTORY_FIREFLY_AGGREGATIONS_V3:
      return {
        ...state,
        aggregationsClassificaition: payload.classifications?.buckets,
        aggregationsExcludedAssets: payload.excludedAssets?.buckets,
        aggregationsIacStacks: state.dynamicAggsSearchInputs.aggregationsIacStacks ? state.aggregationsIacStacks : payload.iacStacks?.buckets,
      };

    case GET_INVENTORY_BASE_AGGREGATIONS_V3:
      return {
        ...state,
        aggregationsProviderId: payload?.providerId,
        aggregationsIsManaged: payload?.ismanaged?.buckets,
        aggregationsAssetType: payload?.assetType,
        aggregationsRegion: payload?.providerRegion?.buckets,
        aggregationsAssetsVolume: payload?.assetsVolume?.buckets,
        aggregationsIacTypes: payload?.iacType?.buckets,
        aggregationsProviderType: payload.providerType?.buckets,
        aggregationsOwners: payload?.owners?.buckets,
        aggregationsTags: state.dynamicAggsSearchInputs.aggregationsTags ? state.aggregationsTags : payload?.tags?.buckets,
        azureResourceGroups: payload?.azureResourceGroups?.buckets,
      };

    case GET_INVENTORY_YEARS_AGGREGATIONS_V3:
      return {
        ...state,
        aggregationsYears: payload,
      };

    case GET_INVENTORY_HITS_V3:
      return {
        ...state,
        responseObjects: payload.responseObjects,
        totalObjects: payload.totalObjects,
      };

    case SET_FEATCHING_TYPE:
      return {
        ...state,
        featchingType: payload,
      };

    case SET_TERRAFORM_COMMANDS:
      return {
        ...state,
        terraformCommands: payload,
      };
    case SET_TF_CMDS_REDACTED:
      return {
        ...state,
        isTfCmdsRedacted: payload,
      }
    case SET_CFT_COMMANDS:
      return {
        ...state,
        cftCommands: payload,
      }
    case SET_SELECTED_SERVICE_VIEW:
      return {
        ...state,
        selectedService: payload,
      };  

      case GET_INVENTORY_DELETED_AGGS:
        return {
          ...state,
          aggregationsDeleted: payload
        };

      case GET_INVENTORY_MASTER_TYPES:
        return {
          ...state,
          aggregationsMaster: payload?.masterType,
        };

  // ********************************* OLD API'S... DELETE AFTER REPLACE IT FROM ALL THE APP SCREENS *********************************************
    case SET_FEATCHING_TYPE:
      return {
        ...state,
        featchingType: payload,
      };

    case GET_INVENTORY_FIREFLY_AGGREGATIONS:
      return {
        ...state,
        aggregationsClassificaition:
          payload.aggregations.classifications?.buckets,
        aggregationsExcludedAssets:
          payload.aggregations.excludedAssets?.buckets,
      };

    case GET_INVENTORY_AGGREGATIONS_V2:
      if (state.featchingType === "ALL") {
        return {
          ...state,
          responseObjects: payload.responseObjects,
          totalObjects: payload.totalObjects,
          aggregationsProviderId: payload.aggregations?.providerId,
          aggregationsIsManaged: payload.aggregations?.ismanaged?.buckets,
          aggregationsAssetType: payload.aggregations?.assetType,
          aggregationsRegion: payload.aggregations?.providerRegion?.buckets,
          is_managed_by_env: payload.aggregations?.is_managed_by_env,
          aggregationsAssetsVolume: payload.aggregations?.assetsVolume?.buckets,
          providerIdByProviderType: payload.aggregations?.providerIdByProviderType?.buckets,
          aggregationsIacTypes: payload.aggregations?.iacType?.buckets,
          aggregationsStackTypes: payload.aggregations?.is_managed_by_iac_type?.buckets,
        };
      }
      if (state.featchingType === "ALL_CLOUD") {
        return {
          ...state,
          responseObjects: payload.responseObjects,
          totalObjects: payload.totalObjects,
          aggregationsIsManaged: payload.aggregations?.ismanaged?.buckets,
          aggregationsAssetType: payload.aggregations?.assetType,
          aggregationsRegion: payload.aggregations?.providerRegion?.buckets,
          aggregationsAssetsVolume: payload.aggregations?.assetsVolume?.buckets,
          aggregationsIacTypes: payload.aggregations?.iacType?.buckets,
        };
      }
      if (state.featchingType === "ALL_CLOUD_STATUS") {
        return {
          ...state,
          responseObjects: payload.responseObjects,
          totalObjects: payload.totalObjects,
          aggregationsIsManaged: payload.aggregations?.ismanaged?.buckets,
          aggregationsAssetType: payload.aggregations?.assetType,
          aggregationsRegion: payload.aggregations?.providerRegion?.buckets,
          aggregationsAssetsVolume: payload.aggregations?.assetsVolume?.buckets,
          aggregationsIacTypes: payload.aggregations?.iacType?.buckets,
        };
      }

      if (state.featchingType === "ALL_CLOUD_STATUS_TYPE") {
        return {
          ...state,
          responseObjects: payload.responseObjects,
          totalObjects: payload.totalObjects,
          aggregationsAssetsVolume: payload.aggregations?.assetsVolume?.buckets,
          aggregationsRegion: payload.aggregations?.providerRegion?.buckets,
        };
      }
      if (state.featchingType === "ALL_CLOUD_STATUS_LOCATION") {
        return {
          ...state,
          responseObjects: payload.responseObjects,
          totalObjects: payload.totalObjects,
          aggregationsAssetsVolume: payload.aggregations?.assetsVolume?.buckets,
        };
      }
    case SET_FILTERS:
      return {
        ...state,
        inventoryFilters: payload,
      };
    case GET_INVENTORY_FLAGS_AGGS:
      return {
        ...state,
        aggregationsGit: payload?.git,
        aggregationsComments: payload?.comments,
        aggregationsClassification: payload?.classifications,
        aggregationsHistory: payload?.history,
        aggregationsRelationship: payload?.relationship,
        aggregationsNoTags: payload?.noTags,
      };
    case GET_INVENTORY_DYNAMIC_AGSS: {
    return {
        ...state,
        [payload.aggregationField]: payload.data?.buckets,
        dynamicAggsSearchInputs: {
        ...state.dynamicAggsSearchInputs,
        [payload.aggregationField]: payload.aggSearch,
        },
    };
    }
    default:
      return state;
  }
};
