const WORKFLOWS_ACTIONS = {
    delete: 'delete',
    create: 'create',
    update: 'update',
    read: 'read',
    "no-op": 'no-op',
    import: 'import',
    createdelete: 'createdelete',
    deletecreate: 'deletecreate',
}
const WORKFLOWS_ACTIONS_MAP = {
    [WORKFLOWS_ACTIONS.create]: 'Create',
    [WORKFLOWS_ACTIONS.delete]: 'Destroy',
    [WORKFLOWS_ACTIONS.update]: 'Change',
    [WORKFLOWS_ACTIONS.read]: 'Read',
    [WORKFLOWS_ACTIONS["no-op"]]: 'No-op',
    [WORKFLOWS_ACTIONS.import]: 'Import',
    [WORKFLOWS_ACTIONS.createdelete]: 'Create before Destroy',
    [WORKFLOWS_ACTIONS.deletecreate]: 'Destroy before Create',
}
const CI_STEPS_MODES = {
    graph: 'graph',
    table: 'table',
    log: 'log',
}
const CI_STEPS = {
    code: 'code',
    plan: 'plan',
    init: 'init',
    apply: 'apply',
    guardrail: 'guardrail',
}
const DIFFS_MOCK = {
    before: "EgZjaHJvbWUyBdafsfdasfasdggAEEUYOTIGCAEQRRg8MfdsadfsafdsfhAIAhAuGMcBGLEDGNEDGIAEMg0IAxAAGIMBGLEDGIAEMg0IBBAAGIMBGLEDGIAEMg0IBRAAGIMBGLEDGIAEMgoIBhAAGLEDGIAEMgYIBxBFGDzSAQc4MjZqMGo3qAIAsAIA",
    after: "EagZjaHJvbWUyBdafsfdasfasdggAEEUYOTIGCAEQRRg8MfdsadfsafdsfhAIAhAuGMcBGLEDGNEDGIAEMg0IAxAAGIMBGLEDGIAEMg0IBBAAGIMBGLEDGIAEMg0IBRAAGIMBGLEDGIAEMgoIBhAAGLEDGIAEMgYIBxBFGDzSAQc4MjZqMGo3qAIAsAIA"
}
export {
    WORKFLOWS_ACTIONS,
    WORKFLOWS_ACTIONS_MAP,
    CI_STEPS,
    CI_STEPS_MODES,
    DIFFS_MOCK,
}