import React from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import map from "lodash/map";
import keys from "lodash/keys";
import {  providerNameFormatter } from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModulesTab from "./modulesTab/modulesTab";
import CreateModuleTab from "./createModuleTab/createModuleTab";
import cloudMigration from "../../../Images/clouds/cloud_migration.svg"

// components
import Loading from "../../../shared/loading/loading";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import { providers } from "../../../utils/providers";
import LongLoader from "../../../shared/longLoader/longLoader";
import { useTranslation } from "react-i18next";
import { CODIFY_TYPES, IAC_TYPES, PROVIDERS, RESOURCE_STATE_TYPES } from "../../../consts/general";
import { areAllRegionsSame } from "../../../utils/helpers";

import { ACTIVE_CODIFICATION_TYPES, configureAdvancedArr, configureBasicArr, configureCloudMigrationArr, getFileType, getUniqueModuleFileName } from "../codifyDrawer/codifyHelpers";

import CreateComposition from "./createComposition/createComposition";
import CodeSyntaxEditor from "./codeSyntaxEditor";
import { changeCodifyDrawerProperty } from "../../../redux/actions/iacImportActions";
import { sendEvent } from "../../../utils/amplitude";
import { codifyEvents } from "../../../utils/amplitudeEvents";
import "./smartCodifyTab.scss";

const UNSUPPORT_MENU_CODIFY_TYPES = [CODIFY_TYPES.helm];
const MAX_STRING_LENGTH_TO_RENDER = 50000;
const OverweightDataDisplay = <AppEmpty text={
    <div>
      <span className="opac">The generated code exceeds the display limit</span>
      <div className="centered"><span className="opac">Click the </span>Download<span className="opac"> button to review it.</span></div>
    </div>}
customStyle="custom" />

const SmartCodifyTab = ({
  isFetchingIaC,
  iacData,
  codifyType,
  activeType = "naive",
  handleSetActiveTab,
  selectedResources,
  hideNonNaiveTypes,
  isCodifyAI = false,
  isImportBlocksClicked,
  handleOverweightData = () => null,
  codifySessionId,
  iacImportState = {},
}) => {
  const { t } = useTranslation("inventory", { keyPrefix: "codifyDrawer" });
  const dispatch = useDispatch();
  const { activeLanguage, activeCodificationType, otherIacData, moduleDataCreated, terraformIacData, tfCdkData, activeIacTab: activeTab, tempCode, editMode } = iacImportState;
  const shouldRenderContent = activeTab === codifyType;
  const isUnsavedCodeIdentified = editMode && tempCode.new !== tempCode.old;

  if (!shouldRenderContent) return null;
  
  const isTerraform = activeTab === CODIFY_TYPES.terraform;

  const isTfCdkCodifyType = activeTab === CODIFY_TYPES.tfcdk; //temporary until we will support tfcdk all tabs
  
  const firstResource = selectedResources[0] || {};
  const isCrossplaneActiveTab = activeTab === CODIFY_TYPES.crossplane;
  const isIacDataExists = !isEmpty(iacData) && !isEmpty(iacData[0]);
  const isSameRegions = areAllRegionsSame(selectedResources);

  const providerType = firstResource.provider;
  const isK8sProviderType = providerType === IAC_TYPES.k8s;
  const isRevisionOrDeletedCodify = !!firstResource.revisionId || firstResource.state === RESOURCE_STATE_TYPES.deleted;
  const isCodifyTypeSupportMenu = !UNSUPPORT_MENU_CODIFY_TYPES.includes(activeTab);
  const shouldShowAdvancedMenu = !hideNonNaiveTypes || isCrossplaneActiveTab;

  const prefix = `Created using ${providerNameFormatter(providerType)} Provider Version ${providers[providerType]?.providerVersion}\n# `;
  const descriptions = {
    naive: isK8sProviderType ? `#${prefix}This mode shows the Terraform code for the chosen resource in its most straightforward format.\n\n` : "",
    smart: isK8sProviderType ? `#${prefix}This mode is the same as Basic but with dependencies connected with a data source \n# instead of an explicit resource id where applicable.\n\n` : "",
    dependencies: isK8sProviderType ? `${prefix}This mode codifies the chosen resource with all of its unmanaged dependencies (that exist in the inventory). \n# Codified dependencies will be connected using a data source.\n\n`: "",
    fullDependencies: isK8sProviderType ? `${prefix}This mode is excellent for building modules or reusable code and codifies all dependencies (that exist in the inventory), \n# even if some are already codified.\n\n`: "",
    modules: prefix.replace("#", ""),
    resourceGroup: "",
  };

  const basicArr = configureBasicArr(isCodifyAI, isRevisionOrDeletedCodify, t, isCrossplaneActiveTab, isCrossplaneActiveTab);
  const advancedArr = configureAdvancedArr(isRevisionOrDeletedCodify, providerType, isTfCdkCodifyType, t, isCrossplaneActiveTab);
  const cloudMigrationArr = configureCloudMigrationArr(isRevisionOrDeletedCodify, providerType);

  const importBlocksComment = `\n# Terraform v 1.5.0, has made importing resources into Terraform much simpler.\n# Rather than using the terraform import command, you can now simply add the\n# block to your Terraform code and actually import resources during an apply.\n\n`;
  const JOINED_ADDITION = `${isImportBlocksClicked ? importBlocksComment : ""}${iacData?.[0]?.content}`

  const canMigrate = !isEmpty(selectedResources) && includes([PROVIDERS.aws, PROVIDERS.gcp, PROVIDERS.azurerm], providerType);
  const renderMenu = (arr) => {
    return map(arr, (item = {}) => {
      const { unvisible, disabled, value, icon, selectedIcon, text, isBeta, hide } = item;
      if (unvisible) return;
      
      const isNotNaive = value !== ACTIVE_CODIFICATION_TYPES.naive;
      const disabledSmart = (isEmpty(selectedResources) && isNotNaive) || disabled;
      const shouldRender = (!hideNonNaiveTypes || !isNotNaive || isCrossplaneActiveTab) && !([ACTIVE_CODIFICATION_TYPES.modules, ACTIVE_CODIFICATION_TYPES.createModule].includes(value) && activeTab === CODIFY_TYPES.pulumi) && !hide;
      const isValueActive = activeType === value;

      if (shouldRender) {
        return (
          <div key={uuidv4()} className={`SmartCodifyTab__menu-item row ${isValueActive ? "active" : ""} ${disabledSmart ? "disabled" : ""}`} 
          onClick={() => !disabledSmart ? handleSetActiveTab(value) : null}>
            <span className="row g10">
              {icon && <img className="SmartCodifyTab__menu-item-icon" src={isValueActive ? selectedIcon || icon : icon} alt={text}/>}
                <span className="row g10">
                  {text}
                  {isBeta && <span className="purple-flag-outline">{t("menu.preview")}</span>}
                </span>
            </span>
          </div>
        );
      }
    });
  };

  const renderAppEmpty = (text) => (
    <div className="full-page center">
      <AppEmpty text={text} customStyle="code" />
    </div>
  );
  const renderOverweightDataDisplay = () => {
    return (
      <div className="full-page center">
        {OverweightDataDisplay}
      </div>
    )
  };

  const renderCode = () => {
    if (!isSameRegions && activeTab === CODIFY_TYPES.cloudformation) {
      return renderAppEmpty(t("cloudFormationRegionsError")); ;
    }
    if (isFetchingIaC) {
      return (
        <div className="full-page center bold g10 SmartCodifyTab__loading">
          <LongLoader msg1={t("loading.msg1")} msg2={t("loading.msg2")} loading={isFetchingIaC} customLoader={<Loading />} duration={15000} />
        </div>
      );
    }
    if (!isFetchingIaC && !isIacDataExists && !includes([ACTIVE_CODIFICATION_TYPES.modules, ACTIVE_CODIFICATION_TYPES.createComposition, ACTIVE_CODIFICATION_TYPES.createModule, ACTIVE_CODIFICATION_TYPES.createModuleDependencies], activeType)) {
      return renderAppEmpty(t("noData"));
    }

    switch (activeType) {
      case ACTIVE_CODIFICATION_TYPES.naive:
        const formattedData = getNaiveDataByCodifyType()
        const isMultipleFilesCodify = isArray(formattedData) && formattedData.length > 1;
        return isMultipleFilesCodify ? (
            <CreateModuleTab codifyType={activeType} iacData={formattedData} activeTab={activeTab} isMultiCodify handleOverweightData={handleOverweightData} 
            onSaveCode={(newCode, selectedFilePath) => onSaveNewCode(newCode, selectedFilePath)} onAddFile={() => onAddFile()}
            onEditFileName={(newName, oldName) => onEditFileName(newName, oldName)} onDeleteFile={(fileName) => onDeleteFile(fileName)} 
            />
          ) : (
            renderSyntaxHighlight(formattedData[0]?.content, onAddFile)
          );
      case ACTIVE_CODIFICATION_TYPES.smart:
        return renderSources();
      case ACTIVE_CODIFICATION_TYPES.dependencies:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.fullDependencies:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.modules:
        return (
          <ModulesTab
            description={descriptions?.modules}
            prefix={isK8sProviderType ? prefix : ""}
            selectedResources={selectedResources}
            isImportBlocksClicked={isImportBlocksClicked}
            importBlocksComment={importBlocksComment}
            handleOverweightData={handleOverweightData}
            OverweightDataDisplay={OverweightDataDisplay}
            codifySessionId={codifySessionId}
          />
        );
      case ACTIVE_CODIFICATION_TYPES.createComposition:
        return <CreateComposition selectedResources={selectedResources} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay}/>;
      case ACTIVE_CODIFICATION_TYPES.createModule:
      case ACTIVE_CODIFICATION_TYPES.createModuleDependencies:
        return <CreateModuleTab codifyType={activeType} selectedResources={selectedResources} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay}
         onAddFile={() => onAddFile(true)} onEditFileName={(newName, oldName) => onEditFileName(newName, oldName, true)} onDeleteFile={(fileName) => onDeleteFile(fileName, true)}/>;
      case ACTIVE_CODIFICATION_TYPES.resourceGroup:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.awsMigration:
      case ACTIVE_CODIFICATION_TYPES.azureMigration:
      case ACTIVE_CODIFICATION_TYPES.googleMigration:
        return iacData?.length > 1 ? 
        <CreateModuleTab codifyType={activeType} iacData={iacData} isMultiCodify handleOverweightData={handleOverweightData} 
          onSaveCode={(newCode, selectedFilePath) => onSaveNewCode(newCode, selectedFilePath)} onAddFile={() => onAddFile()}
          onEditFileName={(newName, oldName) => onEditFileName(newName, oldName)} onDeleteFile={(fileName) => onDeleteFile(fileName)} />
        : renderSyntaxHighlight(iacData[0]?.content, onAddFile);
      default:
        return renderAppEmpty(t("noData"));
    }
  };

  const getNaiveDataByCodifyType = () => {
    if (isCodifyAI) return iacData;
    const newData = iacData || [];
    newData[0].content = `${descriptions[activeType]}${isImportBlocksClicked ? importBlocksComment : ""}${newData[0].content?.replace(importBlocksComment, "")}`;
    return newData;
  };
  const changeTfOrCdkData = async (newData, additionalPayload, isMultipleKeys = false) => {
    const key = isTerraform ? "terraformIacData" : "tfCdkData";
    const value = isTerraform ? { ...terraformIacData, [activeCodificationType]: newData} : { ...tfCdkData, [activeCodificationType]: newData};
    if (isMultipleKeys) {
      additionalPayload[key] = value;
      await dispatch(changeCodifyDrawerProperty("addFile", additionalPayload, true));
    }
    await dispatch(changeCodifyDrawerProperty(key, value));
  }

  const onAddFile = async (isCreateModule) => {
    sendEvent(codifyEvents.clickedCodificationAddNewFile);
    if (isCreateModule) {
      const fileName = getUniqueModuleFileName(Object.keys(moduleDataCreated));
      const extensionFile = getFileType({ activeTab, activeLanguage })
      const newFileName = `${fileName}.${extensionFile}`;
      const newFiles = { ...moduleDataCreated, [newFileName]: "" };
      const payload = { moduleDataCreated: newFiles, moduleFileSelected: newFileName, editMode: true };
      await dispatch(changeCodifyDrawerProperty("addFile", payload, true));
    }
    else {
      const fileName = getUniqueModuleFileName(map(iacData, "filePath"));
      const extensionFile = getFileType({ activeTab, activeLanguage, otherIacData })
      const newFileName = `${fileName}.${extensionFile}`;
      const activeTabData = isCodifyAI ? otherIacData[activeTab] || [] : iacData || [];
      activeTabData.push({ filePath: newFileName, content: "" });
      const payload = { editMode: true, moduleFileSelected: newFileName };
      if (isCodifyAI) {
        payload.otherIacData = { ...otherIacData, [activeTab]: activeTabData };
        await dispatch(changeCodifyDrawerProperty("addFile", payload, true));
      } else {
        await changeTfOrCdkData(activeTabData, payload, true);
      }
    }

  }

  const onDeleteFile = async (fileName, isCreateModule) => {
    if (isCreateModule) {
      const newFiles = { ...moduleDataCreated };
      delete newFiles[fileName];
      await dispatch(changeCodifyDrawerProperty("moduleDataCreated", newFiles));
      await dispatch(changeCodifyDrawerProperty("moduleFileSelected", keys(newFiles)[0]));
    } else {
      const activeTabData = isCodifyAI ? otherIacData[activeTab] || [] : iacData || [];
      const updatedData = activeTabData.filter((source = {}) => source.filePath !== fileName);
      if (isCodifyAI) {
        await dispatch(changeCodifyDrawerProperty("otherIacData", { ...otherIacData, [activeTab]: updatedData }));
      } else {
       await changeTfOrCdkData(updatedData);
      }
      await dispatch(changeCodifyDrawerProperty("moduleFileSelected", updatedData[0]?.filePath));
    }
  };
  const onEditFileName = async (newName, oldName, isCreateModule) => {
    if (isUnsavedCodeIdentified) {
      await dispatch(changeCodifyDrawerProperty("unsavedCodeModal", true));
      return;
    }
    if (isCreateModule) {
      const newFiles = { ...moduleDataCreated, [newName]: moduleDataCreated[oldName] };
      if (newName !== oldName) {
        delete newFiles[oldName];
      }
      await dispatch(changeCodifyDrawerProperty("moduleDataCreated", newFiles));
      await dispatch(changeCodifyDrawerProperty("moduleFileSelected", newName));
    } else {
      const activeTabData = isCodifyAI ? otherIacData[activeTab] || [] : iacData || [];
      const updatedData = map(activeTabData, (data = {}) => data.filePath === oldName ? { ...data, filePath: newName } : data);
      if (isCodifyAI) {
        await dispatch(changeCodifyDrawerProperty("otherIacData", { ...otherIacData, [activeTab]: updatedData }));
      } else {
        await changeTfOrCdkData(updatedData);
      }
      await dispatch(changeCodifyDrawerProperty("moduleFileSelected", newName));
    }

  };
  
  const onSaveNewCode = async(newCode = "", selectedPath) => {
    await dispatch(changeCodifyDrawerProperty("hasSavedCode", true));
      let activeTabData = isCodifyAI ? otherIacData[activeTab] || [] : iacData || [];
      if (selectedPath) {
        activeTabData = map(activeTabData, (source = {}) => source.filePath === selectedPath ? { ...source, content: newCode } : source);
      }
      else {
        activeTabData[0] = { ...(activeTabData?.[0] || {}), content: newCode };
      }
      if (isCodifyAI) {
        await dispatch(changeCodifyDrawerProperty("otherIacData", { ...otherIacData, [activeTab]: activeTabData }));
      } else {
        await changeTfOrCdkData(activeTabData);
      }
  }

  const renderSyntaxHighlight = (code = '', onClickAddFile) => {
    if (code?.length > MAX_STRING_LENGTH_TO_RENDER){
      handleOverweightData(true);
      return renderOverweightDataDisplay();
    }
    if (!isEmpty(code) && !isArray(code)) {
      return <CodeSyntaxEditor code={code} onSave={onSaveNewCode} onClickAddFile={onClickAddFile} />
    }
    return renderAppEmpty(t("noData"));
  };
  const renderWrappers = () => {
    if (iacData?.length === 1) {
      const description = `${descriptions[activeType]}`;
      return renderSyntaxHighlight(description + JOINED_ADDITION, onAddFile);
    } else if (iacData?.length > 1) {
      return <CreateModuleTab codifyType={activeType} iacData={iacData} isMultiCodify handleOverweightData={handleOverweightData} 
      onSaveCode={(newCode, selectedFilePath) => onSaveNewCode(newCode, selectedFilePath)} onAddFile={() => onAddFile()}
      onEditFileName={(newName, oldName) => onEditFileName(newName, oldName)} onDeleteFile={(fileName) => onDeleteFile(fileName)} />
    }
    return;
  };

  const renderSources = () => {
    if (iacData?.length === 1) {
      const description = `${descriptions[activeType]}`;
      return renderSyntaxHighlight(description + JOINED_ADDITION, onAddFile);
    } else if (iacData?.length > 1) {
      return <CreateModuleTab codifyType={activeType} iacData={iacData} isMultiCodify handleOverweightData={handleOverweightData} 
      onSaveCode={(newCode, selectedFilePath) => onSaveNewCode(newCode, selectedFilePath)} onAddFile={() => onAddFile()}
      onEditFileName={(newName, oldName) => onEditFileName(newName, oldName)} onDeleteFile={(fileName) => onDeleteFile(fileName)} />

    }
    return;
  };
  return (
    <div className={`SmartCodifyTab ${isCodifyTypeSupportMenu ? "withMenu" : ""} `}>
      {isCodifyTypeSupportMenu && (
        <div className="SmartCodifyTab__menu">
          <div className="SmartCodifyTab__menu-seperator row">
            <FontAwesomeIcon icon="code" />
            {t("menu.basic")}
          </div>
          {renderMenu(basicArr)}
          {shouldShowAdvancedMenu && (
            <div className="SmartCodifyTab__menu-seperator row">
              <FontAwesomeIcon icon="sitemap" />
              {t("menu.advanced")}
            </div>
          )}
          {renderMenu(advancedArr)}
          {canMigrate && isTerraform && (
              <>
                <div className="SmartCodifyTab__menu-seperator row">
                  <img className="CodifyDrawer__tab-icon" src={cloudMigration} alt="Cloud Migration" />
                  {t("menu.cloudMigration")}
                </div>
                {renderMenu(cloudMigrationArr)}
              </>
          )}
        </div>
      )}
      <div className="SmartCodifyTab__code">{renderCode()}</div>
    </div>
  );
};

export default SmartCodifyTab;
