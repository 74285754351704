import React, { useEffect, useState } from "react";
import { Collapse, Radio } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { TablePagination } from "@mui/material";
import "./aiRemediationVc.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AiRemediationVcTable from "./table/aiRemediationVcTable";
import { useDispatch, useSelector } from "react-redux";
import { getAiRemediationCode } from "../../../../redux/actions/aiRemediationActions";
import Loading from "../../../../shared/loading/loading";
import LongLoader from "../../../../shared/longLoader/longLoader";
import TypingDots from "../../../../shared/typingDots/typingDots";
import { formatUrl, getIcon } from "./aiRemediation.utils";

const AiRemediationVc = ({ data, setSlectedPath, selectedPath, loadingFix }) => {
  const [activeKey, setActiveKey] = useState(null);
  const [vcPage, setVcPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const codeData = useSelector(
    (state) => state.aiRemediationReducer?.code || {}
  );

  const afterKey = useSelector(
    (state) => state.aiRemediationReducer?.afterKeyCode || {}
  );

  const totalFiles = useSelector(
    (state) => state.aiRemediationReducer?.totalFiles
  );

  const classificationsId = data?._id;

  useEffect(() => {
    let skip = 0;
    if(vcPage > 0){
      skip = afterKey[vcPage - 1] || '';
    }
    fetchCode(skip);
  }, [rowsPerPage, vcPage]);

  const fetchCode = async (skip) => {
    setLoading(true);
    const response = await dispatch(getAiRemediationCode(classificationsId, rowsPerPage, skip));
    if (response?.abort) return;
    setLoading(false);
  };

  const { Panel } = Collapse;

  const onChangeRadio = (e) => {
    setSlectedPath(e?.target?.value);
  };

  const onCollapseChange = (key = []) => {
    setActiveKey(key);
  };

  const handleSetRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setVcPage(0);
  };

  const renderHeader = (icon, title, count, value) => (
    <div className="panel-header" onClick={() => setSlectedPath(value)}>
      <div className="panel-header-content">
        <Radio
          value={value}
          checked={selectedPath === value}
          onChange={onChangeRadio}
          onClick={(e) => e.stopPropagation()}
        />
        <span className="panel-icon">{icon}</span>
        <span className="panel-title" dangerouslySetInnerHTML={{ __html: title }}></span>
        <span className="panel-count purple-flag g5 row">
          <FontAwesomeIcon icon="layer-group" className="purple-text" />
          {count}
        </span>
      </div>
      <RightOutlined className="panel-arrow-icon" />
    </div>
  );



  const codeDataFlat = Object.entries(codeData).flatMap(
    ([vcId, items]) => items
  );

  const isCodeDataPagination = (totalFiles || 0) > 10;

  if(loadingFix){
        return (
      <div className="center basic-100">
          <LongLoader customLoader={<Loading />} duration={10000} loading={loadingFix}
          msg1={<span className="row g8">Generating remediation <TypingDots isInText/></span>}
          msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}
          customClassName="col g10 center font-18 bold"/>
      </div>
  );
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="AiRemediationVc col g15">
      <h2>IaC Patch</h2>
      {codeDataFlat.map((item) => (
        <Collapse onChange={onCollapseChange} key={item.vcsCodeFileLink}>
          <Panel
            header={renderHeader(
              getIcon(item.vcsType),
              formatUrl(item.vcsCodeFileLink),
              item.assetCount,
              item.vcsCodeFileLink
            )}
            key={item.vcsCodeFileLink}
          >
            <AiRemediationVcTable
              classificationsId={classificationsId}
              pathCollapse={item.vcsCodeFileLink}
              totalAssets={item.assetCount}
              integrationId={item.integrationId}
            />
          </Panel>
        </Collapse>
      ))}

      {isCodeDataPagination && <TablePagination
        component="div"
        className="AiRemediationVc__pagination"
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
        onRowsPerPageChange={handleSetRowsPerPage}
        count={totalFiles}
        page={vcPage}
        onPageChange={(e, page) => setVcPage(page)}
      />}
    </div>
  );
};

export default AiRemediationVc;
